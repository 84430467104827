import { Helmet } from 'react-helmet'
import DemoNotice from '@/theme/components/DemoNotice'
import Header from '@/theme/components/Header'
import NavigationTabs from '@/theme/components/NavigationTabs'
import NotificationList from '@/theme/components/NotificationList'
import NewVersionPopup from '@/theme/components/NewVersionPopup'
// import Breadcrumbs from '@/theme/components/Breadcrumbs';

import Footer from '@/theme/components/Footer'
import CookiePopup from '@/theme/components/CookiePopup'
import { useEffect, useState } from 'react'
import useAuthContext, { AuthActions } from '../Contexts/AuthContext'
import loadOnSession from 'src/services/loadOnSession'
import useCartContext, { CartActions } from '../CartWrapper/useCartContext'
import { useAppContext } from '@/core/App/AppContext'
import { Toaster } from '../Toaster'
import { sendCartLoaded, sendUserLoaded } from 'src/services/gtm'
import RenderOnView from '../RenderOnView'
import { useRouterContext } from '@/core/Router/RouterContext'
import { VhmLogo } from '@/theme/route/Subscription'
// import { SpinTheWheelModal } from '../SpinTheWheel/SpinTheWheelModal'

const AppLoader = () => {
  const [loading, setLoading] = useState(false)
  const [{ mitter }] = useAppContext()
  useEffect(() => {
    const onStart = () => {
      setLoading(true)
    }
    const onEnd = () => {
      setLoading(false)
    }
    mitter.on('NavigationStart', onStart)
    mitter.on('NavigationEnd', onEnd)
    return () => {
      mitter.off('NavigationStart', onStart)
      mitter.off('NavigationEnd', onEnd)
    }
  }, [])

  return (
    <div
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: loading ? 'flex' : 'none',
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
      >
        <path
          fill="#fff"
          d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.2s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  )
}

export default function Layout({ children, ...props }) {
  const [{ mitter }] = useAppContext()
  const [{ isAuthenticated }, dispatch] = useAuthContext()
  const [, cartDispatch, { reloadCart }] = useCartContext()
  const rts = useRouterContext()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    function enableIsLoaded() {
      setIsLoaded(true)
    }
    window.addEventListener('load', enableIsLoaded)
    return () => {
      window.removeEventListener('load', enableIsLoaded)
    }
  }, [])
  useEffect(() => {
    const update = () => {
      dispatch({
        type: AuthActions.LOADING,
        payload: {},
      })
      loadOnSession({
        full: true,
      })
        .then((d) => {
          sendUserLoaded(d?.customer)
          if (d?.customer) {
            dispatch({
              type: AuthActions.LOGIN,
              payload: d?.customer,
            })
          }
          if (d?.cart) {
            sendCartLoaded(d?.cart)
            reloadCart(d?.cart)
          }
        })
        .finally(() => {
          dispatch({
            type: AuthActions.LOADING_DONE,
            payload: {},
          })
        })
    }
    update()

    mitter.on('RELOAD_LOGIN', update)
    return () => {
      mitter.off('RELOAD_LOGIN', update)
    }
  }, [])
  let showFooter = true
  let showLoader = false
  let pageStyle = {}
  if (rts.location.pathname == '/healthmantra') {
    showFooter = false
    showLoader = true
    pageStyle = { opacity: isLoaded ? '1' : '1' }
  }
  return (
    <>
      <div style={pageStyle} className={`page-wrapper `}>
        <Helmet></Helmet>

        <noscript>
          {process.env.APP_FB_PIXEL_ID && (
            <img
              height="1"
              width="1"
              style={{ display: 'none', visibility: 'hidden' }}
              src={`https://www.facebook.com/tr?id=${process.env.APP_FB_PIXEL_ID}&ev=PageView&noscript=1`}
            />
          )}
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-MT9D9J6"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
        <NotificationList />
        <DemoNotice />
        <RenderOnView>
          <Header />
        </RenderOnView>
        <NavigationTabs />
        {/* <SpinTheWheelModal /> */}
        {/* <Breadcrumbs /> */}
        <NewVersionPopup />
        {children}
        {showFooter ? <Footer /> : null}
        <CookiePopup />
        <Toaster />
        <AppLoader />
      </div>
    </>
  )
}
