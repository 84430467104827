import React from 'react'
import { Image } from '@/theme/components/Image'
import QuantityInput from '@/theme/components/QuantityCounter/QuantityInput'
import BogoQuantityInput from '@/theme/components/QuantityCounter/BogoQuantityInput'
import { Currency } from '@/services/helpers/currency'
import useCartContext from '../useCartContext'
import './cart-tile.scss'
import Link from '@/core/Link'
import { CloseButton } from 'react-bootstrap'
// import price_range from 'src/graphql/queries/product/price_range'

const CartProductTitle = ({ title, url_key = '' }) => {
  return (
    <div className="CartProductTitle">
      {url_key != '' ? <Link href={url_key}>{title}</Link> : title}
    </div>
  )
}
export const CartProductPrice = ({ price, discountPrice = 0 }) => {
  return (
    <div className={'cart-product-price'}>
      {discountPrice > 0 && (
        <div className={'discount'}>{Currency({ discountPrice })}</div>
      )}
      <div className={'current-price'}> {Currency({ money: price })} </div>
    </div>
  )
}

export const CartTile = (props) => {
  const { checkout = false } = props
  const [cartState, cartDispatch, { removeItem, updateCartItem }] =
    useCartContext()
  const { items } = cartState
  return (
    <>
      {items.length > 0 &&
        items.map((items, index) => {
          const {
            uid,
            product: {
              name,
              image,
              stock_status,
              bogo,
              sku,
              excerpt,
              pack_size,
              size,
              url_key,
              price_range,
              canonical_url,
            },
            configurable_options,
            prices: { price },
            quantity,
          } = items
          const product_url = price_range?.minimum_price?.final_price.value
            ? url_key
            : ''
          const showCounter = price.value == 0 || checkout ? false : true
          const inStock = stock_status != 'OUT_OF_STOCK'
          return (
            <div className="cart-row">
              <div className={'cart-tile'} key={index}>
                {product_url != '' ? (
                  <Link href={product_url}>
                    <Image className="cart-image" {...image} />
                  </Link>
                ) : (
                  <Image className="cart-image" {...image} />
                )}
                <div className={'cart-content'}>
                  {(showCounter || sku == 'ZZNT-1') && (
                    <div
                      onClick={() => {
                        removeItem(uid)
                      }}
                      className="delete"
                    >
                      <CloseButton /> Remove
                    </div>
                  )}

                  <div className="cart-content-inner-wrapper">
                    <CartProductTitle title={name} url_key={product_url} />
                  </div>
                </div>
              </div>
              {checkout && (
                <div>
                  <label className="label">Qty</label> {quantity}
                </div>
              )}
              {showCounter && (
                <div className="inline-elems container mt-3 cart-product-tile-quantity-price-wrapper">
                  <CartProductPrice price={price.value} />
                  {bogo != 1 ? (
                    <QuantityInput
                      quantity={quantity}
                      setQuantity={(quantity) => {
                        updateCartItem({
                          cart_item_uid: uid,
                          quantity,
                        })
                      }}
                      disable={cartState.loading || !inStock}
                    />
                  ) : (
                    <BogoQuantityInput
                      quantity={quantity}
                      setQuantity={(quantity) => {
                        updateCartItem({
                          cart_item_uid: uid,
                          quantity,
                        })
                      }}
                      disable={cartState.loading || !inStock}
                    />
                  )}
                  {/* {JSON.stringify({ size, pack_size })} */}
                  {configurable_options?.length ? (
                    <div className="product-pack-size product-pack-size--options">
                      {configurable_options.map((coption, coptionIndex) => (
                        <div key={`cioption-${coptionIndex}`}>
                          {coption.value_label}
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {(pack_size || size) && !configurable_options?.length ? (
                    <div className="product-pack-size">
                      {/* {size ? <span >{size}</span> : null} */}
                      {pack_size ? <span>{pack_size}</span> : null}
                    </div>
                  ) : null}
                </div>
              )}
              {/* {JSON.stringify({ configurable_options })} */}
              {!inStock && (
                <div className="cart-out-of-stock">
                  Current item is out of stock
                </div>
              )}
            </div>
          )
        })}
    </>
  )
}
