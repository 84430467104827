import { useAppContext } from '@/core/App/AppContext'
import PersistanceStorage from '@/utils/storage'
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import { getToken, refreshToken, removeToken } from '@/utils/auth'
import { removeCartId } from '@/utils/cart'
import { gql } from 'graphql-request'
import { createContext, useContext, useMemo, useReducer } from 'react'
import {
  addProductsToCompareList,
  createCompareList,
  removeProductsFromCompareList,
} from 'src/graphql/mutations/customermutation'
import { compareList } from 'src/graphql/queries/customerQuery'
import we from 'src/services/webengage'

const AuthContext = createContext<any>({})
export enum AuthActions {
  LOGOUT = 'AUTH_LOGOUT',
  LOGIN = 'AUTH_LOGIN',
  RELOAD = 'AUTH_RELOAD',
  LOADING = 'AUTH_LOADING',
  LOADING_DONE = 'AUTH_LOADING_DONE',
  UPDATE_ADDRESS = 'AUTH_UPDATE_ADDRESS',
  ADD_ADDRESS = 'AUTH_ADD_ADDRESS',
  REMOVE_ADDRESS = 'AUTH_REMOVE_ADDRESS',
  RELOAD_CHECKLIST = 'AUTH_RELOAD_CHECKLIST',
}
const authReducer = (state, action) => {
  switch (action.type) {
    case AuthActions.LOGIN:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loaded: true,
        loadingStatus: 'success',
      }
    case AuthActions.LOADING:
      return {
        ...state,
        ...action.payload,
        loadingStatus: 'loading',
      }
    case AuthActions.LOADING_DONE:
      return {
        ...state,
        ...action.payload,
        loadingStatus: 'success',
      }

    case AuthActions.RELOAD:
    case 'addCustomer':
      return {
        ...state,
        ...action.payload,
        loadingStatus: 'loading',
      }

    case AuthActions.REMOVE_ADDRESS:
      return {
        ...state,
        addresses: [
          ...state.addresses.filter((add) => add.id != action.payload.id),
        ],
      }

    case AuthActions.UPDATE_ADDRESS:
      const index = state.addresses.findIndex(
        (add) => add.id == action.payload.id
      )
      state.addresses[index] = action.payload
      return {
        ...state,
        addresses: [...state.addresses],
      }

    case AuthActions.ADD_ADDRESS:
      return {
        ...state,
        addresses: [...state.addresses, action.payload],
      }

    case AuthActions.LOGOUT:
      return initialState

    default:
      return state
  }
}
const initialState = {
  isAuthenticated: false,
  token: null,
  loaded: false,
  loadingStatus: 'idle',
}

function init(initialState) {
  return initialState
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState, init)
  const [{ mitter }] = useAppContext()

  const value = useMemo(() => {
    const isSignedIn = async () => {
      const _isSignedIn = Boolean(getToken())

      if (!_isSignedIn && state.isAuthenticated) {
        // since logout is async and slow, remove cart id
        // and set customer sign in status here on auth token expiration
        removeCartId(true)
        await logout()
      } else if (_isSignedIn && state.isAuthenticated) {
        refreshToken()
      }
      return _isSignedIn
    }

    const logout = () => {
      return graphqlWebClient
        .request(
          gql`
            mutation {
              revokeCustomerToken {
                result
              }
            }
          `
        )
        .then((d) => {
          removeCartId()
          removeToken()
          dispatch({ type: AuthActions.LOGOUT })
          mitter.emit('LOGOUT')
        })
    }
    const getWistlistId = () => {
      return graphqlWebClient
        .request(
          gql`
            {
              customer {
                wishlists {
                  id
                }
              }
            }
          `
        )
        .then((d) => {
          try {
            return d.customer.wishlists[0]?.id
          } catch (e) {}
          return null
        })
    }

    const addToWishlist = async (wishlistItem) => {
      const signedIn = await isSignedIn()
      if (!signedIn) {
        mitter.emit(
          'TOGGLE_LOGIN_MODEL',
          'Sign in is required for adding to favourite'
        )
      } else {
        ;(state.wishlist ? Promise.resolve(state.wishlist.id) : getWistlistId())
          .then((wishlistId) => {
            return import('src/services/wishlist/addProductsToWishlist').then(
              (d) => d.default(wishlistId, [wishlistItem])
            )
          })
          .then((wishlist) => {
            wishlist.items_v2.items.forEach((item) =>
              item.product.sku == wishlistItem.sku ? (
                <>
                  {we.event('Added To wishlist', {
                    'Product Id': `${item?.product?.selected_variant?.sku || item?.product?.sku}`,
                    'Product ID': `${item?.product?.selected_variant?.id || item?.product?.id}`,
                    'Product Name': `${item?.product?.name}`,
                    // 'Category Id': `${item?.product?.categories?.map((i)=>(i?.uid))}`,
                    'Category Id': ``,
                    'Category Name': `${item?.product?.categories?.map(
                      (i) => i?.name
                    )}`,
                    'SubCategory Name': '',
                    'SubCategory Id': '',
                    Brand: 'Moha',
                    Manufacturer: 'Moha',
                    'Retail Price':
                      item?.product?.price_range?.minimum_price?.final_price
                        ?.value,
                    Discount:
                      item?.product?.price_range?.minimum_price?.discount
                        ?.amount_off,
                    Price:
                      item?.product?.price_range?.minimum_price?.final_price
                        ?.value +
                      item?.product?.price_range?.minimum_price?.discount
                        ?.amount_off,
                    currency: `${item?.product?.price_range?.minimum_price?.final_price?.currency}`,
                    Size: `${
                      item?.__typename == 'ConfigurableCartItem'
                        ? item?.configurable_options[0]?.value_label
                        : item?.product?.pack_size
                    }`,
                    Color: '',
                    Image: `${item?.product?.image?.url}`,
                  })}
                </>
              ) : null
            )
            dispatch({ type: AuthActions.RELOAD, payload: { wishlist } })
            mitter.emit('ShowToaster', {
              message: 'Product Added To WishList',
            })
          })
      }
    }
    const addToCompareList = async (id) => {
      const storage = new PersistanceStorage()
      const uid = storage.getItem('compare_id')
      if (uid) {
        graphqlWebClient
          .request(addProductsToCompareList, {
            input: {
              uid: uid,
              products: [id],
            },
          })
          .then(({ addProductsToCompareList: { items } }) => {
            dispatch({
              type: AuthActions.RELOAD,
              payload: {
                compareList: items,
              },
            })
            storage.setItem('compare_items', items)
            mitter.emit('ShowToaster', {
              message: 'Product Added To CompareList',
            })
          })
      } else {
        graphqlWebClient
          .request(createCompareList, {
            input: {
              products: [id],
            },
          })
          .then(({ createCompareList: { uid, items } }) => {
            dispatch({
              type: AuthActions.RELOAD,
              payload: {
                compareList: items,
              },
            })
            mitter.emit('ShowToaster', {
              message: 'Product Added To CompareList',
            })
            storage.setItem('compare_items', items)
            storage.setItem('compare_id', uid)
          })
      }
    }
    const removeCompareList = async (id) => {
      const storage = new PersistanceStorage()
      const uid = storage.getItem('compare_id')
      if (uid) {
        graphqlWebClient
          .request(removeProductsFromCompareList, {
            input: {
              uid: uid,
              products: [id],
            },
          })
          .then(({ removeProductsFromCompareList: { items } }) => {
            dispatch({
              type: AuthActions.RELOAD,
              payload: {
                compareList: items,
              },
            })
            storage.setItem('compare_items', items)
            mitter.emit('ShowToaster', {
              message: 'Product Removed From CompareList',
            })
          })
      }
    }
    const reloadLogin = () => {
      mitter.emit('RELOAD_LOGIN')
    }

    return [
      { ...state },
      dispatch,
      {
        isSignedIn,
        logout,
        reloadLogin,
        addToWishlist,
        addToCompareList,
        removeCompareList,
      },
    ]
  }, [state, dispatch])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuthContext = () => useContext(AuthContext)
export default useAuthContext
