// import customer from '@/services/graphql/customer';
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import { gql } from 'graphql-request'
import cart from 'src/graphql/queries/cart'
import { customer } from 'src/graphql/queries/loginQuery'

export const getCustomerWithCart = async (full = false) => {
  //
  try {
    // ${customer()} // add it later when completed migrated to graphql
    const data = await graphqlWebClient.request(gql`{
        ${customer}
        customerCart { ${cart({
          applied_coupons: true,
          items: true,
          prices: true,
          filler_products: true,
          available_payment_methods: full,
          billing_address: full,
          selected_payment_method: full,
          shipping_addresses: full,
        })} }
      }`)
    data.cart = data.customerCart
    delete data.customerCart
    return data
  } catch (e) {
    // console.log(e);
    if (e.response) {
      return e.response.data
    }
    return {
      err: e,
    }
  }
}
// # ${additional_queries}
