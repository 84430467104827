import { imageQuery } from './image'
import price_range from './price_range'

export const bundleProduct = `
    ... on BundleProduct{
      options: items {
        option_id
        value: options {
            id
            uid
        }
      }
    }
`
export default function product_items({ productExtendQueryBoolean = '' }) {
  return `

          ... on MageWorxGiftCards {
            mageworx_gc_type
            mageworx_gc_additional_price {
              label
              value
            }
          }
          ${bundleProduct}
          ... on ConfigurableProduct {
            configurable_options {
              position
              attribute_code
              use_default
              attribute_uid
              label
              values {
                use_default_value
                swatch_data {
                  value
                }
                label
                uid
              }
            }
            variants {
              product {
                sku
                name
                stock_status
                pack_size
                size
                bestseller
                price_range {
                  ${price_range()}
                }
              }
              attributes {
                code
                uid
                value_index
                label
              }
            }
          }
          ${productExtendQueryBoolean}
          bogo
          id
          uid
          __typename
          url_key
          name
          pack_size
          sku
          glowPoints
          rating_summary
          stock_status
          review_count
          key_ingredients
          excerpt
          ${imageQuery}
          special_price
          price_range {
            ${price_range(true)}
          }
  `
}
