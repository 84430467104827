declare var ga
export const gaEvent = (
  category: string,
  action: string,
  label: string,
  value = 1
) => {
  let tracker

  if (!tracker) {
    if ('ga' in window) {
      tracker = ga.getAll()[0]
    }
  }

  if (tracker && tracker.send) {
      tracker.send('event', category, action, label, value, { nonInteraction: 1 })
  }
}

export const aPIEvent = (action: string, label: string, startTime: Date) => {
  let diff = Math.round((new Date().valueOf() - startTime.valueOf()) / 1000)
  console.log('action', action)
  gaEvent('API Calls', action, label, diff)
}

export const getError = (error) => {
  if (error) {
    return error?.response?.errors[0]?.message || String(error)
  }
  return 'Error could not be detected'
}
