import cart from "../queries/cart";
import { imageQuery } from "../queries/product/image";
import price_range from "../queries/product/price_range";

export const updateCustomer = `
mutation( $input: CustomerUpdateInput! ){
    updateCustomerV2( input: $input ){
        customer {
            firstname
            lastname
            date_of_birth
            email
            webengage_whatsapp_optin
          }
        }
      }
      `;

export const createAccountOTP = (number) => `
mutation {
    createAccountOTP(
        mobilenumber:"${number}",
        websiteId:1
    )
        {
            status
            message
        }
}`;
export const createAccountOTPVerify = (number, otp) => ` {
    createAccountOTPVerify(
        mobilenumber:"${number}",
        otp:"${otp}"
        websiteId:1
    )
        {
            status
            message
        }
}`;
export const createCustomerAccount = ({
  firtname,
  lastname,
  otp,
  mobilenumber,
  email,
}) => `
mutation{ 
    createCustomerAccount(
        input:{
            firstname:"${firtname}"
            lastname:"${lastname}"
            password:"Vishal@123"
        }
            otp:"${otp}"
            mobilenumber:"${mobilenumber}"
            websiteId:1
            email:"${email}"
        )
    {
        customer  
        {
            firstname
        }
        token
        status
        message
       }
    }
`;

const customerAddress = `    id
firstname
lastname
street
postcode
telephone
region{
  region
  region_id
  region_code
}
city`

export const updateCustomerAddress = `
mutation(
  $id: Int! 
  $input: CustomerAddressInput 
){
  updateCustomerAddress( id:$id, input: $input ){ ${customerAddress} }
}
`;

export const createCustomerAddress = `
mutation( 
  $input: CustomerAddressInput! 
) {
   createCustomerAddress( input: $input ){ ${customerAddress} }
}
`;
export const deleteCustomerAddress = (id) => `
mutation {
    deleteCustomerAddress( id:${id} ) 
  }
`;

export const mstRewardsApplyPointsToCart = (id, amount) => `
mutation {
    mstRewardsApplyPointsToCart(
      input: { cart_id: "${id}", amount: ${amount} }
    ) {
      cart {
        ${cart({
  // applied_coupons: true,
  // filler_products: true,
  // items: true,
  prices: true,
  // available_payment_methods: true,
  // billing_address: true,
  // selected_payment_method: full,
  // shipping_addresses: true,
})}
      }
    }
  }
`;

export const raiseComplaint = ({
  order_number,
  item_name,
  description,
  sku,
  file_1: { name, base64_encoded_file },
}) => `
mutation {
    RaiseComplaint(
        order_number: "${order_number}"
        item_name: "${item_name}"
        description:"${description}" 
        sku:"${sku}"
        file_1:{
          name:"${name}"
          base64_encoded_file:"${base64_encoded_file}"
        }
    ) {
      message
      status
    }
  }
`;
export const emailQuote = `
mutation(
  $first_name: String
  $last_name: String
  $email: String!
  $phone: String!
  $comment: String
  $guest_cart_id: String
) {
    EmailQuote(
        first_name: $first_name,
        last_name: $last_name,
        email: $email,
        phone: $phone,
        comment: $comment,
        guest_cart_id: $guest_cart_id,
    ) {
      message
      status
    }
  }
`;
export const cancelOrder = ({ message, order_number }) => `
mutation {
    CancelOrder(
        order_number: "${order_number}"
        description: "${message}"
    ) {
      message
      status
    }
  }
`;
export const updateCustomerNote = ({ cart_id, cartnote }) => `
mutation{
  updateCustomerNote(input:{
    cart_id:"${cart_id}",
    note:"${cartnote}"
    }
  ){
    order_comment
  }
}
`;
// cart {
// }
export const notifyOutofStock = (p_id) => `
mutation{
  NotifyOutofStock(product_id:${p_id})
  {
    status
    message
  }
}
`;
export const savecontactform = ({
  first_name,
  last_name,
  email,
  phone,
  message,
}) => `
mutation{
  savecontactform(
  first_name:"${first_name}"
  last_name:"${last_name}"
  email:"${email}"
  phone:"${phone}"
  message:"${message}")
  {
    message
  }
}
`;
export const referralGraphql = ({ referral_code, quote_id = "0" }) => `
mutation{
  referralGraphql(referral_code: "${referral_code}", quote_id: "${quote_id}"){
  quote_id
  referral_code
    }
  }
`;


const compareList = `items{
  product{
    short_description{
      html
    }
    description{
      html
    }
    ... on MageWorxGiftCards {
        mageworx_gc_type
        mageworx_gc_additional_price {
          label
          value
        } 
      }
      ... on ConfigurableProduct {
        configurable_options {
          position
          attribute_code
          use_default
          attribute_uid
          label
          values {
            use_default_value
            swatch_data {
              value
            }
            label
            uid
          }
        }
        variants {
          product {
            sku
            name
            stock_status
            price_range {
              ${price_range()}
            }
          }
          attributes {
            code
            uid
            value_index
            label
          }
        }
      }
      bogo
      id
      __typename
      url_key
      name
      pack_size
      sku
      glowPoints
      rating_summary
      ${imageQuery}
      price_range {
        ${price_range(true)}
      }
    }
   }`


export const createCompareList = `
  mutation( $input : CreateCompareListInput){
    createCompareList ( input : $input ){
      uid
      ${compareList}
    }
  }
`

export const addProductsToCompareList = `
  mutation($input : AddProductsToCompareListInput){
    addProductsToCompareList( input : $input){
      uid
      ${compareList}
    }
  }
`
export const removeProductsFromCompareList = `
  mutation($input : RemoveProductsFromCompareListInput){
    removeProductsFromCompareList( input : $input){
      uid
      ${compareList}
    }
  }
`

export const removeProductsFromWishlist = `
  mutation(
    $wishlistId: ID!
    $wishlistItemsIds: [ID!]!
  )
   {
    removeProductsFromWishlist(
      wishlistId: $wishlistId
      wishlistItemsIds: $wishlistItemsIds
    ){
      wishlist {
        items_v2{
          items{
            id
          }
        }
      }
    }
  }
`