import Link from '@/core/Link'
import clsx from 'clsx'
import { useAppContext } from '@/core/App/AppContext'
import './header-top.scss'
import { useRouterContext } from '@/core/Router/RouterContext'
import useCartContext from '../../CartWrapper/useCartContext'
import useAuthContext from '../../Contexts/AuthContext'
import LoginModal from '../../LoginComponent/LoginModal'
import { useRef, useState } from 'react'
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import { productSearch } from 'src/graphql/queries/productQuery'
import Logo from './Logo'
import UserIcon from 'src/theme/icons/UserIcon'
import CartIcon from 'src/theme/icons/CartIcon'
import SearchInputForm from '../../SearchInput/SearchInputForm'
import { useMemo } from 'react'
import SearchIcon from '@/icons/SearchIcon'
import { FEAT_SUBSCRPTION_ACTIVE } from 'src/config'

const visibleOnlyItems = (items) =>
  items?.filter((mi) => mi.include_in_menu == 1)

export default function HeaderTop(props) {
  const { onCartToggle } = props
  const [{ mitter, menu, isMobile, storeConfig, content: offer_text }] =
    useAppContext()
  let menuItems = useMemo(() => {
    return menu?.children[0]?.children
  }, [menu])
  menuItems = visibleOnlyItems(menuItems)

  const [cartState] = useCartContext()
  const { items, total_quantity } = cartState
  const cartItemsQty = items.reduce((acc, init) => init.quantity + acc, 0) || 0
  const router = useRouterContext()
  const [auth] = useAuthContext()
  const [open, openModal] = useState(false)
  const inputEl = useRef(null)
  const [searchProduct, setSerchProduct] = useState([])
  const [isSticky, setSticky] = useState(false)
  const [isScroll, checkScroll] = useState(false)
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false)
  const additionLogoUrl = '/media/logo/'
  const headerTopRef = useRef(null)

  function debounce(delay) {
    // this function is called when user search in searchbar
    let timeoutID
    return function () {
      if (timeoutID) clearTimeout(timeoutID) // if there is continuos typing each time cleartimout will clear the timeoutId
      timeoutID = setTimeout(() => {
        // console.log(!inputEl.current.value);
        if (!!inputEl.current.value) {
          graphqlWebClient
            .request(productSearch(inputEl.current.value))
            .then((data) => {
              setSerchProduct(data.products.items)
            })
        }
      }, delay)
    }
  }
  const onClick = (e) => {
    mitter.emit('TOGGLE_NAV', e)
  }

  return (
    <>
      <div className="header-top header-dsk" ref={headerTopRef}>
        <div className="header-left blank">
          <span
            data-action="toggle-nav"
            className="nav-toggle icon"
            onClick={onClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={26}
              height={18}
              viewBox="0 0 26 18"
              fill="none"
              className="w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.3952 1.91675C25.3952 2.19854 25.2832 2.46879 25.084 2.66805C24.8847 2.86731 24.6145 2.97925 24.3327 2.97925H1.66602C1.38422 2.97925 1.11397 2.86731 0.914714 2.66805C0.715457 2.46879 0.603516 2.19854 0.603516 1.91675C0.603516 1.63496 0.715457 1.3647 0.914714 1.16545C1.11397 0.96619 1.38422 0.854248 1.66602 0.854248L24.3327 0.854248C24.6145 0.854248 24.8847 0.96619 25.084 1.16545C25.2832 1.3647 25.3952 1.63496 25.3952 1.91675ZM25.3952 9.00008C25.3952 9.28187 25.2832 9.55212 25.084 9.75138C24.8847 9.95064 24.6145 10.0626 24.3327 10.0626H1.66602C1.38422 10.0626 1.11397 9.95064 0.914714 9.75138C0.715457 9.55212 0.603516 9.28187 0.603516 9.00008C0.603516 8.71829 0.715457 8.44804 0.914714 8.24878C1.11397 8.04952 1.38422 7.93758 1.66602 7.93758H24.3327C24.6145 7.93758 24.8847 8.04952 25.084 8.24878C25.2832 8.44804 25.3952 8.71829 25.3952 9.00008ZM18.5 16.0834C18.5 16.3652 18.3881 16.6355 18.1888 16.8347C17.9895 17.034 17.7193 17.1459 17.4375 17.1459H1.66602C1.38422 17.1459 1.11397 17.034 0.914714 16.8347C0.715457 16.6355 0.603516 16.3652 0.603516 16.0834C0.603516 15.8016 0.715457 15.5314 0.914714 15.3321C1.11397 15.1329 1.38422 15.0209 1.66602 15.0209H17.4375C17.7193 15.0209 17.9895 15.1329 18.1888 15.3321C18.3881 15.5314 18.5 15.8016 18.5 16.0834Z"
                fill="#252525"
                stroke="#252525"
                stroke-width="0.25"
              />
            </svg>

            <span>Toggle Nav</span>
          </span>
          {/* <div className="block-search" id="block-search"></div> */}
        </div>
        {/* header left */}
        <div className="header_center">
          <Link
            href="/"
            className="header-logo"
            onClick={() => {
              const navEvent = new PopStateEvent('popstate')
              window.dispatchEvent(navEvent)
            }}
          >
            <Logo />
            {/* <img
              className="div-logo"
              src={logo}
              title="Vedistry"
              alt="Vedistry Logo"
            /> */}
          </Link>
        </div>
        <div className="memu-sec">
          <nav className="navigation">
            <ul className="menu d-flex">
              {/* {console.log({ menuItems })} */}
              {menuItems?.map((mitem, mitemIndex) => (
                <li className="dropdown" key={mitemIndex}>
                  <Link href={mitem.url_path}>
                    {mitem.name}{' '}
                    <svg
                      className="icon"
                      width="14"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" />
                    </svg>
                  </Link>
                  <ul className="dropdown-nav category-menu-list">
                    {visibleOnlyItems(mitem.children)?.map(
                      (msubitem, msubitemIndex) => (
                        <li key={msubitemIndex}>
                          <Link href={'/' + msubitem.url_path}>
                            <strong>{msubitem.name}</strong>
                          </Link>
                          <ul className="unstyled-list">
                            {visibleOnlyItems(msubitem.children)?.map(
                              (smsubitem, smsubitemIndex) => (
                                <li key={smsubitemIndex}>
                                  <Link href={'/' + smsubitem.url_path}>
                                    {smsubitem.name}
                                  </Link>
                                </li>
                              )
                            )}
                          </ul>
                        </li>
                      )
                    )}
                  </ul>
                </li>
              ))}
              {FEAT_SUBSCRPTION_ACTIVE ? (
                <li>
                  <a href="/healthmantra">Vedistry Health Mantra</a>
                </li>
              ) : null}
              <li>
                <Link href="/about-us">About Us </Link>
              </li>
              <li>
                <Link href="/blog">Blog</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header_right header-right">
          <SearchInputForm />

          <div
            className="headertoggle_img"
            onClick={() => {
              auth.isAuthenticated
                ? router.push('/my-account/profile', {})
                : mitter.emit('TOGGLE_LOGIN_MODEL')
            }}
          >
            <UserIcon />
          </div>
          <div
            onClick={onCartToggle}
            className={clsx(
              'header-mincart cart-toggle'
              // cartState.openMiniCart && 'open-cart'
            )}
          >
            <div className="minicart-wrapper">
              <div className="action showcart"></div>
              <div className="cart-counter">
                <span className="counter qty empty">
                  {cartItemsQty}
                  {/* <span className="counter-number">
                    </span> */}
                </span>
                {/* <span data-bind="html: getCartParam('subtotal')" className="subtotal"><span class="price">₹0.00</span></span> */}
              </div>
              <CartIcon />
            </div>
          </div>
        </div>
        <LoginModal />
      </div>
    </>
  )
}
