import { createContext, useContext, useMemo, useState } from "react";
import BreadCrumb from "@/theme/components/BreadCrumb";
import processOptionSelections from "src/services/processOptionSelections";
import { useAppContext } from "@/core/App/AppContext";

const ProductContext = createContext<any>({});

const useProductContext = () => useContext(ProductContext);
export const ProductContextProvider = ({ product, children }) => {
  const initialState = product.defaultCartItem || {};
  const { sku, cart_item_uid, bogo } = initialState;
  const [quantity, setQuantity] = useState(initialState.quantity || 1);
  const [selected_options, setSelectedOptions] = useState(
    initialState.selected_options || {}
  );
  const cartItem = {
    sku,
    quantity,
    selected_options,
  };

  const {
    price,
    stock_status,
    amount_off,
    percent_off,
    list_price,
    selected_variant,
    bundle_options
  } = processOptionSelections(product, cartItem);

  const selectOption = (code, uid) => {
    setSelectedOptions({
      ...selected_options,
      [code]: uid,
    });
  };

  const getCartItem = () => {
    if (bundle_options) {
      return bundle_options
    }
    return {
      sku,
      quantity: bogo == 1 ? quantity * 2 : quantity,
      selected_options: Object.values(selected_options),
    };
  };

  const value = useMemo(() => {
    return {
      ...product,
      price,
      stock_status,
      amount_off,
      percent_off,
      list_price,
      selected_variant,
      cartItem
    };
  }, [
    product,
    price,
    stock_status,
    amount_off,
    percent_off,
    list_price,
    quantity,
    selected_variant.sku,
  ]);

  return (
    <ProductContext.Provider
      value={[
        value,
        {
          selectOption,
          setQuantity,
          getCartItem,
        },
      ]}
    >
      {children}
    </ProductContext.Provider>
  );
};
export default useProductContext;
