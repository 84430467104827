import { graphqlWebClient } from "@/theme/lib/graphqlClient";
import cart from "src/graphql/queries/cart";

export default async function addSubscriptionToCart(cart_id, plan_id) {
  const {
    applySubscriptionToCart: { cart: cartObj },
  } = await graphqlWebClient.request(
    `mutation (
        $cart_id: String!
        $plan_id: String!
      ) {
        applySubscriptionToCart(
          input: {
            cart_id: $cart_id,
            plan_id: $plan_id
          }
        ) {
          cart {
            ${cart({
              subscription_plan: true,
              applied_coupons: true,
              // filler_products: true,
              items: true,
              prices: true,
              // available_payment_methods: full,
              // billing_address: full,
              // selected_payment_method: full,
              // shipping_addresses: full,
            })}
          }
        }
      }`,
    { cart_id, plan_id }
  );

  return cartObj;
}
