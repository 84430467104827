import useGraphQLQuery from '@/theme/lib/useGraphQLQuery'
import { gql } from 'graphql-request'
import { useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useInView } from 'react-intersection-observer'
import HeadingViewAll from '../HeadingViewAll'
import RatingResult from '../Ratings/RatingResult'
import RatingSummary from '../Ratings/RatingSummary'
import ReviewAdd from '../Ratings/ReviewAdd/ReviewAdd'

const starArrayV2 = [
   {
      l: 'five_star',
      v: '5',
   },
   {
      l: 'four_star',
      v: '4',
   },
   {
      l: 'three_star',
      v: '3',
   },
   {
      l: 'two_star',
      v: '2',
   },
   {
      l: 'one_star',
      v: '1',
   },
]

const ConsolidatedReviewBase = ({ sku }) => {
   const [openForm, setOpenForm] = useState(false)
   const [{ data }] = useGraphQLQuery(
      gql`
      query getConsolidatedReview($sku: String) {
        consolidatedReview(sku: $sku) {
          consolidated_review
          review_count
          rows {
            rating
            count
          }
          starwise_rating {
            five_star
            four_star
            three_star
            two_star
            one_star
          }
        }
      }
    `,
      {
         sku,
      },
      {
         immediate: true,
         fn: (d) => d.consolidatedReview,
      }
   )

   const consolidated_review = data?.consolidated_review || 0
   // Get avg
   // ((12) / 26) * 100
   // Get percentage
   // (12 / 26) * 100
   const starwise_rating = data?.starwise_rating[0] || {}
   const review_count = data?.review_count || 0
   let avgRating = 0
   if (starwise_rating) {
      Object.keys(starwise_rating).map((ok) => {
         let _star = starArrayV2.find((_row) => ok == _row.l)
         if (_star && starwise_rating[ok]) {
            avgRating += parseInt(_star.v) * parseInt(starwise_rating[ok])
         } else {
            avgRating += 0
         }
      })
   }
   avgRating = avgRating / review_count
   if (isNaN(avgRating)) {
      avgRating = 0
   }
   return (
      <>
         {/* {JSON.stringify({ avgRating })} */}
         <div className="review-average">
            <div className="review-average-section review-average-left">
               <div className="review-average-count">
                  {Number(avgRating).toFixed(1)}{' '}
               </div>
               <div className="review-average-stars">
                  <RatingResult value={avgRating > 0 ? avgRating / 5 : 0} />
               </div>
            </div>
            <div className="review-average-section review-average-right">
               <div className="review-bar">
                  {starArrayV2.map((data, key) => (
                     <ReviewHistogram
                        key={key}
                        label={`${data.v} star`}
                        total={review_count}
                        value={starwise_rating[data.l] || 0}
                     />
                  ))}
               </div>
            </div>
         </div>
         <button
            type="button"
            onClick={() => setOpenForm((prev) => !prev)}
            className="primary custom-primary-btn mt-5 mb-5"
         >
            Write a Review
         </button>
         {openForm && <ReviewAdd />}
      </>
   )
}
export const ProductRatingNumber = ({ sku }) => {
   const [{ data }] = useGraphQLQuery(
      gql`
      query getConsolidatedReview($sku: String) {
        consolidatedReview(sku: $sku) {
          consolidated_review
          review_count
          rows {
            rating
            count
          }
          starwise_rating {
            five_star
            four_star
            three_star
            two_star
            one_star
          }
        }
      }
    `,
      {
         sku,
      },
      {
         immediate: true,
         fn: (d) => d.consolidatedReview,
      }
   )

   const consolidated_review = data?.consolidated_review || 0
   // Get avg
   // ((12) / 26) * 100
   // Get percentage
   // (12 / 26) * 100
   const starwise_rating = data?.starwise_rating[0] || {}
   const review_count = data?.review_count || 0
   let avgRating = 0
   if (starwise_rating) {
      Object.keys(starwise_rating).map((ok) => {
         let _star = starArrayV2.find((_row) => ok == _row.l)
         if (_star && starwise_rating[ok]) {
            avgRating += parseInt(_star.v) * parseInt(starwise_rating[ok])
         } else {
            avgRating += 0
         }
      })
   }
   avgRating = avgRating / review_count
   if (isNaN(avgRating)) {
      avgRating = 0
   }
   return (
      <>
         <div className="rating">
            <svg
               width="11"
               height="11"
               viewBox="0 0 11 11"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z"
                  fill="#545454"
               />
            </svg>{' '}
            {Number(avgRating).toFixed(1)}
            {' | '}
            {review_count} Reviews
         </div>
      </>
   )
}

function getPercentage(num, per) {
   return Math.round((num / 100) * per)
}

const ReviewHistogram = (props) => {
   const count = getPercentage(props.total, props.value)
   const progressWidth = () => {
      // console.log({props})
      let result = (parseInt(props.value) / parseInt(props.total)) * 100
      if (result <= 0 || isNaN(result)) {
         return 0
      }
      return result.toFixed(0)
   }
   return (
      <>
         <div className="review-bar-inner">
            <div className="">{props.label}</div>
            <div className="">
               <ProgressBar now={progressWidth()} />
            </div>
         </div>
      </>
   )
}

export default function ConsolidatedReview({ sku }) {
   const { inView, ref } = useInView({
      triggerOnce: true,
      threshold: 0,
   })

   return (
      <div className="consolidated-review" ref={ref} id="product_reviews">
         <ConsolidatedReviewBase sku={sku} />
      </div>
   )
}
