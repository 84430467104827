import { useAppContext } from "@/core/App/AppContext";
import { useEffect } from "react";
import "./quantity-counter.scss";
export default function QuantityInput({
  quantity,
  disable = false,
  setQuantity,
}) {
  const [{ mitter }] = useAppContext()
  const onQuantityChange = (quantity) => {
    setQuantity(quantity);
  };

  const increment = () => {
    mitter.emit('NavigationStart', true);
    onQuantityChange(quantity + 1);
  };

  const decrement = () => {
    quantity > 1 && onQuantityChange(quantity - 1);
    quantity > 1 && mitter.emit('NavigationStart', true);
  };
  useEffect(()=>{
    disable == false && mitter.emit('NavigationEnd', false);
  },[quantity])
  return (
    <div className="field qty qty-wrapper">
      {/* <label className="label">
        <span>Qty</span>
      </label> */}
      <div className="control">
         <button
            disabled={disable}
            type="button"
            className="qty-decrease"
            onClick={decrement}
         >-</button>
        <input
          // {...inputProps}
          value={quantity}
          disabled={disable}
          type="text"
          id="quantity"
          title="Qty"
          className="input-text qty"
          onChange={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, "");
            // onChange(e)
          }}
        />
        <button
         disabled={disable}
         type="button"
         className="qty-increase"
         onClick={increment}
         >
         +
         </button>

      </div>
    </div>
  );
}
