import * as React from 'react'
import RouterContext from '@/core/Router/RouterContext'
import type { UrlObject } from 'url'
import type { ParsedUrlQuery } from 'querystring'

import {
  addBasePath,
  addLocale,
  delBasePath,
  delLocale,
  hasBasePath,
  isLocalURL,
} from '@/utils/router'

import { getURL, parseRelativeUrl } from '@/utils/router/utils'

import makeMatcher from '@/core/Router/matcher'

import {
  Params,
  RouteObject,
  RouteMatch,
  HistoryObject,
  TransitionOptions,
  HistoryChangeEvent,
  INavigationSource,
} from '@/core/Router/Router.Types'

import routes from '@/routes'

import { PureComponent } from 'react'
import { createElement } from 'react'
import SomethingWentWrong from '@/theme/route/SomethingWentWrong'
import Layout from '@/theme/components/Layout'
import { MittEmitter } from '@/utils/mitt'
import { HtmlHead } from '@/theme/components/HtmlHead'
import { sendPageView } from 'src/services/gtm'

type Url = UrlObject | string
type HistoryMethod = 'replaceState' | 'pushState' | 'popState'

interface RouteProperties {
  shallow?: boolean
}

let canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

let getLocation = (source) => {
  const { search, hash, href, origin, protocol, host, hostname, port } =
    source.location
  let { pathname } = source.location

  if (!pathname && href && canUseDOM) {
    const url = new URL(href)
    pathname = url.pathname
  }

  const encodedPathname = pathname
    .split('/')
    .map((pathPart) => encodeURIComponent(decodeURIComponent(pathPart)))
    .join('/')

  return {
    pathname: encodedPathname,
    search,
    hash,
    href,
    origin,
    protocol,
    host,
    hostname,
    port,
    state: source.history.state,
    _key: (source.history.state && source.history.state._key) || 0,
  }
}

RouterContext.displayName = 'RouterContext'

const basePath = (process.env.___ROUTER_BASEPATH as string) || ''
type Handler = (...evts: any[]) => void

class RouterBase {
  private transitioning = false
  private _inFlightRoute: string
  private _defaultLocale: string
  private _shallow: boolean
  private _key: number = 0
  private _location: Partial<Location>
  private _locale
  private _match: RouteMatch
  private _routeData: any
  private matcher = makeMatcher()
  private routes: RouteObject[] = []
  private query: ParsedUrlQuery
  private _isSsr: boolean
  private mitter: MittEmitter
  private all: { [s: string]: Handler[] } = Object.create(null)

  get routeData() {
    return this._routeData
  }

  get location() {
    return this._location
  }

  on(type: string, handler: Handler) {
    ;(this.all[type] || (this.all[type] = [])).push(handler)
  }

  off(type: string, handler: Handler) {
    if (this.all[type]) {
      this.all[type].splice(this.all[type].indexOf(handler) >>> 0, 1)
    }
  }

  emit(type: string, ...evts: any[]) {
    // eslint-disable-next-line array-callback-return
    ;(this.all[type] || []).slice().map((handler: Handler) => {
      handler(...evts)
    })
  }

  constructor(
    private source: INavigationSource,
    initialData: { props: any },
    mitter: MittEmitter,
    defaultListener: any
  ) {
    this.routes = routes
    this.mitter = mitter
    this._location = getLocation(this.source)
    this.findMatch(this.location.pathname)
    this._routeData = initialData.props
    const updater = () => {
      this._onTransitionComplete()
    }
    this.on('onUpdate', () => {
      defaultListener(updater)
    })

    source.addEventListener('popstate', this.handlePopState)

    this._isSsr = true
    if (typeof window !== 'undefined') {
      this.changeHistoryState(
        'replaceState',
        this.location.pathname + this.location.search,
        {}
      )
    }
  }
  _onChange({ location, action }) {
    if (action == 'PUSH') {
      window.scrollTo({
        top: 0,
      })
    }
    this.emit('onUpdate')
  }
  _resolveTransition: Function = () => {}
  _onTransitionComplete() {
    this.transitioning = false
    this._resolveTransition()
  }
  _navigate(to, { state = null, replace = false } = {}) {
    if (typeof to === 'number') {
      this.source.history.go(to)
    } else {
      state = { ...state }
      // try...catch iOS Safari limits to 100 pushState calls
      try {
        if (this.transitioning || replace) {
          this.source.history.replaceState(state, null, to)
        } else {
          this.source.history.pushState(state, null, to)
        }
      } catch (e) {
        this.source.location[replace ? 'replace' : 'assign'](to)
      }
    }

    this._location = getLocation(this.source)
    this.transitioning = true
    let transition = new Promise((res) => (this._resolveTransition = res))
    this._onChange({ location, action: 'PUSH' })
    return transition
  }

  get pathKey() {
    return this._match.route.path
  }
  render() {
    return this._match.route.component
      ? createElement(
          this._match.route.component,
          {
            ...this._routeData,
            params: this._match.params,
            location: this.location,
          },
          null
        )
      : null
  }
  _constructor() {
    this.source.removeEventListener('popstate', this.handlePopState)
  }
  async changeHistoryState(
    method: HistoryMethod,
    url: string,
    options: TransitionOptions = {}
  ) {
    if (method !== 'pushState' || getURL() !== url) {
      this._shallow = options.shallow
      await this._navigate(url, {
        replace: method == 'replaceState',
        state: {
          url,
          options,
          __X: true,
          _key: (this._key =
            method !== 'pushState' ? this._key : this._key + 1),
        },
      })
    }
  }

  private handlePopState = (event: HistoryChangeEvent): void => {
    const location = getLocation(this.source)
    const state = location.state
    if (!state) {
      // We get state as undefined for two reasons.
      //  1. With older safari (< 8) and older chrome (< 34)
      //  2. When the URL changed with #
      //
      // In the both cases, we don't need to proceed and change the route.
      // (as it's already changed)
      // But we can simply replace the state with the new changes.
      // Actually, for (1) we don't need to nothing. But it's hard to detect that event.
      // So, doing the following for (1) does no harm.
      this.changeHistoryState('replaceState', getURL())
      return
    }

    const url = location.pathname + location.search
    // location.state.url;
    const options = location.state.options || {}

    this._key = location._key
    const { pathname } = parseRelativeUrl(url)

    let forcedScroll: { x: number; y: number } | undefined
    if (this._isSsr == true && pathname === this.location.pathname) {
      return
    }

    this.navigate(
      'popState',
      url,
      Object.assign<{}, TransitionOptions, TransitionOptions>({}, options, {
        shallow: options.shallow && this._shallow,
        locale: options.locale || this._defaultLocale,
      }),
      forcedScroll
    )
  }

  replace = (href, options: TransitionOptions) => {
    performance.mark('route_replace_start')
  }
  push = (href, options: TransitionOptions = {}) => {
    performance.mark('route_push_start')
    try {
      // Snapshot scroll position right before navigating to a new page:
      sessionStorage.setItem(
        '__xint_scroll_' + this._key,
        JSON.stringify({ x: self.pageXOffset, y: self.pageYOffset })
      )
    } catch (e) {}

    return this.navigate('pushState', href, options)
  }

  async navigate(
    method: HistoryMethod,
    url: string,
    options: TransitionOptions,
    forcedScroll?: { x: number; y: number }
  ) {
    performance.mark('route_change_start')
    if (!isLocalURL(url)) {
      window.location.href = url
      return false
    }

    this._isSsr = false

    const prevLocale = this._locale

    if (this._inFlightRoute) {
      // this.abortComponentLoad(this._inFlightRoute, routeProps)
    }

    url = addBasePath(
      addLocale(
        hasBasePath(url) ? delBasePath(url) : url,
        options.locale,
        this._defaultLocale
      )
    )

    const cleanedUrl = delLocale(
      hasBasePath(url) ? delBasePath(url) : url,
      this._locale
    )

    this._inFlightRoute = url

    let localeChange = prevLocale !== this._locale

    // const { isOnlyAHashChange } = this.state;

    // if (isOnlyAHashChange && !localeChange) {
    //   // Router.events.emit('hashChangeStart', as, routeProps)
    //   // TODO: do we need the resolved href when only a hash change?
    //   // this.changeState(method, url, as, options)
    //   // this.scrollToHash(cleanedAs)
    //   // this.notify(this.components[this.route], null)
    //   // Router.events.emit('hashChangeComplete', as, routeProps)
    //   return true;
    // }

    let parsed = parseRelativeUrl(url)
    let { pathname, query, search } = parsed

    if (this.location.pathname == pathname && this.location.search == search) {
      window.scrollTo({
        top: 0,
      })
      return null
    }
    this.mitter.emit('NavigationStart', {
      method,
      url,
      options,
    })

    await this.getRouteInfo(pathname, search, {
      shallow: options.shallow,
    })

    this.query = query

    performance.mark('route_change_finished')

    if (method != 'popState') {
      this.changeHistoryState(method, url, options)
    } else {
      this._location = getLocation(this.source)
      window.scrollTo({
        top: 0,
      })
      this.emit('onUpdate')
    }
    performance.mark('route_change_end')
    this.mitter.emit('NavigationEnd')
    return false
  }

  private findMatch(pathname: string) {
    let _match: Params, _route: RouteObject
    this.routes.forEach((route) => {
      if (_match == null) {
        _route = route
        _match = route.path
          ? this.matcher(route.path, pathname || this.location.pathname)
          : {}
      }
    })

    this._match = {
      pathname,
      params: _match,
      route: _route,
    }
  }

  private async getRouteInfo(
    pathname: string,
    search: string,
    routeProps: RouteProperties
  ) {
    this.findMatch(pathname)
    const key = this._match.route.path
      ? this._match.route.path.toString()
      : 'default'

    const component = this._match.route.component as any

    this._match.route.component = await (component.load
      ? component.load().then((d) => d.default || d)
      : Promise.resolve(component))

    if (this._match.route.initialDataPath) {
      this._routeData = await this.fetchInitialData(pathname, search)
      const { type } = this._routeData
      try {
        if (
          this._match.route.component[type] &&
          this._match.route.component[type].load
        ) {
          await this._match.route.component[type].load()
        }
      } catch (e) {}
    }
  }

  private fetchInitialData(pathname, search) {
    const dataUrl = `/_data${
      pathname == '/' ? '/index' : pathname
    }.json${search}`

    return fetch(dataUrl)
      .then((d) => {
        if (d.ok) {
          return d.json()
        }
        return d.text().then((text) => {
          try {
            return JSON.parse(text)
          } catch (e) {}
          return {}
        })
      })
      .then((d) => d.props)
  }
}

export type RouterBaseType = typeof RouterBase.prototype
interface RouterProps {
  mitter: MittEmitter
  source?: INavigationSource
  routeData: {
    props: any
  }
  storeConfig: any
}
class Router extends PureComponent<RouterProps, any> {
  private _router: RouterBaseType
  private _initialListener
  private _cb
  constructor(props: RouterProps) {
    super(props)
    const { routeData, mitter, source } = props

    this._initialListener = (cb) => {
      this._cb = cb
      this.forceUpdate(() => {
        performance.mark('route_change_componented_rendered')
        this._cb()
      })
    }

    this._router = new RouterBase(
      source,
      routeData,
      mitter,
      this._initialListener
    )

    this.state = {
      path: this._router.pathKey,
      hasError: false,
      errorDetails: null,
    }
    // const { meta_title } = routeData?.props || {};
    // sendPageView(meta_title)
  }
  handleErrorReset = () => {
    this.setState({ hasError: false })
  }

  componentWillUnmount() {
    this._router._constructor()
  }

  componentDidMount() {
    this._cb && this._cb()
  }

  componentDidUpdate(
    prevProps: Readonly<RouterProps>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    // const {
    //   routeData: { jsonLd, meta_title, meta_description, canonical, robots },
    // } = this._router
    // sendPageView(meta_title)
  }

  renderRouterContent() {
    const { hasError, errorDetails, path } = this.state
    if (hasError) {
      return (
        <Layout>
          <SomethingWentWrong
            onClick={this.handleErrorReset}
            errorDetails={errorDetails}
          />
        </Layout>
      )
    }

    return <Layout>{this._router.render()}</Layout>
  }

  render() {
    let data = this.props.storeConfig ?? {}

    let { default_title, default_description } = data
    const {
      routeData: { jsonLd, meta_title, meta_description, canonical, robots },
    } = this._router
    return (
      <RouterContext.Provider value={this._router}>
        <HtmlHead
          baseUrl={process.env.APP_FRONTEND_URL}
          default_title={default_title}
          default_description={default_description}
        />
        {this.renderRouterContent()}
      </RouterContext.Provider>
    )
  }
}

export default Router
