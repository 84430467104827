import React from 'react'

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        stroke="#252525"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22.75 22.75l-4.705-4.705m0 0A8.668 8.668 0 008.6 3.91a8.667 8.667 0 109.445 14.136z"
      ></path>
    </svg>
  )
}

export default SearchIcon
