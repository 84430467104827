import { useInView } from "react-intersection-observer";

export default function RenderOnView({ children }) {
   const { ref, inView, entry } = useInView({
      /* Optional options */
      threshold: 0,
      triggerOnce: true,
   })
   // if (!inView) return null
   return (
      <div ref={ref} className="js-RenderOnView">
         {inView ? (
         <>{children}</>
         ): null}
      </div>
   )
}
