import PersistanceStorage from "@/utils/storage";
import { globalmitt } from "../mitt/globalmitt";

const storage = new PersistanceStorage();

export const removeToken = () => {
  globalmitt.emit("removeToken");
  // graphqlWebClient.setHeader("authorization", null);
  return storage.removeItem("authtoken");
};
export const getToken = () => {
  return storage.getItem("authtoken");
};
export const setToken = (token, ttl = 3600) => {
  // graphqlWebClient.setHeader('authorization', `Bearer ${token}`)
  globalmitt.emit("setToken", token);
  storage.setItem("authtoken", token, ttl);
};
export const refreshToken = () => setToken(getToken());
export const isInitiallySignedIn = () => !!getToken();
