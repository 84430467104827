export const isProductConfigurable = (product) =>
  product.__typename === 'ConfigurableProduct';

export const isProductBundle = (product) =>
  product.__typename === 'BundleProduct';

export const isProductSimple = (product) =>
  product.__typename === 'SimpleProduct';

export const isProductGrouped = (product) =>
  product.__typename === 'GroupedProduct';

export const isProductGift = (product) =>
  product.__typename === 'MageWorxGiftCards';

