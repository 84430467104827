import localStorage from '../../../server/plugins/localStorage';

class LocalStorage {
  constructor(private localStorage, private key) {}
  _makeKey(key) {
    return `${this.key}__${key}`;
  }
  getItem(name) {
    return this.localStorage.getItem(this._makeKey(name));
  }
  setItem(name, value) {
    return this.localStorage.setItem(this._makeKey(name), value);
  }
  removeItem(name) {
    return this.localStorage.removeItem(this._makeKey(name));
  }
}

export default class PersistanceStorage {
  static KEY = 'MOHA_PERSISTENCE';
  private storage;
  /* istanbul ignore next: test injects localstorage mock */
  constructor(lS?: any) {
    if (!lS) {
      lS = localStorage();
    }
    this.storage = new LocalStorage(
      lS,
      this.constructor['KEY'] || PersistanceStorage.KEY,
    );
  }
  getItem(name) {
    const now = Date.now();
    const item = this.storage.getItem(name);
    if (!item) {
      return undefined;
    }
    const { value, ttl, timeStored } = JSON.parse(item);
    if (ttl && now - timeStored > ttl * 1000) {
      this.storage.removeItem(name);
      return undefined;
    }
    if (value) return JSON.parse(value);
    return null;
  }
  setItem(name, value, ttl?) {
    const timeStored = Date.now();
    this.storage.setItem(
      name,
      JSON.stringify({
        value: JSON.stringify(value),
        timeStored,
        ttl,
      }),
    );
  }
  removeItem(name) {
    this.storage.removeItem(name);
  }
}
