import { PureComponent } from 'react';

import Link from '@/core/Link';
import Button from '@/theme/components/Button';

export class SomethingWentWrong extends PureComponent<any, any> {
  heading() {
    return null;
  }
  content() {
    return null;
  }
  render() {
    return <div className='container-width'>
      <div className='error-404 not-found'>
        <div className='text-center'>
          <img  src="/sad.png" alt="moha 404 page" />
          <h3 className="font-bold my-3">Oops! We couldn't find any matches!</h3>
          <Link href='/shop'>
            <Button>
              Continue Shopping
            </Button>
          </Link>
        </div>
      </div>
    </div>
  }

}

export default SomethingWentWrong;
