// import URL_RESOLVER from '@/services/graphql/fragments/urlResolver.graphql';
import graphql from '@/utils/graphql';
// import { URL } from 'url';
/**
 * Get params from the url for url resolver
 *
 * @param mtach - Match Request of the server
 */
export const getParams = (match: any) => {
   let m = match.params;
   let params = Object.keys(m).map((e) => m[e]);

   return params;
};

/**
 * Method to get the id and type of the url slug based details.
 *
 * @param url - to get the data from the magento entities
 */
export const urlResolver = async (url: any) => {
   // let data = await graphql(URL_RESOLVER, {
   //   url,
   // });
   // console.log(data);
   return /* data ||  */{};
};


export const assetsUrl = (path: string, params = {}) => {

   let url = new URL(process.env.ASSET_DOMAIN ?? process.env.APP_CDN_URL)
   if (path && path.startsWith('http')) {
      url = new URL(path)
      // url.pathname = path
   } else {
      url.pathname = path
   }
   if (params && Object.keys(params).length) {
      Object.keys(params).map((ok) => {
         url.searchParams.set(ok, params[ok])
      })
   }
   return url.toString()
}

export const mediaUrl = (path: string, params = {}) => {

   let url = new URL(process.env.APP_CDN_URL)
   if (path && path.startsWith('http')) {
      url = new URL(path)
      // url.pathname = path
   } else {
      url.pathname = `media/${path}`
   }
   if (params && Object.keys(params).length) {
      Object.keys(params).map((ok) => {
         url.searchParams.set(ok, params[ok])
      })
   }
   return url.toString()
}


export function parseEmail(value: string | any): string {
   if (!value) {
      return value
   }
   let email = value.trim().toLowerCase()
   return email
}
export function parseMobileNumber(number: string | any): string {
   if (!number) {
      return number
   }
   number = number.replace(/\D/g, '');
   if (number.length === 10) {
      number = '+91' + number;
   } else if (number.length === 12) {
      if (!number.startsWith("+")) {
         number = '+' + number;
      }
   }

   return number;
}
