import { FC } from 'react'
import useCartContext from '../CartWrapper/useCartContext'
import CartItem from './CartItem'
import ClubMemberCart from './ClubMemberCart'

const CartSubscriptionNote = ({ subscription }) => {
  return (
    <div className="card mx-3 card-body bg-info-subtle text-center">
      You have selected <b>{subscription.plan_name}</b>
    </div>
  )
}

const Cart: FC = (props) => {
  const [cartState, dispatch, { removeItem }] = useCartContext()
  const { items, total_quantity } = cartState

  return (
    <div className="">
      <div className="cart-content">
        {/* {JSON.stringify(items, null, 2)} */}
        {items?.map((cartitem, cartitemindex) => (
          <CartItem
            key={cartitem.uid}
            type={cartitem.__typename}
            uid={cartitem.uid}
            price={cartitem.prices?.price}
            pack_size={cartitem.pack_size}
            size={cartitem.size}
            configurable_options={cartitem.configurable_options}
            name={cartitem.product.name}
            imageUrl={cartitem.product.image.url}
            bogo={cartitem.product?.bogo}
            inStock={cartitem.product?.stock_status != 'OUT_OF_STOCK'}
            quantity={cartitem.quantity}
            onRemove={() => removeItem(cartitem.uid)}
          />
        ))}
        {cartState?.subscription_plan ? (
          <CartSubscriptionNote subscription={cartState?.subscription_plan} />
        ) : null}
        {/* <CartItem /> */}
        {/* {items?.length > 0 ? <ClubMemberCart /> : null} */}
      </div>
    </div>
  )
}

export default Cart
