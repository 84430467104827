import { getToken } from "@/utils/auth";
import { globalmitt } from "@/utils/mitt/globalmitt";
import { GraphQLClient } from "graphql-request";

const getTokenHeader = () => {
  const isBrowser = () => typeof window !== "undefined";
  if (isBrowser()) {
    const token = getToken();
    if (token) {
      return {
        authorization: `Bearer ${token}`,
      };
    }
  }

  return {};
};

const graphqlClient = new GraphQLClient(
  `${process.env.APP_MAGENTO_ADMIN_URL_PRIVATE}/graphql`,
  {
    method: "GET",
  }
);

export const graphqlWebClient = new GraphQLClient(`${process.env.APP_MAGENTO_ADMIN_URL}/graphql`, {
  // for client side queries
  method: "POST",
  headers: getTokenHeader(),
});

globalmitt.on("setToken", (token) => {
  graphqlWebClient.setHeader("authorization", `Bearer ${token}`);
  graphqlClient.setHeader("authorization", `Bearer ${token}`);
});
globalmitt.on("removeToken", (token) => {
  graphqlWebClient.setHeader("authorization", null);
  graphqlClient.setHeader("authorization", null);
});

export const graphqlGuestClient = new GraphQLClient(`${process.env.APP_MAGENTO_ADMIN_URL}/graphql`, {
  // for client side queries
  method: "GET",
});

export default graphqlClient;
