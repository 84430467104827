import { useAppContext } from '@/core/App/AppContext'
import { useEffect, useState } from 'react'
import { LoginComponent } from '.'
import useAuthContext from '../Contexts/AuthContext'
import { ModalComponent } from '../ModalComponent'

export default function LoginModal({ isVisible = false, onClose }) {
  const [isOpened, setIsOpened] = useState(() => isVisible)
  const [{ open, message }, setOpen] = useState({ open: false, message: null })
  const [{ mitter }] = useAppContext()
  useEffect(() => {
    function fn(message) {
      setOpen({ open: !open, message })
      setIsOpened((prev) => !prev)
    }
    mitter.on('TOGGLE_LOGIN_MODEL', fn)
    return () => mitter.off('TOGGLE_LOGIN_MODEL', fn)
  }, [open])

  const closeModal = () => {
    setOpen({
      open: false,
      message: null,
    })
    setIsOpened(false)
  }
  return (
    <LoginComponent isVisible={open} close={closeModal} message={message} />
    // <ModalComponent className={'login-modal'} isOpen={open} close={closeModal} title={message}>
    // </ModalComponent>
  )
}
