import { numberNotation } from '@/services/helpers/numberNotation'
import useCartContext from '@/theme/components/CartWrapper/useCartContext'
import { useEffect, useMemo, useState } from 'react'
import { Offcanvas, ProgressBar } from 'react-bootstrap'

const MIN_FREE_SHIPPING_AMOUNT = 399

function diffInFreeShippingAmt(value = 0) {
  return numberNotation({ value: MIN_FREE_SHIPPING_AMOUNT - value })
}
function percentageFreeShippingAmt(value = 0) {
  if (value >= MIN_FREE_SHIPPING_AMOUNT) return 100
  return Number((value / MIN_FREE_SHIPPING_AMOUNT) * 100)
}

export default function CartProgressBar(props) {
  const [cartState, , { isCartContainsBogoProduct }] = useCartContext()
  const [text, setText] = useState(props.defaultText)
  const [value, setValue] = useState(0)
  useEffect(() => {
    if (cartState?.prices?.subtotal_with_discount_excluding_tax) {
      let value = diffInFreeShippingAmt(
        cartState.prices.subtotal_with_discount_excluding_tax.value
      )
      let percentage = percentageFreeShippingAmt(
        cartState.prices.subtotal_with_discount_excluding_tax.value
      )
      if (value) {
        if (
          cartState?.prices?.subtotal_with_discount_excluding_tax.value >=
          MIN_FREE_SHIPPING_AMOUNT
        ) {
          setText(`You are eligible for free shipping!`)
        } else {
          setText(`You are ${value} away from free shipping!`)
        }
        setValue(percentage)
      }
    }
  }, [cartState])
  if (cartState?.items?.length == 0) {
    return null
  }
  return (
    <div className="off-title-top-bar">
      <ProgressBar now={value} />
      <div className="text-center position-relative">
        <label htmlFor="CartProgressBar">{text}</label>
      </div>
    </div>
  )
}

CartProgressBar.defaultProps = {
  defaultText: `-`,
}
