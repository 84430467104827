'use client'
import React from 'react'

// import ProductImg from "../../assets/img/product.png";
// import Link from "next/link";
import { Image } from '../Image'
import { CartProductPrice } from '../CartWrapper/CartTile'
import useCartContext from '../CartWrapper/useCartContext'
import QuantityInput from '../QuantityCounter/QuantityInput'
import BogoQuantityInput from '../QuantityCounter/BogoQuantityInput'
import { SUBSCRIPTION_PRODUCTS_SKUS } from 'src/config'

type ICartItem = {
   uid: String
   imageUrl: String
   name: String
   sku?: String
   product?: String
   caption?: String
   price?: any
   configurable_options?: any
   pack_size?: any
   size?: any
   quantity?: any
   checkout?: any
   bogo?: any
   inStock?: boolean
   onRemove?: () => void
}

export default function CartItem({
   uid,
   onRemove,
   imageUrl,
   name,
   sku,
   type,
   product,
   caption,
   price,
   size,
   pack_size,
   configurable_options,
   checkout = false,
   bogo = 0,
   quantity = 0,
   inStock = false,
}: ICartItem) {
   const [cartState, cartDispatch, { removeItem, updateCartItem }] =
      useCartContext()
   let showCounter = price.value == 0 || checkout ? false : true
   if (type == 'VirtualCartItem') {
      showCounter = false
   }

   const isSubscriptionItem = SUBSCRIPTION_PRODUCTS_SKUS.includes(sku)
   if (isSubscriptionItem) {
      showCounter = false
   }

   return (
      <div className="cart-item CartItem">
         {type == 'VirtualCartItem' ? (
            <div className="cart-image">
               <Image
                  className="img-fluid -border"
                  src={imageUrl}
                  alt={name}
                  aspectRatio={'1/1'}
               />
            </div>
         ) : (
            <div className="cart-image">
               <Image
                  className="img-fluid -border"
                  src={imageUrl}
                  alt={name}
                  aspectRatio={'1/1'}
               />
            </div>
         )}
         <div className="cart-title d-flex flex-wrap align-content-between justify-content-start flex-column">
            <h2 className="card-heading-text mb-3">{name}</h2>
            {caption ? <p>{caption}</p> : null}
            <div>
               {configurable_options?.length ? (
                  <div className="product-pack-size product-pack-size--options">
                     {configurable_options.map((coption, coptionIndex) => (
                        <p key={`cioption-${coptionIndex}`}>
                           <span>{coption.value_label}</span>{' '}

                        </p>
                     ))}
                  </div>
               ) : null}
               {(pack_size || size) && !configurable_options?.length ? (
                  <div className="product-pack-size">
                     {/* {size ? <span >{size}</span> : null} */}
                     {pack_size ? <span>{pack_size}</span> : null}
                  </div>
               ) : null}
               {checkout && (
                  <div>
                     <label className="label">Qty</label> {quantity}
                  </div>
               )}
               {showCounter ? (
                  <>
                     {bogo != 1 ? (
                        <QuantityInput
                           quantity={quantity}
                           setQuantity={(quantity) => {
                              updateCartItem({
                                 cart_item_uid: uid,
                                 quantity,
                              })
                           }}
                           disable={cartState.loading || !inStock}
                        />
                     ) : (
                        <BogoQuantityInput
                           quantity={quantity}
                           setQuantity={(quantity) => {
                              updateCartItem({
                                 cart_item_uid: uid,
                                 quantity,
                              })
                           }}
                           disable={cartState.loading || !inStock}
                        />
                     )}
                  </>
               ) : null}
            </div>
         </div>
         <div className="align-content-between d-flex flex-column flex-wrap justify-content-between price-tag">
            <div
               className="remove-icon text-primary"
               style={{ alignItems: 'center' }}
               onClick={onRemove}
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="var(--font-color-2)"
                  className="w-5 h-5"
                  width={20}
                  height={20}
               >
                  <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
               </svg>
               <span>Remove</span>
            </div>
            <div className="">
               <CartProductPrice price={price.value} />
            </div>
         </div>
      </div>
   )
}
