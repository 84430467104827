/**
 * TODO Document
 */
export const findMatchingVariant = ({
  variants,
  optionSelections,
}) => {
  return variants.find(({ attributes, product }) => {
    
    const customAttributes = (attributes || []).reduce(
      (map, { code, uid }) => {
        map[code] = uid
        return map 
      },
      {}
    );

    for (let code in optionSelections) {
      if (customAttributes[code] != optionSelections[code]) {
        return false;
      }
    }

    // otherwise, every option selection matched
    // and this is the correct variant
    return true;
  });
};
