import React from 'react'

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        d="M1.33203 16.5003C1.33203 15.3511 1.78858 14.2489 2.60124 13.4362C3.41389 12.6235 4.51609 12.167 5.66536 12.167H14.332C15.4813 12.167 16.5835 12.6235 17.3962 13.4362C18.2088 14.2489 18.6654 15.3511 18.6654 16.5003C18.6654 17.075 18.4371 17.6261 18.0308 18.0324C17.6244 18.4387 17.0733 18.667 16.4987 18.667H3.4987C2.92406 18.667 2.37296 18.4387 1.96663 18.0324C1.5603 17.6261 1.33203 17.075 1.33203 16.5003Z"
        stroke="var(--secondary-text-color)"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M10 7.8335C11.7949 7.8335 13.25 6.37842 13.25 4.5835C13.25 2.78857 11.7949 1.3335 10 1.3335C8.20507 1.3335 6.75 2.78857 6.75 4.5835C6.75 6.37842 8.20507 7.8335 10 7.8335Z"
        stroke="var(--secondary-text-color)"
        strokeWidth="2"
      />
    </svg>
  )
}

export default UserIcon
