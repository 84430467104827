import { useAppContext } from '@/core/App/AppContext'
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import useProductContext from '@/theme/route/ProductPage/useProductContext'
import { useState } from 'react'
import { notifyOutofStock } from 'src/graphql/mutations/customermutation'
import Button from '../Button'
import { ButtonLoader } from '../ButtonLoader'
import useCartContext from '../CartWrapper/useCartContext'
import useAuthContext from '../Contexts/AuthContext'

export default function AddToCartButton(props) {
  const [{ mitter }] = useAppContext()
  const [, , { addToCart }] = useCartContext()
  const [, , { isSignedIn }] = useAuthContext()

  const [product, { getCartItem }] = useProductContext()
  const { id, __typename, stock_status } = product
  const [loading, setLoading] = useState(false)

  if (__typename == 'MageWorxGiftCards') {
    return null
  }
  if (stock_status != 'IN_STOCK') {
    return (
      <Button
        className="btn-primary btn-block w-100 btn-lg product-btn"
        onClick={() => {
          isSignedIn().then((signedIn) => {
            if (!signedIn) {
              mitter.emit(
                'TOGGLE_LOGIN_MODEL',
                'Sign in is required for notify stock'
              )
            } else {
              graphqlWebClient
                .request(notifyOutofStock(id))
                .then(({ NotifyOutofStock: { message, status } }) => {
                  if (status) {
                    alert(message)
                  }
                })
                .catch((err) => {
                  // alert('Something went wrong')
                })
            }
          })
        }}
      >
        Notify Me
      </Button>
    )
  }

  return (
    <Button
      onClick={() => {
        const cartItem = getCartItem()
        setLoading(true)
        addToCart(cartItem, product)
          .then((d) => {
            mitter.emit('ShowToaster', { message: `Product Added to cart` })
            if (!sessionStorage.getItem(`${cartItem.sku}-upsell`)) {
              sessionStorage.setItem(`${cartItem.sku}-upsell`, '1')
              props.isUpsellModal && mitter.emit('UpsellModal', cartItem.sku)
            }
          })
          .catch((e) => {
            mitter.emit('ShowToaster', { message: e })
          })
          .finally(() => {
            setLoading(false)
          })
      }}
      className="btn-primary btn-block w-100 btn-lg product-btn"
      variant="primary"
    >
      {loading ? <ButtonLoader /> : <span>{props.buttonText}</span>}
    </Button>
  )
}
