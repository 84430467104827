/* TODO: @subscription
export const loginOTP = (number) => `
 mutation {
    loginOTP(mobilenumber: "${number}", websiteId: 1) {
      ok
      status
      message
    }
  }
`
 */
export const loginOTP = (number) => `
  mutation {
    loginOTP(mobilenumber: "${number}", websiteId: 1) {
      status
      message
    }
  }
`
export const loginOTPVerify = (number, otp) => `{
  loginOTPVerify(mobilenumber: "${number}", otp: "${otp}", websiteId: 1) {
    status
    message
    token
  }
}
`
