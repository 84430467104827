import { useAppContext } from '@/core/App/AppContext'
import Link from '@/core/Link'
import { useEffect, useState } from 'react'
import './header-bottom.scss'
import SearchInputForm from '../../SearchInput/SearchInputForm'
import Sidebar from './Sidebar'
import { useMemo } from 'react'
import { FEAT_SUBSCRPTION_ACTIVE } from 'src/config'

const MobileNav = () => {
  const [tab, setTab] = useState(0)
  const [open, setOpen] = useState(false)
  const [{ mitter }] = useAppContext()
  useEffect(() => {
    function fn() {
      console.log('emitted')
      setOpen(!open)
    }
    mitter.on('TOGGLE_NAV', fn)
    return () => mitter.off('TOGGLE_NAV', fn)
  }, [open])

  const [{ isMobile, menu, blogCategories, storeConfig }] = useAppContext()

  let menuItems = useMemo(() => {
    return menu?.children[0]?.children
  }, [menu])

  return (
    <>
      {/* {JSON.stringify(menu, null, 2)} */}
      {/* {JSON.stringify(menuItems, null, 2)} */}
      <Sidebar
        isVisible={open}
        onToggle={() => setOpen((prev) => !prev)}
        menu={menuItems}
      />
      {/* {open && <div className="backdrop" onClick={() => setOpen(false)}></div>} */}
    </>
  )
}

export default function HeaderBottom(props) {
  const [{ isMobile, menu, blogCategories, storeConfig }] = useAppContext()

  let menuItems = useMemo(() => {
    return menu?.children[0]?.children
  }, [menu])
  if (isMobile) {
    return (
      <div className="header-bottom">
        <SearchInputForm additionalClass="mobile-search-bar" />
        <MobileNav />
      </div>
    )
  }
  return (
    <div className="header-bottom">
      {/* <Menu /> */}

      <ul className="menu d-flex">
        <li className="dropdown">
          <Link href="/shop/concern">
            Shop By Concern{' '}
            <svg
              class="icon"
              width="14"
              height="16"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" />
            </svg>
          </Link>
          <ul className="dropdown-nav">
            <li>
              <a href="">Immunity & Throat Health</a>
            </li>
            <li>
              <a href="">Joint Health</a>
            </li>
            <li>
              <a href="">Personal Health</a>
            </li>
            <li>
              <a href="">Woman Health</a>
            </li>
            <li>
              <a href="">Digestion</a>
            </li>
          </ul>
        </li>
        <li className="dropdown">
          <Link href="/shop/category">
            Shop By Category{' '}
            <svg
              class="icon"
              width="14"
              height="16"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" />
            </svg>
          </Link>
          <ul className="dropdown-nav">
            <li>
              <a href="">Immunity & Throat Health</a>
            </li>
            <li>
              <a href="">Joint Health</a>
            </li>
            <li>
              <a href="">Personal Health</a>
            </li>
            <li>
              <a href="">Woman Health</a>
            </li>
            <li>
              <a href="">Digestion</a>
            </li>
          </ul>
        </li>
        {FEAT_SUBSCRPTION_ACTIVE ? (
          <li>
            <a href="/healthmantra">Vedistry Health Mantra</a>
          </li>
        ) : null}
        <li>
          <Link href="/about-us">About Us </Link>
        </li>
        <li>
          <Link href="/blog">Blog</Link>
        </li>
      </ul>
    </div>
  )
}
