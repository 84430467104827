import Block from '@/theme/components/Block'
import ReviewControl from '@/theme/components/Ratings/ReviewControl'
import Form from '@/theme/components/Form'
import './review-add.scss'
import useGraphQLQuery, {
  useGraphQLMutation,
} from '@/theme/lib/useGraphQLQuery'
import {
  productReviewRatingsMetadata,
  createProductReview,
} from '../graphql/productReviewRatings.gql'
import { gql } from 'graphql-request'
import useProductContext from '@/theme/route/ProductPage/useProductContext'
import { useForm, FormProvider } from 'react-hook-form'
import useAuthContext from '../../Contexts/AuthContext'
import { useAppContext } from '@/core/App/AppContext'

export default function ReviewAdd(props) {
  const [{ data }, request] = useGraphQLQuery(
    gql`{
    ${productReviewRatingsMetadata}
  }`,
    null,
    { immediate: true }
  )

  const [{ loading, error, data: reviewData }, mutate] = useGraphQLMutation(
    gql`
      ${createProductReview}
    `,
    null,
    {}
  )

  const items = data?.productReviewRatingsMetadata?.items || []
  const [{ name, sku }] = useProductContext()
  const [{ isAuthenticated, firstname }] = useAuthContext()
  const [{ mitter }] = useAppContext()
  const methods = useForm()

  if (reviewData) {
    mitter.emit('ShowToaster', {
      message: 'Review Submitted Successfully',
    })
    window.location.reload()
  }
  return (
    <Block title="Write Your Own Review" className="review-add">
      <FormProvider {...methods}>
        <form
          // action='http://magento2.templatemela.com/MAG2013/MAG110247_1/review/product/post/id/17/'
          className="review-form"
          noValidate
          onSubmit={methods.handleSubmit((e) => {
            if (!isAuthenticated) {
              mitter.emit('TOGGLE_LOGIN_MODEL')
            } else {
              e.nickname = firstname
              mutate({ input: e })
            }
          })}
        >
          <fieldset
            className="fieldset review-fieldset mb-4"
            data-hasrequired="* Required Fields"
          >
            <legend className="legend review-legend">
              <span>You're reviewing:</span>
              <strong>{name}</strong>
            </legend>
            <br />
            <span id="input-message-box"></span>
            <fieldset className="field required review-field-ratings">
              <div className="control">
                <div className="nested" id="product-review-table">
                  {items.map((item, i) => (
                    <div className="field choice review-field-rating">
                      <label className="label" id="Value_rating_label">
                        <span>{item.name}</span>
                      </label>
                      <ReviewControl index={i} {...item} />
                    </div>
                  ))}
                </div>
              </div>
            </fieldset>
            <input
              name="sku"
              type="hidden"
              value={sku}
              {...methods.register('sku')}
            />
            <Form.TextField label="Summary" {...methods.register('summary')} />
            {/* <Form.TextField
              className='required'
              label='Nickname'
              {...methods.register('nickname')}
            /> */}
            <Form.TextareaField
              className="required"
              {...methods.register('text')}
              label="Review"
              cols={5}
              rows={3}
            />
          </fieldset>
          <div className="mb-4">
            <button type="submit" className="btn btn-primary">
              <span>Submit Review</span>
            </button>
          </div>
        </form>
      </FormProvider>
    </Block>
  )
}
