import { useAppContext } from '@/core/App/AppContext'
import { AsyncImage } from 'loadable-image'

type IImage = {
   url?: string;
   src?: string;
   label?: string;
   alt?: string;
   width?: string;
   height?: string;
   dimWidth?: string;
   aspectRatio?: string | number | undefined;
   aspectRatioMobile?: string | number | undefined;
   aspectRatioTablet?: string | number | undefined;
   aspectRatioDesktop?: string | number | undefined;
   className?: string;
   onClick?: Function;
   loading?: 'lazy' | 'eager' | string;
}

export const Image = ({
   url = null,
   src = null,
   label = null,
   alt = null,
   width = null,
   className = '',
   onClick = null,
   loading = 'lazy',
   aspectRatio = null,
   aspectRatioMobile = null,
   aspectRatioTablet = null,
   aspectRatioDesktop = null,
   style = null
}: IImage) => {
   const [{ isMobile, mitter }] = useAppContext()
   if (isMobile && aspectRatioMobile) {
      aspectRatio = aspectRatioMobile
   }

   return (
      <div className="Image" style={{ '--aspect-ratio': aspectRatio, '--aspect-ratio-mobile': aspectRatioMobile, '--aspect-ratio-tablet': aspectRatioTablet, ...style }}>
         <AsyncImage
            src={src ?? url}
            alt={alt ?? label}
            onClick={onClick}
            className={`picture ${className}`}
            style={{ width: '100%', height: 'auto', aspectRatio: aspectRatio }}
            loading={loading as 'lazy' | 'eager'}
         />
      </div>
   )

}
