import { useAppContext } from '@/core/App/AppContext'
import React from 'react'
import { FC } from 'react'
import useCartContext from '../CartWrapper/useCartContext'

type CouponCodePill = {
  title: string
  code?: string
  description?: string
  close?: any
  setSuccessModal?: any
}
export const CouponCodePill: FC<CouponCodePill> = ({
  title,
  description,
  code,
  close,
  setSuccessModal,
}) => {
  const [cartState, cartDispatch, { applyCoupon, removeCoupon }] =
    useCartContext()
  const [{ mitter }] = useAppContext()

  const appliedCouponCode =
    cartState?.applied_coupons && cartState?.applied_coupons[0]?.code
  const handleRemoveCoupon = () => {
    // alert('Coupon Code removed');
    removeCoupon().catch((err) => {
      alert(err)
    })
  }

  const handleApplyCoupon = (couponcode) => {
    applyCoupon(couponcode)
      .then((data) => {
        mitter.emit('ShowToaster', {
          message: 'Coupon Code applied',
        })
        setSuccessModal && setSuccessModal(true)
        mitter.emit('NavigationEnd', () => false)
        // close(true);
      })
      .catch((err) => {
        if (err) {
          mitter.emit('ShowToaster', {
            message: 'Coupon Code not valid',
          })
        }
        mitter.emit('NavigationEnd', () => false)
      })
  }
  function toggleCouponcode() {
    if (appliedCouponCode == code) {
      // return null
      console.log('Removing...')
      handleRemoveCoupon()
    } else {
      mitter.emit('NavigationStart', () => true)
      handleApplyCoupon(code)
    }
  }
  let isApplied = false
  if (appliedCouponCode == code) {
    isApplied = true
  }

  return (
    <div className="item--coupon" title={title}>
      <button
        type="button"
        className={`btn rounded-pill d-inline-flex gap-2 align-items-center ${
          isApplied ? 'btn-dark' : 'btn-outline-dark'
        } py-1`}
        onClick={toggleCouponcode}
      >
        <span>{code}</span>
        {isApplied ? (
          <span>
            <svg
              width={16}
              height={16}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        ) : null}
      </button>
    </div>
  )
}
