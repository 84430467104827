import PersistanceStorage from '@/utils/storage';
import { getToken } from '@/utils/auth';

const storage = new PersistanceStorage();
// export const getUserCartId = () => {
//   return storage.getItem('user-cart');
// };
// export const getGuestCartId = () => {
//   return storage.getItem('guest-cart');
// };
export const getCartId = () => {
  // if (getToken()) {
  //   return getUserCartId();
  // }
  // return getGuestCartId();
  return storage.getItem('quoteId');
};
export const setCartId = (quoteId) => {
  // if (getToken()) {
  //   return storage.setItem('user-cart', quoteId);
  // }
  // storage.setItem('guest-cart', quoteId);
  storage.setItem('quoteId', quoteId);
};
export const removeCartId = (guest = false) => {
  // if (getToken() && !guest) {
  //   storage.removeItem('user-cart');
  // }
  // storage.removeItem('guest-cart');
  storage.removeItem('quoteId');
};
