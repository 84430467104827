import { gql } from 'graphql-request';

export const getCoupon = gql`
query {
    getCouponslist{
      rule_title
      rule_description
      coupon_code
    }
  }
`;
export const getCouponsQuery = gql`
query GetCouponslist($visible_on_front: Int) {
    getCouponslist(visible_on_front: $visible_on_front) {
      rule_title
      rule_description
      coupon_code
    }
  }
`;
export const getSpinwheelCouponsQuery = gql`
query  {
    getCouponslist(spinwheel_only: 1) {
      rule_title
      rule_description
      coupon_code
    }
  }
`;
