export default function price_range(maximum_price = false) {
  return `minimum_price {
        final_price {
            currency
            value
        }
        discount {
            amount_off
            percent_off
        }
    }
    ${
      maximum_price
        ? `maximum_price {
        final_price {
            currency
            value
        }
        discount {
            amount_off
            percent_off
        }
    }`
        : ""
    }
    `;
}
