import './block.scss';
import clsx from 'clsx';

export default function Block({
  className = null,
  id = null,
  title,
  children,
}) {
  return (
    <div className={clsx('block', className)} id={id}>
      <div className='block-title'>
        <strong>{title}</strong>
      </div>
      <div className='block-content'>{children}</div>
    </div>
  );
}

// 'review-list'
