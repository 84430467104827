import { graphqlWebClient } from '@/theme/lib/graphqlClient';
import { getToken, removeToken, setToken } from '@/utils/auth';
import { getCartId, removeCartId, setCartId } from '@/utils/cart';
import { gql } from 'graphql-request';
import cart from 'src/graphql/queries/cart';
import { getCustomerWithCart } from './getCustomerWithCart';

export const handle_out_of_stock = (errorString) => {

    const filter_null_items = (items) => items.filter((data) => data != null)

    console.log('errorString', errorString)

    if (errorString?.response?.errors[0].message == 'Some of the products are out of stock.') {
        const {
            customer,
            customerCart,
            addProductsToCart,
            removeItemFromCart,
            updateCartItems,
            addMwGiftCardProductsToCart
        } = errorString.response.data;

        if (customer) {
            customerCart.items = filter_null_items(customerCart.items)
            setCartId(customerCart && customerCart.id);
            return {
                customer: customer,
                cart: customerCart
            }
        }
        if (addProductsToCart || updateCartItems ||  removeItemFromCart || addMwGiftCardProductsToCart) {
            const { cart } = addProductsToCart || updateCartItems || removeItemFromCart || addMwGiftCardProductsToCart
            cart.items = filter_null_items(cart.items)
            return {
                cart: cart
            }
        }
    }
}
const loadOnSession = async ({ full = false, retry = true }) => {
    var token = getToken();
    if (token) {
        const resp = await getCustomerWithCart(full);

        if (resp.err) {
            return handle_out_of_stock(resp?.err)
        }

        if (resp) {
            const { customer, cart } = resp;
            if (customer) {
                setCartId(resp.cart && resp.cart.id);
                return resp
            }
        } else {
            removeToken()
            if (retry) return loadOnSession({ full, retry: false });
            else {
                return {};
            }
        }
    }
    /**
     * If cart id exists in localstorage then fetch cart from db
     * This is guest user
     */
    var cart_id = getCartId()
    if (cart_id) {
        const query_data = await graphqlWebClient
            .request(
                gql`{
                    cart(cart_id:"${cart_id}"){
                    ${cart({
                    applied_coupons: true,
                    filler_products: true,
                    items: true,
                    prices: true,
                    available_payment_methods: full,
                    billing_address: full,
                    selected_payment_method: full,
                    shipping_addresses: full,
                })}
                    }
                }`
            )
            .catch((d) => {
                return d;
            });

        if (!query_data.cart) {
            removeCartId()
            if (retry) return loadOnSession({ full, retry: false });
            else {
                return {};
            }
        } else {
            return query_data
        }
    } else {
        return { customer: null, cart: null };
    }
};

export default loadOnSession;
