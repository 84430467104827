import React from 'react'
import { useState } from 'react'
import useCartContext from '../CartWrapper/useCartContext'
import { Button, CloseButton, Offcanvas } from 'react-bootstrap'
import { CouponCode } from '../CouponCode'
import GQLQueryAsync from '../GQLQueryAsync'
import { getCoupon } from 'src/graphql/queries/getCoupon'
import { CouponCodePill } from '../CouponCode/CouponCodePill'
import { useEffect } from 'react'

export default function CouponForm() {
   const [
      cart,
      dispatch,
      {
         isPending,
         applyCoupon,
         removeCoupon,
         loadingCart,
         isRemoveCouponPending,
      },
   ] = useCartContext()
   const [couponCode, setCouponCode] = useState('')
   const [openList, setOpenList] = useState(false)

   const appliedCoupon =
      cart.applied_coupons?.length > 0 ? cart.applied_coupons[0] : null
   useEffect(() => { }, [])

   return (
      <>
         {/* {JSON.stringify({ appliedCoupon })} */}
         <div className={`coupon-div `}>
            <div className="d-flex justify-content-between align-items-center mb-3">
               <h5 className="mb-0">Apply Coupon</h5>
               <div className={`input-align input-group `}>
                  <input
                     aria-label="coupon code"
                     aria-describedby="basic-addon2"
                     className="form-control"
                     value={couponCode}
                     readonly={appliedCoupon?.code}
                     placeholder={appliedCoupon?.code}
                     onChange={({ target }) => setCouponCode(target.value)}
                  />
                  {appliedCoupon?.code ? (
                     <Button
                        onClick={(event) => {
                           console.log('couponCode', couponCode)
                           removeCoupon(couponCode)
                           setCouponCode('')
                        }}
                        className="btn btn-dark coupon-btn"
                        variant={'dark'}
                        disabled={isRemoveCouponPending}
                     >
                        {isRemoveCouponPending ? 'Removing...' : 'Remove'}
                     </Button>
                  ) : (
                     <Button
                        onClick={(event) => {
                           console.log('couponCode', couponCode)
                           applyCoupon(couponCode, { showAlert: true })
                        }}
                        className="btn btn-primary coupon-btn"
                        disabled={isPending}
                     >
                        {isPending ? 'Applying...' : 'Apply code'}
                     </Button>
                  )}
               </div>
            </div>
            <div className="text-center d-flex align-items-center gap-4">
               <div>Available coupons:</div>
               <GQLQueryAsync
                  query={getCoupon}
                  className="d-flex gap-3 align-items-center"
               >
                  {(response) => (
                     <>
                        {response?.getCouponslist?.map((ccode, codeIndex) => (
                           <CouponCodePill
                              key={codeIndex}
                              title={ccode.title}
                              description={ccode.description}
                              code={ccode.coupon_code}
                           // close={close}
                           // setSuccessModal={setSuccessModal}
                           />
                        ))}
                     </>
                  )}
               </GQLQueryAsync>
            </div>

         </div>
         <Offcanvas show={openList} placement="end" className="cart-popup-sidebar">
            <Offcanvas.Header>
               <Offcanvas.Title className="text-center position-relative">
                  <CloseButton
                     onClick={() => setOpenList((prev) => !prev)}
                     className="closebtn position-absolute start-0"
                  />
                  <span
                     className="offcanvas-title-text"
                     onClick={() => setOpenList((prev) => !prev)}
                  >
                     Available coupons
                  </span>
               </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
               <CouponCode />
            </Offcanvas.Body>
         </Offcanvas>
      </>
   )
}
