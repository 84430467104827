import { gql } from 'graphql-request'
import price_range from './product/price_range'
import product_items from './product/product_items'

export const productQuery = () => {
  return `query getProducts($filter: ProductAttributeFilterInput,
    $pageSize: Int,
    $currentPage: Int,
    $sort: ProductAttributeSortInput
  ){
  products(filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
    total_count
    aggregations{
      attribute_code
      label
      options{
        label
        value
        count
      }
    }
    items {
      ${product_items({
        productExtendQueryBoolean: `  short_description {
          html
        }`,
      })}
    }
  }
}
`
}
export const subscriptionProductsQuery = gql`query getProducts($filter: ProductAttributeFilterInput,
    $pageSize: Int,
    $currentPage: Int,
    $sort: ProductAttributeSortInput
  ){
  products(filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
    items {
      ${product_items({
        productExtendQueryBoolean: `
        excerpt
        short_description {
          html
        }`,
      })}
    }
  }
}
`

export const consolidatedReview = ({ sku }) => `
{
  consolidatedReview(sku:"MOHFW"){
    consolidated_review
    review_count
    starwise_rating{
      five_star
      four_star
      three_star
      two_star
      one_star
    }
  }
}
`
export const customerpurchased = `{
  customerpurchased
  {
    sku
    name
    url_key
    image
  }
}
`

export const sliderProduct = (key) => `${key}{
  __typename
  bogo
  id
  name
  url_key
  sku
  image {
    label
    url
  }
  rating_summary
  pack_size
  stock_status
  special_price
  price {
    regularPrice {
      amount {
        value
      }
    }
    minimalPrice {
      amount {
        value
      }
    }
    maximalPrice {
      amount {
        value
      }
    }
  }
  price_range {
    ${price_range(true)}
  }
  ... on ConfigurableProduct {
    configurable_options {
      position
      attribute_code
      use_default
      attribute_uid
      label
      values {
        use_default_value
        swatch_data {
          value
        }
        label
        uid
      }
    }
    variants {
      product {
        sku
        name
        stock_status
        price_range {
          ${price_range()}
        }
      }
      attributes {
        code
        uid
        value_index
        label
      }
    }
  }
}`

// ${sliderProduct('upsell_products')}
export const productExtendQuery = `
  stock_status
  review_count
  description{
    html
  }
  key_ingredients
  bogo
  short_description{
    html
  }
  ${sliderProduct('related_products')}
  ${sliderProduct('fbt_products')}
  media_gallery{
    label
    url
    disabled
  }
`

export const getProductFaq = (id) => `{
  getProductFaq(product_id:"${id}")
    {
      question
      answer
    }
  }
`
export const createProductFaq = ({ nickname, question, email, ...rest }) => {
  return `
  mutation{
      createProductFaq(
        product_id:${rest.id}
        nickname:"${nickname}"
        question:"${question}"
        email:"${email}"
      ){
        status
      }
    }
  `
}
export const productSearch = (search) => `{
  products(search: "${search}") {
    items {
      name
      url_key
      image {
        url
        label
        disabled
      }
    }
  }
}`

export const fillerProduct = `
  filler_products{
    items{
      sku
      name
    }
  }
`
