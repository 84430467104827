import categoriesIcon from '@/theme/icons/categories'
import { FC } from 'react'
import { Accordion, Nav, Offcanvas } from 'react-bootstrap'
import { MinusIcon } from 'src/common/svgs'
import Link from '@/core/Link'
import { useAppContext } from '@/core/App/AppContext'
import useNavigate from '@/core/Router/useNavigate'
import { FEAT_SUBSCRPTION_ACTIVE } from 'src/config'

const visibleOnlyItems = (items) =>
  items?.filter((mi) => mi.include_in_menu == 1)

const getMenuIcon = (menu) => {
  let path = menu.url_path.split('/')
  let urlKey = path[path.length - 1]
  if (categoriesIcon[urlKey]) {
    return categoriesIcon[urlKey]
  }
  return null
}

const Sidebar: FC = ({ isVisible = true, onToggle, menu }) => {
  const visibleMenus = visibleOnlyItems(menu)
  const [{ mitter }] = useAppContext()
  const navigate = useNavigate()
  const redirect = (path) => {
    mitter.emit('TOGGLE_NAV')
    navigate(path)
  }

  return (
    <>
      {/* {JSON.stringify(menu)} */}
      <Offcanvas show={isVisible} onHide={onToggle} className=" shop-sub-menu">
        {/* {JSON.stringify(menu, null, 2)} */}
        <Offcanvas.Body>
          <Nav.Link href={void 0} className="shop-menu" onClick={onToggle}>
            <MinusIcon /> Shop
          </Nav.Link>
          <Accordion flush>
            {visibleMenus?.map((menuitem, miindex) => (
              <Accordion.Item eventKey={miindex} className="ignore-border">
                <Accordion.Header>{menuitem.name}</Accordion.Header>
                <Accordion.Body>
                  {visibleOnlyItems(menuitem.children)?.map(
                    (submenu, submenuindex) => (
                      <a
                        // href={submenu.url_path}
                        onClick={() => {
                          redirect('/' + submenu.url_path)
                        }}
                        key={`si${submenuindex}`}
                        className="nav-link"
                      >
                        <span>{getMenuIcon(submenu)}</span>
                        <span>{submenu.name}</span>
                      </a>
                    )
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <a className="nav-link" onClick={() => redirect('/about-us')}>
            About us
          </a>
          <a className="nav-link" onClick={() => redirect('/why-ayurveda')}>
            Why Ayurveda
          </a>
          <a className="nav-link" onClick={() => redirect('/blog')}>
            Blog
          </a>
          <a className="nav-link" onClick={() => redirect('/tv')}>
            Vedistry TV
          </a>
          <a className="nav-link" onClick={() => redirect('/contact-us')}>
            Contact Us
          </a>
          {FEAT_SUBSCRPTION_ACTIVE ? (
            <a className="nav-link fw-bold text--accent" href="/healthmantra">
              Vedistry Health Mantra
            </a>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default Sidebar
