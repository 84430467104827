import { useRouterContext } from '@/core/Router/RouterContext'
import { Helmet } from 'react-helmet'

interface IHtmHead {
  baseUrl: string
  default_title: string
  default_description: string
  default_robots?: string
  ogMeta?: {
    title: string
    description: string
    url: string
    img?: string
  }
}
export function HtmlHead(props: IHtmHead) {
  const { default_title, default_description, baseUrl, default_robots } = props
  const {
    routeData: { jsonLd, meta_title, meta_description, canonical, robots, featuredImage },
  } = useRouterContext()
  const canonicalUrl = baseUrl + canonical
  const final_title = meta_title || default_title
  const final_description = meta_description || default_description
  const final_robots = robots || default_robots

  const ogMeta = {
    ...props.ogMeta,
    title: final_title,
    description: final_description,
    url: canonicalUrl,
    img: featuredImage,
  }

  return (
    <Helmet title={final_title}>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {final_description && (
        <meta name="description" content={final_description} />
      )}
      {ogMeta.title && <meta property="og:title" content={ogMeta.title} />}
      {ogMeta.description && (
        <meta property="og:description" content={ogMeta.description} />
      )}
      {ogMeta.url && <meta property="og:url" content={ogMeta.url} />}
      {ogMeta.img && <meta property="og:image" content={ogMeta.img} />}
      {jsonLd && (
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      )}
      {final_robots && <meta name="robots" content={final_robots} />}
    </Helmet>
  )
}
