import './rating-result.scss'
export default function RatingResult({ className = null, value = 0 }) {
  return (
    <>
      {/* <RatingsStar size="sm" value={value * 100} /> */}
      <div className="ratings">
        <div className="empty-stars"></div>
        <div
          className="full-stars"
          style={{
            width: `calc(${value * 100}% - 5px)`,
          }}
        ></div>
      </div>
    </>
  )
}
