export const productReviewRatingsMetadata = `productReviewRatingsMetadata {
    items {
      id
      name
      values {
        value_id
        value
      }
    }
}`

export const createProductReview = `
mutation ( $input: CreateProductReviewInput!) {
  createProductReview( input: $input) {
    review {
      nickname
      summary
      text
      average_rating
      ratings_breakdown {
        name
        value
      }
    }
  }
}`


export const getProductReviews = () => `
query getProducts(
  $filter: ProductAttributeFilterInput
  $pageSize: Int
  $currentPage: Int
) {
  products( filter: $filter ) {
    items {
      reviews(pageSize: $pageSize currentPage: $currentPage)	{
        page_info {
          current_page
          page_size
          total_pages
        }
        items {
          average_rating
          created_at
          nickname
          summary
          text
        }
      }
    }
  }
}`