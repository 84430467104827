import { useEffect } from 'react'
import ErrorBoundary from '@/core/ErrorBoundary'
import AppContextProvider from '@/core/App/AppContext'
import loadable from '@loadable/component'
import Router from '@/core/Router'
import '@/theme/styles/style.scss'
import '@/theme/styles/style-v2.scss'
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import globalStyles from '@/theme/styles/index.module.scss'
import { HistoryObject, INavigationSource } from '@/core/Router/Router.Types'
import { MittEmitter } from '@/utils/mitt'
import Contexts from '@/theme/components/Contexts'
import { useLazyScript } from 'src/hooks/useLazyScript'

const ErrorLoadable = loadable(() => import('@/theme/components/ErrorPage'))

interface AppState {
   isLoading: boolean
}
interface AppProps /* extends RouteComponentProps */ {
   staticContext?: any
   data: { props: any }
   appData: { menu: any; currencyList?: any; storeConfig: any }
   mitter?: MittEmitter
   source?: INavigationSource
}
export default function App(props: AppProps) {
   useEffect(() => {
      performance.mark('app_mounted')
   }, [])

   useLazyScript('https://www.searchanise.com/widgets/v1.0/init.js')

   return (
      <AppContextProvider location={props.source?.location} {...props.appData} mitter={props.mitter}>
         <ErrorBoundary>
            {Contexts(
               <Router
                  source={props.source}
                  routeData={props.data}
                  mitter={props.mitter}
                  storeConfig={props.appData.storeConfig}
               ></Router>
            )}
         </ErrorBoundary>
      </AppContextProvider>
   )
}
App.globalStyles = globalStyles
const Component404 = (props) => {
   const { staticContext } = props
   if (staticContext) staticContext.status = 404
   return <ErrorLoadable />
}
