export const checkFreeShipping = (extentionAttr, prices) => {
  if (
    extentionAttr &&
    prices &&
    extentionAttr.cart_shipping_method == 'false'
  ) {
    return prices.grand_total.value + 0 // 49 add if cart total is below 499
  } else {
    return prices && prices.grand_total.value
  }
}
