import { hydrate } from "react-dom";
import App from "@/core/App";
import { loadableReady } from "@loadable/component";

// if (process && !process.release) {
//   i18n.use(Backend).use(initReactI18next).use(LanguageDetector);
// }
declare var importScripts;
const ENVIRONMENT_IS_WORKER = typeof importScripts === "function";
import mitt from "@/utils/mitt";
import { useEffect } from "react";
const mitter = mitt();
window["mitter"] = mitter;

declare var process: any;
var process: any = {
  env: { FORCE_REDUCED_MOTION: false },
};

window['dataLayer'] = window['dataLayer'] || [];

performance.mark("hydrate_start");

const MainApp = () => {
  // useSSR(data.initialI18nStore, data.initialLanguage);
  useEffect(() => {
    (async () => {
      if ("serviceWorker" in navigator) {
        const { Workbox } = await import("workbox-window");
        const wb = new Workbox("/sw.js");
        window.workbox = wb;
        const reg = await wb.register();
      }
    })()
      .then((e) => {
        console.log(e);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <App
      appData={window["__APP_DATA__"]}
      data={window["__INITIAL_DATA__"]}
      mitter={mitter}
      source={window as any}      
    />
  );
};
loadableReady(() => {
  const root = document.getElementById("root");
  hydrate(<MainApp />, root, () => {
    performance.mark("hydrate_end");
  });
});