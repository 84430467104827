import { FEAT_SUBSCRPTION_ACTIVE } from 'src/config'
import price_range from './product/price_range'

let subscriptionQuery = null
if (FEAT_SUBSCRPTION_ACTIVE) {
  subscriptionQuery = `subscription {
    plan_id
    plan_name
    status
    start_at
    end_at
    consultation_booked_count
    consultation_available_count
    consultation_limit
    consultation_used
  }`
}

const glowPoints = `
mstRewardPointsBalance {
  balance
  spent
  earned
  transactions{
    amount
    comment
    transaction_id
    created_at
    amount
    amount_used
    activated_at
    code
    comment
    is_activated
    is_expiration_email_sent
    is_expired
    expires_at
  }
}
`
const whistList = `wishlist {
  id
  items_v2 {
    items {
      product {
        pack_size
        sku
        bogo
        __typename
        rating_summary
        url_key
        name
        image {
          disabled
          label
          position
          url
        }
        price_range {
          ${price_range(true)}
        }
      }
    }
  }
}`

export const customer = `
  customer {
    ${glowPoints}
    giftcards{
      card_amount
      card_balance
      card_code
      card_status
    }
    id
    share_and_save_url
    lastname
    firstname
    email
    date_of_birth
    anniversary
    mobilenumber
    webengage_whatsapp_optin
    addresses {
      id
      firstname
      lastname
      street
      postcode
      telephone
      region{
        region
        region_id
        region_code
      }
      city
    }
    ${subscriptionQuery}
  }
`

export const userQuery = customer

export const FBCheckCustomer = (access_token) => `
{
  custStatus(
  token:"${access_token}"
  type:"fb"
  )
  {
    status
    token
  }
}
`
export const GmailCheckCustomer = (access_token) => `
{
  custStatus(
  token:"${access_token}"
  type:"g"
  )
  {
    status
    token
  }
}
`

export const FBPostLogin = ({
  access_token,
  mobilenumber,
  otp,
  type = 'fb',
}) => `
{
  postSocialLogin(
  token:"${access_token}"
  type:"${type}"
  mobilenumber:"${mobilenumber}"
  otp:"${otp}"
  )
  {
   status
    token
  }
}


`
