import './header.scss'
import React, { useState, useEffect, useRef } from 'react'
import { CloseButton, Navbar, Offcanvas, ProgressBar } from 'react-bootstrap'
import CartWrapper from '@/theme/components/CartWrapper'
import HeaderBottom from '@/theme/components/Header/HeaderBottom'
import HeaderTop from '@/theme/components/Header/HeaderTop'
import { useAppContext } from '@/core/App/AppContext'
import { WhatsappIcon } from '@/theme/components/WhatsappIcon'
import useScroll from 'src/hooks/useScroll'
import CartItem from '../Cart/CartItem'
import ClubMemberCart from '../Cart/ClubMemberCart'
import CouponForm from '../Cart/CouponForm'
import { Button } from 'react-bootstrap'
import Cart from '../Cart'
import QuoteBreakdown from '../Cart/QuoteBreakdown'
import Link from '@/core/Link'
import useNavigate from '@/core/Router/useNavigate'
import CartProgressBar from '@/theme/route/CartPage/CartProgressBar'
import { cartViewEvent } from 'src/services/gtm'
import we from 'src/services/webengage'
import useCartContext from '../CartWrapper/useCartContext'

export const OfferText = () => {
  const [{ content: offer_text }] = useAppContext()
  if (!offer_text) {
    return null
  }
  return (
    <div className={`topbar notification w-100  d-flex align-items-center`}>
      <div className="marquee-tick d-flex align-items-center">
        <div className="track d-flex">
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: offer_text.content }}
          ></div>
        </div>
      </div>
    </div>
  )
}
export const OfferTextTemp = ({ data }) => {
  // const [{ content_temp: offer_text_temp }] = useAppContext()
  if (!data) return null
  return (
    <>
      <div className="secondary-offer-text">
        {/* {JSON.stringify(data)} */}
        <div dangerouslySetInnerHTML={{ __html: data.title }}></div>
      </div>
    </>
  )
}

const Header = (props) => {
  const [{ mitter, pageBlocks }] = useAppContext()
  const [cartState] = useCartContext()
  const app = useAppContext()
  const headerEl = useRef(null)
  const { scrollY, scrollX, scrollDirection } = useScroll()
  const [showCart, setShowCart] = useState(false)
  const toggleCartSidebar = () => setShowCart((prev) => !prev)
  const navigate = useNavigate()
  const proceedToCheckout = () => {
    navigate('/checkout')
    setShowCart(false)
  }

  useEffect(() => {
    // if (scrollY > 30) {
    //    headerEl.current.classList.add('is-fixed')
    // } else {
    //    headerEl.current.classList.remove('is-fixed')
    // }
    if (scrollDirection == 'up') {
      headerEl.current.classList.add('is-fixed')
    } else {
      headerEl.current.classList.remove('is-fixed')
    }
  }, [scrollDirection])

  useEffect(() => {
    let headerHeight: any = window?.getComputedStyle(headerEl.current).height
    headerHeight = parseInt(headerHeight)
    headerHeight = headerHeight + 51
    headerHeight = headerHeight + 'px'
    document.body.setAttribute('style', `--header-height:${headerHeight}`)
  }, [])
  useEffect(() => {
    if (showCart) {
      // Send Cart Viewed event
      cartViewEvent(cartState)
      we.event('Cart Viewed', {
        'Product Details': cartState?.items,
        'Product Name': `${cartState.items.map((i) => i.product.name)}`,
        'Total Amount': cartState?.prices?.grand_total?.value,
        'No Of Products': cartState?.total_quantity,
      })
    }
  }, [showCart, cartState])
  const onClick = (e) => {
    mitter.emit('TOGGLE_NAV', e)
  }
  return (
    <>
      <Navbar bg="light" expand="lg" sticky="top" className="pt-0">
        <header className="header-content bg-dark" ref={headerEl}>
          <div className="header-offers-container">
            <OfferText />
            {pageBlocks?.length >= 3 && pageBlocks[2] ? (
              <OfferTextTemp data={pageBlocks[2]} />
            ) : null}
          </div>
          <HeaderTop onCartToggle={toggleCartSidebar} />
          {/* <CartWrapper /> */}
          <HeaderBottom />
          <WhatsappIcon />
        </header>
      </Navbar>
      <Offcanvas show={showCart} placement="end" className="cart-popup-sidebar">
        <Offcanvas.Header>
          <span
            className="offcanvas-title-text position-absolute start-0 p-2"
            onClick={toggleCartSidebar}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6 pointer closebtn "
              width={28}
              height={28}
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>

            {/* <CloseButton className="closebtn position-absolute start-0" /> */}
          </span>
          <Offcanvas.Title className="text-center position-relative small">
            <CartProgressBar />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Cart />
          <div className="fixed-align px-10 pb-3 border-top p-2">
            <QuoteBreakdown
              renderHeader={() => <CouponForm />}
              renderFooter={() => (
                <div className="d-flex flex flex-column gap-3 mt-4">
                  <Button
                    onClick={proceedToCheckout}
                    type="button"
                    size="lg"
                    variant="primary"
                    className="w-100"
                  >
                    Proceed to Checkout
                  </Button>
                  <Button
                    onClick={() => {
                      mitter.emit('NavigationStart', () => true)
                      navigate('/')
                      toggleCartSidebar()
                    }}
                    type="button"
                    variant="dark"
                    size="lg"
                    className="w-100"
                  >
                    Continue Shopping
                  </Button>
                </div>
              )}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default Header
