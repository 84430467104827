import React, { useEffect, useRef, useState } from 'react'
import MobileSearchIcon from 'src/theme/icons/MobileSearchIcon'
import { Portal } from 'react-portal'
import './SearchInput.scss'
import { Search } from 'react-bootstrap-icons'
import Link from '@/core/Link'
import { currencyFormat } from '../ProductTile'
import { useNavigate } from 'react-router'
import SearchIcon from '@/icons/SearchIcon'
import { ListGroup, Modal } from 'react-bootstrap'
import { useAppContext } from '@/core/App/AppContext'
import { Image } from '../Image'

const url = new URL('https://searchanise.com/')
url.pathname = '/getwidgets'
url.searchParams.set('api_key', process.env.APP_SEARACHANISE_API_KEY)
url.searchParams.set('restrictBy[status]', '1')
url.searchParams.set('restrictBy[visibility]', '3|4')
url.searchParams.set('maxResults', '3')
url.searchParams.set('startIndex', '0')
url.searchParams.set('items', 'true')
url.searchParams.set('pages', 'true')
url.searchParams.set('facets', 'false')
url.searchParams.set('categories', 'true')
url.searchParams.set('suggestions', 'true')
url.searchParams.set('vendors', 'false')
url.searchParams.set('tags', 'false')
url.searchParams.set('pageStartIndex', '0')
url.searchParams.set('pagesMaxResults', '3')
url.searchParams.set('categoryStartIndex', '0')
url.searchParams.set('categoriesMaxResults', '3')
url.searchParams.set('suggestionsMaxResults', '4')
url.searchParams.set('union[price][min]', 'se_price_32000|se_price_0')
url.searchParams.set('recentlyViewedProducts', 'true')
url.searchParams.set('recentlyAddedToCartProducts', 'true')
url.searchParams.set('recentlyPurchasedProducts', 'true')
url.searchParams.set('vendorsMaxResults', '3')
url.searchParams.set('tagsMaxResults', '3')

const SearchResultItem = ({
  title,
  description = null,
  link = null,
  price = null,
  image = null,
  type = 'item',
  onSelect,
}) => {
  const [{ mitter }] = useAppContext()
  // const navigate = useNavigate()
  const onNavClik = (path) => {
    mitter.emit('REDIRECTING', path)
    // navigate(path)
    // REDIRECTING
  }
  if (type == 'category') {
    return (
      <ListGroup.Item
        action
        className="list-item--category"
        // as={Link}
        href={link}
        onClick={() => onNavClik(link)}
      >
        <div className="item-link">
          <span>{title}</span>
        </div>
      </ListGroup.Item>
    )
  }
  return (
    <ListGroup.Item
      action
      className="list-item--product"
      onClick={() => onNavClik(link)}
    >
      <div className="item-link">
        <Image src={image} aspectRatio={'1/1'} />
        <div>
          <div className="item-title fw-bold">{title}</div>
          <div className="item-description small">{description}</div>
          <div>{currencyFormat(price)}</div>
        </div>
      </div>
    </ListGroup.Item>
  )
}

const SearchResults = ({ data, onSelect }) => {
  return (
    <dialog open className="SearchResults">
      <div className="SearchResults__content">
        {data?.categories?.length ? (
          <div className="SearchResults__content-group">
            <h4>Categories</h4>
            {data?.categories?.map((cat, catIndex) => (
              <SearchResultItem
                key={`cat${catIndex}`}
                title={cat.title}
                type="category"
                link={cat.link}
                description={cat.description}
                onSelect={onSelect}
              />
            ))}
          </div>
        ) : null}
        {data?.items?.length ? (
          <div className="SearchResults__content-group">
            <h4>Products</h4>
            {data?.items?.map((cat, catIndex) => (
              <SearchResultItem
                key={`cat${catIndex}`}
                title={cat.title}
                link={cat.link}
                price={cat.price}
                description={cat.description}
                image={cat.image_link}
                onSelect={onSelect}
              />
            ))}
          </div>
        ) : null}
        {/* {JSON.stringify(data, null, 2)} */}
      </div>
    </dialog>
  )
}
const ResultList = ({ data, onSelect }) => {
  return (
    <>
      {data?.categories?.length ? (
        <ListGroup variant="flush">
          <ListGroup.Item>
            <h4>Categories</h4>
          </ListGroup.Item>
          {data?.categories?.map((cat, catIndex) => (
            <SearchResultItem
              key={`cat${catIndex}`}
              title={cat.title}
              type="category"
              link={cat.link}
              description={cat.description}
              onSelect={onSelect}
            />
          ))}
        </ListGroup>
      ) : null}
      {data?.items?.length ? (
        <ListGroup variant="flush">
          <ListGroup.Item>
            <h4>Products</h4>
          </ListGroup.Item>
          {data?.items?.map((cat, catIndex) => (
            <SearchResultItem
              key={`cat${catIndex}`}
              title={cat.title}
              link={cat.link}
              price={cat.price}
              description={cat.description}
              image={cat.image_link}
              onSelect={onSelect}
            />
          ))}
        </ListGroup>
      ) : null}
    </>
  )
}

const useSearchQuery = () => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  //   const req = new Request(url)
  function searchAsync(search) {
    url.searchParams.set('q', search)
    setIsLoading(true)
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setData(response)
      })
      .catch(console.warn)
      .finally(() => {
        setIsLoading(false)
      })
  }
  return {
    data,
    searchAsync,
    isLoading,
  }
}

const SearchInputForm = ({ additionalClass = '' }) => {
  const searchBarFormRef = useRef(null)
  const searchInputRef = useRef(null)
  const searchResultsDialog = useRef(null)
  const [isOpened, setIsOpened] = useState(false)
  const [{ isMobile, mitter }] = useAppContext()

  const { data, searchAsync, isLoading } = useSearchQuery()
  const onChange = ({ target: { value } }: any) => {
    if (value.trim() === '') {
      searchResultsDialog.current &&
        searchResultsDialog.current.style.setProperty('display', 'none')
    } else {
      searchAsync(value.trim())
      searchResultsDialog.current &&
        searchResultsDialog.current.style.setProperty('display', 'unset')
    }
  }
  // const nav = useNavigate()
  const onSelect = (link: string) => {
    location.replace(link)
    // nav.navigate(link)
  }

  useEffect(() => {
    function closeModal(val) {
      location.replace(val)
      // nav(val)
      setIsOpened(false)
    }
    mitter.on('REDIRECTING', closeModal)
    return () => {
      mitter.off('REDIRECTING', closeModal)
    }
  }, [])

  if (!isMobile) {
    return (
      <>
        <div
          className="cursor-pointer"
          onClick={() => setIsOpened((prev) => !prev)}
        >
          <SearchIcon />
        </div>
        <Modal
          show={isOpened}
          onHide={() => setIsOpened(false)}
          className="search-modal"
        >
          <Modal.Header closeButton>
            <div className="input-group mt-2 mb-2 border header-search">
              <div className="input-group-prepend border-0">
                <button
                  id="button-addon4"
                  type="button"
                  className="btn btn-link text-info text-dark d-flex align-items-center"
                >
                  <Search />
                </button>
              </div>
              <input
                type="search"
                placeholder="What are you looking for today?"
                aria-describedby="button-addon4"
                className="search-input form-control bg-white border-0"
                onChange={onChange}
                ref={searchInputRef}
              />
            </div>
          </Modal.Header>
          <Modal.Body className="px-0">
            {data ? (
              <div ref={searchResultsDialog}>
                <ResultList data={data} onSelect={onSelect} />
              </div>
            ) : null}
          </Modal.Body>
        </Modal>
      </>
    )
  }
  return (
    <>
      <div className={`search-block ${additionalClass}`} id="search">
        <div
          className="container"
          ref={searchBarFormRef}
          id="search-bar-form"
          onClick={() => {
            searchInputRef.current.focus()
          }}
          onSubmit={(ev) => {
            ev.preventDefault()
            ev.stopPropagation()
          }}
        >
          <div className="input-group mt-2 mb-2 border header-search">
            <div className="input-group-prepend border-0">
              <button
                id="button-addon4"
                type="button"
                className="btn btn-link text-info text-dark d-flex align-items-center"
              >
                <Search />
              </button>
            </div>
            <input
              type="search"
              placeholder="What are you looking for today?"
              aria-describedby="button-addon4"
              className="search-input form-control bg-white border-0"
              onChange={onChange}
              ref={searchInputRef}
            />
          </div>
        </div>
      </div>
      <>
        {data ? (
          <div ref={searchResultsDialog}>
            <ResultList data={data} onSelect={onSelect} />
          </div>
        ) : null}
      </>
    </>
  )
}

export default SearchInputForm
