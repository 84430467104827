declare var fbq

const userData = (cart) => {
  if (cart.billing_address) {
    const address = cart.billing_address
    return {
      em: cart.email,
      fn: address.firstname,
      ln: address.lastname,
      external_id: null,
      ct: address.city,
      zp: address.postcode,
      country: address.country.code,
      st: address.region.label,
      ph: address.telephone,
      cn: address.country.code,
    }
  } else {
    return 'guest_user'
  }
}
const contents = (items) => {
  return items.map((data) => {
    return {
      id: data.product.uid,
      quantity: data.quantity,
    }
  })
}

const content_ids = (items) =>
  items.map((data) => {
    if (data.product) {
      return data.product.uid
    } else {
      return data.uid
    }
  })

const content_name = (items) =>
  items.map((data) => {
    if (data.product) {
      return data.product.name
    } else {
      return data.name
    }
  })

const value = (cart) => cart.prices.grand_total.value

const num_items = (cart) =>
  cart.items.reduce((acc, init) => init.quantity + acc, 0) || 0

const sendFBEvent = (...args) => {
  if (window['fbq']) {
    window['fbq'](...args)
  }
}

export const fBAddToCart = (cart, cartState) => {
  try {
    const data = {
      content_ids: content_ids(cart.items),
      content_name: content_name(cart.items),
      contents: contents(cart.items),
      value: value(cart),
      currency: 'INR',
      content_type: 'product',
      user_data: userData(cartState),
    }

    sendFBEvent('track', 'AddToCart', data)
  } catch (err) {
    console.log('fBAddToCart', err)
  }
}
export const fBInitiateCheckout = (cart) => {
  try {
    const data = {
      num_items: num_items(cart),
      content_ids: content_ids(cart.items),
      content_name: content_name(cart.items),
      contents: contents(cart.items),
      value: value(cart),
      currency: 'INR',
      content_type: 'product',
      user_data: userData(cart),
    }

    sendFBEvent('track', 'InitiateCheckout', data)
  } catch (err) {
    console.log('fBInitiateCheckout', err)
  }
}
export const fBViewCategory = (products, cart) => {
  try {
    const data = {
      content_ids: content_ids(products.items),
      content_name: content_name(products.items),
      contents: null,
      content_type: 'category',
      user_data: userData(cart),
    }

    sendFBEvent('track', 'ViewCategory', data)
  } catch (err) {
    console.log('fBViewCategory', err)
  }
}
export const fBViewContent = (product, cart) => {
  try {
    const data = {
      content_ids: product.uid,
      content_name: product.name,
      contents: null,
      content_type: 'product',
      user_data: userData(cart),
      value: product.price || product.price_range?.minimum_price.final_price.value,
      currency: 'INR',
      content_category: null,
    }

    sendFBEvent('track', 'ViewContent', data)
  } catch (err) {
    console.log('fBViewContent', err)
  }
}
export const fBPurchase = (cart) => {
  try {
    const data = {
      content_ids: content_ids(cart.items),
      content_name: content_name(cart.items),
      contents: contents(cart.items),
      content_type: 'product',
      value: value(cart),
      currency: 'INR',
      user_data: userData(cart),
    }

    sendFBEvent('track', 'Purchase', data)
  } catch (err) {
    console.log('fBPurchase', err)
  }
}
