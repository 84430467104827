import './review-control.scss';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
export default function ReviewControl({ className = null, value = 0, id, index, values }) {
  const name = `ratings[${index}].id`;
  const cName = `ratings[${index}].value_id`;
  const idPrefix = `id_${index}`;
  const { register } = useFormContext();
  return (
    <div className='control review-control'>
      <input name={name} type='hidden' value={id} {...register(name)} />
      {
        values.map((val, i) =>
        (<>
          <input
            type='radio'
            {...register(cName)}
            id={`${idPrefix}_${val.value}`}
            value={val.value_id}
            className='radio'
          />
          <label
            className={`rating-${val.value}`}
            htmlFor={`${idPrefix}_${val.value}`}
            title={`${val.value} star`}
            id={`${idPrefix}_${val.value}_label`}
          >
            <span>{val.value} star</span>
          </label>
        </>)
        )
      }
    </div>
  );
}
