import {
  useState,
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  Ref,
  useMemo,
} from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import OtpInput from 'react-otp-input'

import './form.scss'
let fieldId = 0
const ifNull = (...arr) => arr.filter((a) => !!a).join(' ')

interface FieldProps {
  label?: string
  className?: string
  htmlFor?: string
  id?: string
  // invalid?:boolean;
  error?: string
  control?: any
}

const Field: FC<FieldProps> = (props) => {
  const { label, htmlFor, className, children, error } = props

  return (
    <div className={ifNull('form-group', className)}>
      <label htmlFor={htmlFor} className="form-label form-control-label">
        <span>{label}</span>
      </label>
      {children}
      {error && <span>{error}</span>}
    </div>
  )
}

type TextFieldProps = FieldProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    ref?: Ref<HTMLInputElement>
  }

const TextField: FC<TextFieldProps> = forwardRef<
  HTMLInputElement,
  TextFieldProps
>((props, ref) => {
  const { label, className, htmlFor, error, type = 'text', ...rest } = props
  const [id] = useState(() => {
    if (props.id) return props.id
    return ++fieldId
  })
  return (
    <Field
      label={label}
      id={`field-${id}`}
      htmlFor={`input-${id}`}
      className={className}
    >
      <div className="control">
        <input
          {...rest}
          className={ifNull('form-control', error && 'mage-error')}
          id={`input-${id}`}
          type={type}
          ref={ref}
        />
      </div>
      {error && <div className="mage-error">{error}</div>}
    </Field>
  )
})

const OtpField: FC<TextFieldProps> = forwardRef<
  HTMLInputElement,
  TextFieldProps
>((props, ref) => {
  const {
    control,
    label,
    className,
    htmlFor,
    error,
    type = 'number',
    ...rest
  } = props
  const [id] = useState(() => {
    if (props.id) return props.id
    return ++fieldId
  })
  return (
    <Field
      label={label}
      id={`field-${id}`}
      htmlFor={`input-${id}`}
      className={className}
    >
      <div className="control">
        <Controller
          control={control}
          name={rest.name}
          render={({ field: { value, onChange } }) => (
            <OtpInput
              numInputs={6}
              value={value}
              onChange={onChange}
              renderSeparator={null}
              renderInput={(props) => (
                <input
                  {...rest}
                  id={`input-${id}`}
                  type={type}
                  {...props}
                  className={ifNull('form-control', error && 'mage-error')}
                />
              )}
            />
          )}
        />
      </div>
      {error && <div className="mage-error">{error}</div>}
    </Field>
  )
})

type TextareaFieldProps = FieldProps &
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > & {
    ref?: Ref<HTMLTextAreaElement>
  }

const TextareaField: FC<TextareaFieldProps> = forwardRef<
  HTMLTextAreaElement,
  TextareaFieldProps
>((props, ref) => {
  const { label, className, htmlFor, error, ...rest } = props
  const [id] = useState(() => {
    if (props.id) return props.id
    return ++fieldId
  })
  return (
    <Field
      label={label}
      id={`field-${id}`}
      htmlFor={`input-${id}`}
      className={className}
    >
      <div className="control">
        <textarea
          {...rest}
          className={ifNull('form-control textarea', error && 'mage-error')}
          ref={ref}
          id={`input-${id}`}
        />
      </div>
      {error && <div className="mage-error">{error}</div>}
    </Field>
  )
})

type SelectProps = FieldProps &
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > & {
    ref?: Ref<HTMLSelectElement>
  }
const SelectField: FC<SelectProps> = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const { label, className, htmlFor, children, ...rest } = props
    const [id] = useState(() => {
      if (props.id) return props.id
      return ++fieldId
    })
    return (
      <Field
        label={label}
        id={`field-${id}`}
        htmlFor={`input-${id}`}
        className={className}
      >
        <div className="control custom-selectbox">
          <select {...rest} id={`input-${id}`}>
            {children}
          </select>
        </div>
      </Field>
    )
  }
)

const RadioField = (props) => {
  const { label, className, htmlFor, swatch = false, ...rest } = props

  const [id] = useState(() => {
    if (props.id) return props.id
    return ++fieldId
  })

  return (
    <div className={ifNull('field choice', className, swatch && 'swatch')}>
      <input type="radio" className="radio" {...rest} id={`input-${id}`} />
      <label className="label" htmlFor={`input-${id}`}>
        {label}
      </label>
    </div>
  )
}

const CheckboxField = (props) => {
  const { label, className, htmlFor, swatch = false, ...rest } = props

  const [id] = useState(() => {
    if (props.id) return props.id
    return ++fieldId
  })

  return (
    <div className={ifNull('field choice', className, swatch && 'swatch')}>
      <input
        type="checkbox"
        className="checkbox"
        {...rest}
        id={`input-${id}`}
      />
      <label className="label" htmlFor={`input-${id}`}>
        {label}
      </label>
    </div>
  )
}

const MobileNumberField = (props) => {
  const { label, className, htmlFor, name, control, defaultValue } = props
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,

    rules: {
      required: 'Mobile number is required',
      pattern: {
        value: /\d{12}/,
        message: 'Mobile number is incomplete',
      },
    },
  })
  const [id] = useState(() => {
    if (props.id) return props.id
    return ++fieldId
  })

  const { value, onChange, onBlur } = field

  const [countryCode, mobilenumber] = useMemo(() => {
    if (!value) return ['91', '']
    return [value.substring(0, 2), value.substring(2, 12)]
  }, [value])

  return (
    <Field
      label={label}
      id={`field-${id}`}
      htmlFor={`input-${id}`}
      className={ifNull('mobile-field form-group', className)}
    >
      <div className="control">
        <select
          value={countryCode}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(`${e.currentTarget.value}${mobilenumber || ''}`)
          }}
          className="form-control-plaintext"
        >
          <option value="91">+91</option>
        </select>
        <input
          type="number"
          id={`input-${id}`}
          onBlur={onBlur}
          value={mobilenumber}
          className={ifNull('form-control', error && 'mage-error')}
          onChange={(e) => {
            onChange(
              `${countryCode || '91'}${e.currentTarget.value.substring(0, 10)}`
            )
          }}
        />
      </div>
      {error && <div className="mage-error">{error.message}</div>}
    </Field>
  )
}

const Form = {
  TextField,
  OtpField,
  TextareaField,
  SelectField,
  Field,
  RadioField,
  CheckboxField,
  MobileNumberField,
}

export default Form
