import { findMatchingVariant } from "@/services/helpers/findMatchingProductVariant";
import {
  isProductBundle,
  isProductConfigurable,
  isProductSimple,
} from "@/services/helpers/isProductType";

const processOptionSelections = (product, formValues) => {
  let price, stock_status, list_price, amount_off, percent_off, bundle_options;

  const { variants } = product;
  const isConfigurable = isProductConfigurable(product);
  const isSimple = isProductSimple(product);
  const isBundle = isProductBundle(product);
  const optionSelections = formValues.selected_options;
  const optionsSelected =
    Object.values(optionSelections).filter((value) => !!value).length > 0;
  var selected_variant: any = {};
  if (!isConfigurable || !optionsSelected) {
    price = product.price_range?.minimum_price.final_price.value;
    amount_off = product.price_range?.minimum_price.discount.amount_off;
    percent_off = product.price_range?.minimum_price.discount.percent_off;
    list_price = amount_off + price;
    stock_status = product.stock_status;
  } else {
    const item = findMatchingVariant({
      optionSelections,
      variants,
    });

    selected_variant = item?.product || {};

    product = item?.product || product;
    price = product.price_range?.minimum_price.final_price.value;
    amount_off = product.price_range?.minimum_price.discount.amount_off;
    percent_off = product.price_range?.minimum_price.discount.percent_off;
    list_price = amount_off + price;
    stock_status = product.stock_status;
  }

  if (isBundle) {
    const { sku, options } = product
    const entered_options = options.map((data) => {
      return { uid: data.value[0].uid, value: "" }
    });
    const selected_options = entered_options && entered_options.map((data) => data.uid);
    bundle_options = {
      sku: sku,
      quantity: 1,
      entered_options,
      selected_options
    }
  }

  return {
    price,
    stock_status,
    amount_off,
    percent_off,
    list_price,
    selected_variant,
    bundle_options: bundle_options || null
  };
};

export default processOptionSelections;
