import { ReceiptIcon } from '@/icons/'
import { useState } from 'react'
import { FC } from 'react'
import { CloseButton } from 'react-bootstrap'
import { CartPaymentTile, CartTotal } from '../CartWrapper/CartPaymentTile'

const QuoteBreakdown: FC = ({ renderHeader, renderFooter, children }) => {
  const [showPriceBreakup, setShowPriceBreakup] = useState(false)
  const handlePriceBreakupClose = () => setShowPriceBreakup(false)
  const handlePriceBreakupShow = () => setShowPriceBreakup(true)

  return (
    <>
      {renderHeader && renderHeader()}

      <div className="cart-price-section">
        {showPriceBreakup && <CartPaymentTile />}
        {!showPriceBreakup && <CartTotal />}
        {!showPriceBreakup && (
          <div className="price-break-link" onClick={handlePriceBreakupShow}>
            <ReceiptIcon />
            <span>View Breakup</span>
          </div>
        )}
        {showPriceBreakup && (
          <div className="price-break-link " onClick={handlePriceBreakupClose}>
            <CloseButton /> Close Break
          </div>
        )}
      </div>
      {renderFooter && renderFooter()}
    </>
  )
}

export default QuoteBreakdown
