import type { ParsedUrlQuery } from 'querystring';
import type { UrlObject } from 'url';

export function getLocationOrigin() {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? ':' + port : ''}`;
}

export function parseRelativeUrl(url: string, base?: string) {
  const globalBase = new URL(
    typeof window === 'undefined' ? 'http://n' : getLocationOrigin(),
  );
  const resolvedBase = base ? new URL(base, globalBase) : globalBase;
  const { pathname, searchParams, search, hash, href, origin } = new URL(
    url,
    resolvedBase,
  );
  if (origin !== globalBase.origin) {
    throw new Error(`invariant: invalid relative URL, router received ${url}`);
  }
  return {
    pathname,
    query: searchParamsToUrlQuery(searchParams),
    search,
    hash,
    href: href.slice(globalBase.origin.length),
  };
}
export function searchParamsToUrlQuery(
  searchParams: URLSearchParams,
): ParsedUrlQuery {
  const query: ParsedUrlQuery = {};
  searchParams.forEach((value, key) => {
    if (typeof query[key] === 'undefined') {
      query[key] = value;
    } else if (Array.isArray(query[key])) {
      (query[key] as string[]).push(value);
    } else {
      query[key] = [query[key] as string, value];
    }
  });
  return query;
}

export function removePathTrailingSlash(path: string): string {
  return path.endsWith('/') && path !== '/' ? path.slice(0, -1) : path;
}

export const normalizePathTrailingSlash = process.env.__NEXT_TRAILING_SLASH
  ? (path: string): string => {
      if (/\.[^/]+\/?$/.test(path)) {
        return removePathTrailingSlash(path);
      } else if (path.endsWith('/')) {
        return path;
      } else {
        return path + '/';
      }
    }
  : removePathTrailingSlash;

export function getURL() {
  const { href } = window.location;
  const origin = getLocationOrigin();
  return href.substring(origin.length);
}

function stringifyUrlQueryParam(param: string): string {
  if (
    typeof param === 'string' ||
    (typeof param === 'number' && !isNaN(param)) ||
    typeof param === 'boolean'
  ) {
    return String(param);
  } else {
    return '';
  }
}
export function urlQueryToSearchParams(
  urlQuery: ParsedUrlQuery,
): URLSearchParams {
  const result = new URLSearchParams();
  Object.entries(urlQuery).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => result.append(key, stringifyUrlQueryParam(item)));
    } else {
      result.set(key, stringifyUrlQueryParam(value));
    }
  });
  return result;
}

const slashedProtocols = /https?|ftp|gopher|file/;

export function formatUrl(urlObj: UrlObject) {
  let { auth, hostname } = urlObj;
  let protocol = urlObj.protocol || '';
  let pathname = urlObj.pathname || '';
  let hash = urlObj.hash || '';
  let query = urlObj.query || '';
  let host: string | false = false;

  auth = auth ? encodeURIComponent(auth).replace(/%3A/i, ':') + '@' : '';

  if (urlObj.host) {
    host = auth + urlObj.host;
  } else if (hostname) {
    host = auth + (~hostname.indexOf(':') ? `[${hostname}]` : hostname);
    if (urlObj.port) {
      host += ':' + urlObj.port;
    }
  }

  if (query && typeof query === 'object') {
    query = String(urlQueryToSearchParams(query as ParsedUrlQuery));
  }

  let search = urlObj.search || (query && `?${query}`) || '';

  if (protocol && protocol.substr(-1) !== ':') protocol += ':';

  if (
    urlObj.slashes ||
    ((!protocol || slashedProtocols.test(protocol)) && host !== false)
  ) {
    host = '//' + (host || '');
    if (pathname && pathname[0] !== '/') pathname = '/' + pathname;
  } else if (!host) {
    host = '';
  }

  if (hash && hash[0] !== '#') hash = '#' + hash;
  if (search && search[0] !== '?') search = '?' + search;

  pathname = pathname.replace(/[?#]/g, encodeURIComponent);
  search = search.replace('#', '%23');

  return `${protocol}${host}${pathname}${search}${hash}`;
}
export const urlObjectKeys = [
  'auth',
  'hash',
  'host',
  'hostname',
  'href',
  'path',
  'pathname',
  'port',
  'protocol',
  'query',
  'search',
  'slashes',
];
export function formatWithValidation(url: UrlObject): string {
  if (process.env.NODE_ENV === 'development') {
    if (url !== null && typeof url === 'object') {
      Object.keys(url).forEach((key) => {
        if (urlObjectKeys.indexOf(key) === -1) {
          console.warn(
            `Unknown key passed via urlObject into url.format: ${key}`,
          );
        }
      });
    }
  }

  return formatUrl(url);
}
