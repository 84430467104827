import { useAppContext } from "@/core/App/AppContext";
import "./quantity-counter.scss";
export default function BogoQuantityInput({
  quantity,
  disable = false,
  setQuantity,
}) {
  const [{ mitter }] = useAppContext()
  const onQuantityChange = (quantity) => {
    setQuantity(quantity);
  };

  const increment = () => {
    mitter.emit('NavigationStart', true);
    onQuantityChange(quantity + 2);
  };

  const decrement = () => {
    quantity > 2 && onQuantityChange(quantity - 2);
    quantity > 2 && mitter.emit('NavigationStart', true);
  };
  disable == false && mitter.emit('NavigationEnd', false);
  return (
    <div className="field qty qty-wrapper">
      <label className="label">
        <span>Qty</span>
      </label>
      <div className="control">
        <input
          // {...inputProps}
          value={quantity}
          disabled={disable}
          type="text"
          id="quantity"
          title="Qty"
          className="input-text qty"
          onChange={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, "");
            // onChange(e)
          }}
        />
        <div className="qty-arrows">
          <input
            disabled={disable}
            type="button"
            className="qty-increase"
            onClick={increment}
          />
          <input
            disabled={disable}
            type="button"
            className="qty-decrease"
            onClick={decrement}
          />
        </div>
      </div>
    </div>
  );
}
