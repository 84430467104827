import Form from '../Form'
import useConfigurableVariant from './useConfigurableVariant'
import useConfigurableOption from './useConfigurableOption'
import useProductContext from '@/theme/route/ProductPage/useProductContext'
import { isProductConfigurable } from '@/services/helpers/isProductType'
import { FC, useState } from 'react'
import { ArrowDown } from 'src/common/svgs'
import { ArrowUp } from 'react-bootstrap-icons'
import { Portal } from 'react-portal'

function ConfigurableOption(props) {
   const { label, uid } = useConfigurableOption(props)

   return <option value={uid}>{label}</option>
}

function ConfigurableOptionV2(props) {
   const { uid, label } = props
   return <option value={uid}>{label}</option>
}

function findLabel(options, value) {
   let result = options?.find((row) => row.uid == value)
   return result?.label
}

export default function ConfigurableProductV2({ skipLabel }) {
   const [product, { selectOption }] = useProductContext()
   const { variants, configurable_options } = useConfigurableVariant(product)


   if (!isProductConfigurable(product)) return null

   const {
      cartItem: { selected_options },
   } = product

   if (configurable_options.length == 0) return null

   // This will only work if
   const code = configurable_options[0].attribute_code

   const value = selected_options[code]

   return (
      <fieldset className="fieldset product-variant-options">
         {configurable_options.map((e, i) => {
            const code = e.attribute_code
            const value = selected_options[code]

            return (
               <OptionItem code={code} options={e.values} value={value} selectOption={selectOption} />
            )
         })}
      </fieldset>
   )
}

type IOptionItem = {
   options: any[], value: string, selectOption: (x: any, y: any) => {}, code: string
}
const OptionItem: FC<IOptionItem> = ({ options, value, selectOption, code }) => {
   const [isOpened, setIsOpened] = useState(false)
   return (
      <div className={`OptionItem product-card__options-v2 pack-switch ${isOpened ? 'active' : ''}`}>
         <div
            className="pack-of"
            onClick={() => setIsOpened((prev) => !prev)}
         >
            <span>{findLabel(options, value) ?? 'Size'}</span>{' '}<ArrowDown />
         </div>
         <>
            {isOpened ? (
               <div className="pack-of-list">
                  <table className="table">
                     <tbody>
                        {options.map((x, j) => {
                           return (
                              <tr
                                 key={j}
                                 id={x.uid}
                                 onClick={(e) => {
                                    console.log('selection', code, x.uid)
                                    selectOption(code, x.uid)
                                    setIsOpened(false)
                                 }}
                              >
                                 <td>{x.label}</td>
                              </tr>
                           )
                        })}
                     </tbody>
                  </table>
               </div>
            ) : null}
         </>
      </div>
   )
}
