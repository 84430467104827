import PersistanceStorage from '@/utils/storage';

const storage = new PersistanceStorage();
export const getReferralCode = () => {
  return storage.getItem('referralcode');
};
export const setReferralCode = (referralCode) => {
  return storage.setItem('referralcode', referralCode);
};
export const removeReferralCode = (guest = false) => {
  storage.removeItem('referralcode');
};
