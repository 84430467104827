import clsx from 'clsx'
import './button.scss'

export const Buttons = ({ children, className = null }) => {
  return <div className={'actions ' + (className || '')}>{children}</div>
}

const Button = ({
  children,
  className = null,
  id = null,
  title = null,
  type = 'submit',
  variant = 'primary',
  onClick = null,
  disabled = false,
  fill = false,
  as = 'button',
  style = null,
  getRef = null,
  href = null,
}) => {
  title = title || (typeof children == 'string' && children) || ''
  const As: any = as || 'button'
  type = As == 'button' ? type : null
  let props = {}
  if (as == 'a') {
    props = { href }
  }
  return (
    <As
      {...props}
      ref={getRef}
      type={type as any}
      title={title}
      className={clsx('btn', 'action', className, variant, {
        fill,
      })}
      id={id || 'product-addtocart-button'}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </As>
  )
}

export default Button
