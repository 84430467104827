import { FEAT_SUBSCRPTION_ACTIVE } from 'src/config'
import price_range from './product/price_range'
import product_items from './product/product_items'

const cart = ({
  get_default = true,
  applied_coupons = false,
  available_payment_methods = false,
  billing_address = false,
  items = false,
  prices = false,
  selected_payment_method = false,
  shipping_addresses = false,
  filler_products = false,
  subscription_plan = false,
  // free_gift = false,
}) => {
  const base = `id
    email
  `

  const queries = [base]
  if (get_default) {
    queries.push(`total_quantity
    order_comment
    mstRewardPoints {
      is_applied
      spend_points
      earn_points
    }
    extentionAttr {
      cart_shipping_method
      free_product
    }
    applied_mw_gift_cards{
      code
      remaining
      applied
    }
    `)
  }
  subscription_plan &&
    FEAT_SUBSCRPTION_ACTIVE &&
    queries.push(`subscription_plan {
     plan_id,
     plan_name,
     price,
     discount,
     discount_amount,
     subscription_start_at,
     subscription_end_at,
     subscription_renew_at
     }`)
  applied_coupons && queries.push(`applied_coupons { code }`)

  available_payment_methods &&
    queries.push(`available_payment_methods { code title }`)

  selected_payment_method &&
    queries.push(`selected_payment_method { code title }`)

  billing_address && queries.push(billing_address_str)

  shipping_addresses && queries.push(shipping_address_str)

  // free_gift && queries.push(free_gift_str);
  filler_products && queries.push(filler_products_str)

  if (items) {
    queries.push(`items ${cart_item()}`)
  }
  if (prices) {
    queries.push(`prices ${cart_prices}`)
  }
  return queries.join('\n')
}

const money = `{ value currency }`

const amount = `amount ${money}`

const billing_address_str = `billing_address {
    city
    country {
      code
      label
    }
    firstname
    lastname
    postcode
    region {
      code
      label
    }
    street
    telephone
  }`

const free_gift_str = `mp_free_gifts {
    rule_id
    auto_add
    max_gift
    gifts {
      id
      name
      gift_price
      free_ship
      added
      configurable
      required_option
      sku
      final_price
      image
    }
    notice
    total_added
  }`

const shipping_address_str = `shipping_addresses {
    firstname
    lastname
    street
    city
    postcode
    region {
      code
      label
    }
    country {
      code
      label
    }
    telephone
    available_shipping_methods {
      amount {
        currency
        value
      }
      available
      carrier_code
      carrier_title
      error_message
      method_code
      method_title
      price_excl_tax {
        value
        currency
      }
      price_incl_tax {
        value
        currency
      }
    }
    selected_shipping_method {
      amount {
        value
        currency
      }
      carrier_code
      carrier_title
      method_code
      method_title
    }
  }`

const discount = `{ ${amount} label }`
const cart_tax_item = `{ ${amount} label }`
const cart_prices = `{
    applied_taxes ${cart_tax_item}
    discounts ${discount}
    grand_total ${money}
    subtotal_excluding_tax ${money}
    subtotal_including_tax ${money}
    subtotal_with_discount_excluding_tax ${money}
    shipping_flat_rate
    cod_charges
  }`

const prices = `
      discounts ${discount}
      price ${money}
      row_total ${money}
      row_total_including_tax ${money}
      total_item_discount ${money}
  `

const cart_item = () => {
  return `{
        uid
        __typename
        quantity
        prices { ${prices} }

        product {
            name
            stock_status
            bogo
            sku
            uid
            excerpt
            price_range {
                ${price_range()}
            }
            url_key
            canonical_url
            image {
                url
                label
            }
            size
            pack_size
        }
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            configurable_product_option_value_uid
            option_label
            value_label
          }
        }
        ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
        }
    }
    `
}

const filler_products_str = `filler_products {
  items {
    ${product_items({})}
  }
  }`

export default cart
