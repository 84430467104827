import React from 'react'

const Digestive = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.7197 7.91946L33.2163 7.9774L33.2197 7.94853V7.91946H32.7197ZM19.5095 11.7507C19.2333 11.7507 19.0095 11.9745 19.0095 12.2507C19.0095 12.5268 19.2333 12.7507 19.5095 12.7507V11.7507ZM28.7441 4.31011C28.7441 3.2954 29.537 2.50013 30.4819 2.50013V1.50013C28.955 1.50013 27.7441 2.77328 27.7441 4.31011H28.7441ZM30.4819 2.50013C31.4268 2.50013 32.2197 3.2954 32.2197 4.31011H33.2197C33.2197 2.77328 32.0088 1.50013 30.4819 1.50013V2.50013ZM32.2197 4.31011V7.91946H33.2197V4.31011H32.2197ZM32.2231 7.86152C32.1468 8.51483 31.8543 9.50854 31.2392 10.3258C30.6371 11.1258 29.7348 11.7507 28.3885 11.7507V12.7507C30.1028 12.7507 31.282 11.9318 32.0382 10.9272C32.7814 9.93973 33.1241 8.76782 33.2163 7.9774L32.2231 7.86152ZM28.3885 11.7507H19.5095V12.7507H28.3885V11.7507ZM27.7441 4.31011V6.40353H28.7441V4.31011H27.7441ZM27.7441 6.40353C27.7441 6.43853 27.7208 6.57169 27.5698 6.7027C27.4239 6.82939 27.1186 6.98634 26.5116 6.98634V7.98634C27.2907 7.98634 27.8516 7.78235 28.2254 7.45787C28.5942 7.13772 28.7441 6.72947 28.7441 6.40353H27.7441ZM26.5116 6.98634H23.6963V7.98634H26.5116V6.98634ZM23.6963 6.98634H16.9107V7.98634H23.6963V6.98634Z"
        fill="#F6F6F6"
      />
      <path
        d="M32.8642 17.7316C33.1403 17.7316 33.3642 17.5077 33.3642 17.2316C33.3642 16.9554 33.1403 16.7316 32.8642 16.7316V17.7316ZM17.5606 16.7316C15.3029 16.7316 13.2962 15.0001 13.2962 12.4672H12.2962C12.2962 15.5957 14.7949 17.7316 17.5606 17.7316V16.7316ZM13.2962 12.4672C13.2962 9.82892 14.8967 7.98635 17.0553 7.98635V6.98635C14.1905 6.98635 12.2962 9.44436 12.2962 12.4672H13.2962ZM17.5606 17.7316H32.8642V16.7316H17.5606V17.7316Z"
        fill="#F6F6F6"
      />
      <path
        d="M19.4374 12.2508L34.4523 12.3952C37.2431 12.3952 39.5054 14.6252 39.5054 17.3761C39.5054 20.127 37.2431 22.357 34.4523 22.357H11.4969"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M33.0086 17.3036H10.0532C7.26245 17.3036 5.0001 19.5336 5.0001 22.2845C5.0001 25.0353 7.26245 27.2654 10.0532 27.2654H28.1721"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M32.8641 27.3377C32.8641 24.5869 30.6018 22.3568 27.811 22.3568M17.1996 32.3186H26.7282"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M17.2717 37.2273C14.481 37.2273 12.2186 34.9972 12.2186 32.2464C12.2186 29.4955 14.481 27.2655 17.2717 27.2655"
        stroke="#F6F6F6"
      />
      <path
        d="M20.0148 32.3187C24.2306 32.3187 25.1401 35.134 25.0679 36.5056V40.2593C25.0679 41.6148 23.9691 42.7137 22.6136 42.7137C21.2581 42.7137 20.1592 41.6148 20.1592 40.2593V38.599C20.1592 38.0696 19.986 37.0253 19.293 37.0831C18.6 37.1408 17.7289 37.2034 17.1996 37.2275"
        stroke="#F6F6F6"
      />
      <path
        d="M38.4281 33.4012C38.4281 36.5083 35.878 39.0371 32.72 39.0371C29.562 39.0371 27.0119 36.5083 27.0119 33.4012C27.0119 30.2941 29.562 27.7653 32.72 27.7653C35.878 27.7653 38.4281 30.2941 38.4281 33.4012Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
      <path
        d="M32.8642 30.5858H32.5755C32.0971 30.5858 31.7093 30.9736 31.7093 31.452C31.7093 31.9304 31.3214 32.3183 30.843 32.3183H30.7708C30.2525 32.3183 29.8324 32.7384 29.8324 33.2567V33.3289C29.8324 33.8472 30.2525 34.2673 30.7708 34.2673C31.2891 34.2673 31.7093 34.6875 31.7093 35.2057C31.7093 35.724 32.1294 36.1442 32.6477 36.1442H32.7921C33.3103 36.1442 33.7305 35.724 33.7305 35.2057V35.1336C33.7305 34.6551 34.1183 34.2673 34.5967 34.2673C35.0751 34.2673 35.463 33.8795 35.463 33.4011V33.1845C35.463 32.7061 35.0751 32.3183 34.5967 32.3183C34.1183 32.3183 33.7305 31.9304 33.7305 31.452C33.7305 30.9736 33.3427 30.5858 32.8642 30.5858Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const ImmunityThroat = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.9178 37.1515C36.9549 36.8549 36.4062 35.9056 33.9144 34.4818C31.4226 33.0579 31.9863 29.8097 32.5795 28.3636C36.4729 20.3544 36.2504 15.1262 36.1392 13.9026C36.0279 12.6789 33.8032 2 22.7905 2C11.7779 2 9.44189 9.45301 8.88565 10.4542C8.4209 11.2906 8.44074 17.4622 8.44074 18.0184V18.0184C8.44074 19.1403 9.11313 20.2526 8.62297 21.2617L7.73397 23.092C7.61218 23.3427 7.45643 23.5754 7.31944 23.8181C6.89914 24.563 6.66902 25.7203 8.10698 26.2501C8.24431 26.3007 8.37083 26.3487 8.4873 26.3942C9.39535 26.7491 9.10822 27.6112 9.10822 28.5861V28.5861C9.10822 28.8086 10.4431 29.5872 10.4431 29.6985C10.4431 29.8097 9.77565 30.1434 9.88689 30.4772C9.99813 30.8109 10.888 31.0334 10.6656 32.257C10.4431 33.4806 9.55317 35.5941 11.7779 36.0391C14.0027 36.4841 15.7825 35.7054 16.45 36.0391C17.1174 36.3728 17.3399 36.929 17.5624 37.5964C17.7404 38.1304 18.7489 41.2303 19.231 42.7134"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M17.2305 28.9221C17.3788 27.4019 18.6543 24.3613 22.5699 24.3613"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M14.894 26.5871C15.784 24.251 19.0544 20.0907 25.0168 22.1375"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M21.5679 33.8168C23.2735 33.928 26.7071 33.0826 26.7961 28.811"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M19.1211 35.931C21.42 37.0063 26.6631 37.6218 29.2438 31.4814"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M23.8485 28.9225C23.8485 29.8751 23.0763 30.6473 22.1237 30.6473C21.1711 30.6473 20.3989 29.8751 20.3989 28.9225C20.3989 27.97 21.1711 27.1978 22.1237 27.1978C23.0763 27.1978 23.8485 27.97 23.8485 28.9225Z"
        stroke="#F6F6F6"
      />
    </svg>
  )
}

const Women = () => {
  return (
    <svg
      width="31"
      height="43"
      viewBox="0 0 31 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1674 29.4644C6.34838 28.3444 1 22.4073 1 15.3059C1 7.40498 7.438 1 15.3797 1C23.3213 1 29.7593 7.40498 29.7593 15.3059C29.7593 22.5855 24.1467 28.5641 17.0757 29.4644"
        stroke="#F6F6F6"
      />
      <path
        d="M25.279 15.3066C25.279 20.7288 20.8511 25.1304 15.3814 25.1304C9.91174 25.1304 5.48379 20.7288 5.48379 15.3066C5.48379 9.88435 9.91174 5.48271 15.3814 5.48271C20.8511 5.48271 25.279 9.88435 25.279 15.3066Z"
        stroke="#F6F6F6"
      />
      <path
        d="M13.4384 13.6717C13.6455 13.6717 13.8134 13.5038 13.8134 13.2967V10.224H17.087V13.2967C17.087 13.5038 17.2549 13.6717 17.462 13.6717H20.5359V16.8003H17.462C17.2549 16.8003 17.087 16.9682 17.087 17.1753V20.5353H13.8134V17.1753C13.8134 16.9682 13.6455 16.8003 13.4384 16.8003H10.0771V13.6717H13.4384Z"
        stroke="#F6F6F6"
        strokeWidth="0.75"
        strokeLinejoin="round"
      />
      <path
        d="M12.9577 28.9027L13.0194 33.1508H7.78368V37.2804H13.0194V41.9999H17.2226V37.2804H22.3846V33.1508H17.2226V28.9027"
        stroke="#F6F6F6"
      />
    </svg>
  )
}

const Joint = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8365 2L10.0499 13.2507C7.88011 14.7775 5.71036 17.992 6.03181 24.421C6.35045 30.7936 7.61877 41.7189 7.61877 42.0671"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6585 12.286L24.7559 24.5011C23.8719 25.3047 21.7343 27.5227 21.5414 32.8587C21.3485 38.1947 20.9454 39.713 20.5436 42.7132"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.015 5.1338L17.6036 16.3041C16.7464 15.5541 14.6462 14.5683 13.1033 16.6256C11.1746 19.1971 13.9873 21.6884 16.157 20.4829C15.916 21.6348 16.1249 23.9224 18.8893 23.8581C22.3449 23.7778 21.3806 19.84 20.2555 19.1971L31.6669 7.54466"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1402 42.0674V27.5947C12.1402 26.8486 11.7167 26.1756 11.104 25.7498C9.9252 24.9307 8.63975 23.6688 8.96142 22.4588C9.49122 20.4658 11.3834 21.8159 11.7618 22.0731C12.1402 22.3302 13.881 24.4518 16.1516 24.9018C17.3881 25.1469 18.0859 26.0022 17.9394 26.5953C17.626 27.8636 15.6218 28.2955 15.6218 29.6019V42.0674"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
const Men = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_4)">
        <path
          d="M27.5584 37.5881C22.0651 42.9402 13.2472 42.8054 7.86313 37.2793C2.47905 31.7532 2.57377 22.9348 8.06705 17.5827C13.0701 12.7082 20.9226 12.5043 26.3445 16.684C26.6366 16.9091 27.0576 16.8941 27.3302 16.6285L33.2099 10.8999C33.5066 10.6108 33.5128 10.136 33.2237 9.8393L30.616 7.16285L39.0768 6.87861L38.7454 15.5067L36.3163 13.0135C36.0272 12.7168 35.5524 12.7106 35.2557 12.9996L29.3703 18.7338C29.1053 18.992 29.0724 19.3983 29.2736 19.6956C32.9928 25.1924 32.4412 32.8308 27.5584 37.5881ZM39.3454 6.86959C39.3452 6.8696 39.345 6.8696 39.3448 6.86961L39.3454 6.86959ZM39.0871 6.61073C39.0871 6.61053 39.0871 6.61033 39.0871 6.61013L39.0871 6.61073Z"
          stroke="#F6F6F6"
        />
        <path
          d="M10.8621 20.4518C14.7701 16.6443 21.0519 16.7448 24.8928 20.6869C28.7337 24.6291 28.6706 30.9114 24.7626 34.719C20.8546 38.5266 14.5728 38.4261 10.7319 34.4839C6.89108 30.5417 6.95411 24.2594 10.8621 20.4518Z"
          stroke="#F6F6F6"
        />
        <path
          d="M17.2008 30.2116L15.2539 32.1085L13.1271 29.9256L15.074 28.0287C15.3707 27.7396 15.3769 27.2648 15.0878 26.9681L13.1901 25.0203L15.2685 22.9954L17.1662 24.9431C17.4553 25.2398 17.9301 25.246 18.2268 24.9569L20.3807 22.8584L22.5075 25.0412L20.3536 27.1398C20.0569 27.4289 20.0507 27.9037 20.3398 28.2004L22.4393 30.3553L20.3609 32.3802L18.2614 30.2254C17.9723 29.9287 17.4975 29.9225 17.2008 30.2116Z"
          stroke="#F6F6F6"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_4">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
const Oral = () => {
  return (
    <svg
      width="38"
      height="35"
      viewBox="0 0 38 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2673 21.1914L14.3276 32.6409C13.7087 34.4202 10.3822 35.8127 8.5255 32.0993L1.4856 13.2231C0.789344 11.3922 0.309702 6.91042 3.96117 3.63028C7.61263 0.350155 11.4137 0.871056 12.8577 1.54152C16.8032 3.78501 24.462 9.46335 26.6281 8.34934"
        stroke="#F6F6F6"
      />
      <path
        d="M26.5507 32.0574C24.694 35.7708 21.3675 34.3783 20.7486 32.5989L17.9339 21.4924C17.9022 21.3672 17.8665 21.2382 17.7796 21.1427C17.6419 20.9916 17.4232 20.9262 17.1899 21.3461M17.7315 4.48127C18.5979 3.7386 21.0838 2.18404 22.2184 1.4996C23.6625 0.829133 27.4635 0.308231 31.115 3.58836C34.7665 6.86849 34.2868 11.3503 33.5906 13.1812L30.4173 21.6898"
        stroke="#F6F6F6"
      />
      <path
        d="M4.88869 6.72479C4.21822 7.73049 3.18674 10.2989 4.42452 12.5269"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M5.97322 5.56449C6.2053 5.22926 6.88609 4.54332 7.75254 4.48143"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M37.5002 27.9992C37.5002 31.3492 34.7509 34.0749 31.3471 34.0749C27.9433 34.0749 25.194 31.3492 25.194 27.9992C25.194 24.6492 27.9433 21.9235 31.3471 21.9235C34.7509 21.9235 37.5002 24.6492 37.5002 27.9992Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
      <path
        d="M30.2637 24.9822V26.8389H28.2523V28.9276H30.2637V30.939H32.4298V28.9276H34.2865V26.8389H32.4298V24.9822H30.2637Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
    </svg>
  )
}
const Personal = () => {
  return (
    <svg
      width="43"
      height="44"
      viewBox="0 0 43 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 35.5385V30.127C1 28.7113 2.14765 27.5636 3.56335 27.5636C4.97904 27.5636 6.12669 28.7113 6.12669 30.127V35.5385C6.12669 36.9542 4.97904 38.1019 3.56335 38.1019C2.14765 38.1019 1 36.9542 1 35.5385Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M6.19774 28.6317C9.1171 27.9197 15.5397 26.5098 17.8752 26.5668C20.7946 26.638 23.5003 27.6348 23.9276 28.1333C24.3204 28.5915 25.5558 30.1332 23.4835 31.2088C23.0871 31.4145 22.6288 31.458 22.1841 31.4167C20.8472 31.2926 18.9698 31.1238 18.7297 31.1238C18.3736 31.1238 16.7359 30.9814 16.6647 32.1207C16.5935 33.26 17.3056 33.6872 18.0176 33.7584C18.5872 33.8153 21.6253 34.4467 23.0731 34.7552C23.5478 34.8027 24.8675 34.7837 26.3485 34.328C27.8295 33.8723 31.6651 33.1888 33.3977 32.9039C33.9911 32.809 35.2205 32.9324 35.3914 34.1856C35.605 35.7521 34.7506 36.2505 33.9673 36.4641C33.3407 36.635 25.5415 39.1462 21.7202 40.3804C21.1506 40.5643 19.4417 40.8788 16.3799 39.8107L6.19774 36.8202"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M10.683 27.635C8.00098 25.7125 3.54835 20.2013 7.194 13.5366C10.4896 7.51182 15.7217 8.45087 19.0121 10.4019C20.6272 11.3596 22.9641 11.5197 24.5635 10.536C25.3281 10.0658 26.1284 9.6838 26.8463 9.54919C27.0134 9.51787 27.2045 9.4877 27.416 9.4612M26.8463 34.1858C37.3133 24.4308 38.8798 21.7251 37.3845 15.3167C37.2835 14.8837 37.1584 14.4808 37.0122 14.1063"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M39.5009 8.85915C39.5009 12.0896 36.8497 14.7183 33.5669 14.7183C30.2842 14.7183 27.633 12.0896 27.633 8.85915C27.633 5.62873 30.2842 3 33.5669 3C36.8497 3 39.5009 5.62873 39.5009 8.85915Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
      <path
        d="M32.5189 5.94172V7.73725H30.5738V9.75721H32.5189V11.7024H34.6137V9.75721H36.4092V7.73725H34.6137V5.94172H32.5189Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
      <path
        d="M11.6804 21.5827H14.6277C15.1413 21.5827 15.6182 21.3163 15.8874 20.8788L16.2088 20.3566C16.6437 19.6498 17.673 19.6556 18.0999 20.3672V20.3672C18.5381 21.0974 19.6024 21.0797 20.016 20.3353L20.4522 19.55C21.084 18.4128 22.7293 18.4418 23.3207 19.6004L23.9771 20.8865C24.5975 22.1019 26.3201 22.1408 26.9947 20.9545V20.9545C27.2994 20.4185 27.8685 20.0875 28.4851 20.0875H32.6144"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
    </svg>
  )
}
const Sexual = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_3)">
        <path
          d="M11.2262 33.3509C6.05552 32.4887 2 27.9178 2 22.4505C2 16.3677 6.88174 11.4366 12.9037 11.4366C18.9256 11.4366 23.8073 16.3677 23.8073 22.4505C23.8073 23.506 23.6564 24.5259 23.3756 25.4909M14.1897 33.3509C17.4732 32.9265 20.3419 31.0358 22.0725 28.3468"
          stroke="#F6F6F6"
        />
        <path
          d="M19.2858 27.1176C17.8527 29.1058 15.5278 30.3984 12.9035 30.3984C8.54914 30.3984 5.01927 26.8399 5.01927 22.4502C5.01927 18.0605 8.54914 14.502 12.9035 14.502C17.2578 14.502 20.7876 18.0605 20.7876 22.4502C20.7876 23.0828 20.7143 23.6981 20.5758 24.2881"
          stroke="#F6F6F6"
        />
        <path
          d="M11.1142 33.18V36.189H7.14418V39.3682H11.1142V43.0017H14.3015V39.3682H18.2156V36.189H14.3015V33.18"
          stroke="#F6F6F6"
        />
        <path
          d="M14.4786 14.8936C13.476 18.5 14.3897 22.5306 17.2361 25.4133C21.4998 29.7313 28.4856 29.8388 32.8393 25.6533C36.7524 21.8914 37.1525 15.8382 34.1087 11.5373L38.8629 6.9668L41.4759 9.61305L41.7793 1.81846L34.0329 2.07525L36.7647 4.84179L32.0104 9.41231C27.7181 6.04462 21.4104 6.16009 17.3991 10.0164C16.8217 10.5714 16.3197 11.1741 15.893 11.8121"
          stroke="#F6F6F6"
        />
        <path
          d="M17.3329 16.096C16.7791 18.5966 17.4752 21.3237 19.4303 23.3038C22.5133 26.426 27.5595 26.5086 30.7014 23.4881C33.8432 20.4677 33.8909 15.488 30.808 12.3658C27.725 9.2435 22.6788 9.16096 19.5369 12.1814C19.2218 12.4843 18.9379 12.8069 18.6851 13.1454"
          stroke="#F6F6F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_3">
          <rect
            width="42"
            height="43"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
const StressAnxietySleep = () => {
  return (
    <svg
      width="30"
      height="46"
      viewBox="0 0 30 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5556 23.0021C16.9826 22.2196 18.2026 21.124 19.6667 23.0021"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M10.3333 23.0021C10.7603 22.2196 11.9804 21.124 13.4444 23.0021"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M13.4444 28.1873C13.8715 27.4047 15.0915 26.3091 16.5556 28.1873"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M9.83608 34.4784L5.91936 34.718C1.91157 35.5485 0.970342 37.7792 1.0007 38.7907V44.7799H5.7372M9.83608 34.4784L14.4815 37.3533V44.7799H5.7372M9.83608 34.4784C10.3522 34.2389 11.2568 31.8585 11.1111 30.2614M5.7372 39.6691V44.7799"
        stroke="#F6F6F6"
      />
      <path
        d="M19.4842 34.1105L23.7022 34.3586C28.0183 35.2188 29.0319 37.5291 28.9992 38.5768V44.7799H23.8984M19.4842 34.1105L14.4815 37.088V44.7799H23.8984M19.4842 34.1105C18.9284 33.8624 17.675 31.9156 17.8319 30.2614M23.8984 39.4866V44.7799"
        stroke="#F6F6F6"
      />
      <path
        d="M15.5185 17.2984C14.3214 17.3564 10.1473 17.6145 9.09614 16.7799C8.36618 17.3015 7.0289 18.9012 7.51943 21.1268C6.4391 21.9672 4.99671 23.9957 7.86983 25.3867C7.75303 26.1691 7.79973 27.9079 8.92095 28.6034C10.3225 29.4727 13.0957 31.2115 14.9352 31.2984"
        stroke="#F6F6F6"
      />
      <path
        d="M15.0741 17.2984C16.1632 17.3564 19.1731 17.6145 20.1294 16.7799C20.7935 17.3015 22.0102 18.9012 21.5639 21.1268C22.5468 21.9672 23.859 23.9957 21.2451 25.3867C21.3514 26.1691 21.3089 27.9079 20.2888 28.6034C19.0138 29.4727 16.155 31.2115 14.4815 31.2984"
        stroke="#F6F6F6"
      />
      <path
        d="M7.16002 21.4569C5.66116 19.1413 4.77487 13.8157 13.2206 11.0371C14.0042 10.8618 15.6898 10.5812 17.319 10.5591C17.8035 10.5525 17.9727 11.1391 17.581 11.4243V11.4243C17.3381 11.601 17.5503 12.0494 17.8453 11.9931C19.2496 11.7253 20.9788 11.6029 22.2708 12.0565C23.0826 12.3415 23.1494 13.3342 23.0681 14.1907C23.0132 14.7698 23.096 15.429 23.4846 16.0351C24.4621 17.56 22.9959 21.9651 22.6048 21.9651"
        stroke="#F6F6F6"
      />
      <path
        d="M6.59964 9.52177L4.95589 7.23047C4.60268 6.73812 4.95457 6.0526 5.56051 6.0526V6.0526C6.15998 6.0526 6.51338 5.38001 6.1733 4.88634L4.63881 2.65885"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M13.915 7.93833L15.5245 5.57427C15.866 5.0728 15.5068 4.39375 14.9001 4.39375V4.39375C14.2916 4.39375 13.9328 3.71096 14.2781 3.20982L15.8004 1"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M25.0011 2.58391L23.5628 4.81579C23.2396 5.31729 23.5996 5.97766 24.1963 5.97766V5.97766C24.7982 5.97766 25.1572 6.6485 24.8233 7.14933L23.1911 9.59766"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
    </svg>
  )
}
const ArrowDown = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.18363 9.79291L2.53738 4.91653C2.45982 4.83517 2.41655 4.72707 2.41655 4.61466C2.41655 4.50225 2.45982 4.39415 2.53738 4.31279L2.54263 4.30753C2.58023 4.26795 2.62549 4.23644 2.67566 4.2149C2.72583 4.19336 2.77985 4.18226 2.83444 4.18226C2.88904 4.18226 2.94306 4.19336 2.99323 4.2149C3.04339 4.23644 3.08865 4.26795 3.12626 4.30753L7.50126 8.89953L11.8745 4.30753C11.9121 4.26795 11.9574 4.23644 12.0075 4.2149C12.0577 4.19336 12.1117 4.18226 12.1663 4.18226C12.2209 4.18226 12.2749 4.19336 12.3251 4.2149C12.3753 4.23644 12.4205 4.26795 12.4581 4.30753L12.4634 4.31279C12.5409 4.39415 12.5842 4.50225 12.5842 4.61466C12.5842 4.72707 12.5409 4.83517 12.4634 4.91653L7.81713 9.79291C7.77627 9.8358 7.72713 9.86994 7.67268 9.89327C7.61823 9.91659 7.55962 9.92862 7.50038 9.92862C7.44115 9.92862 7.38253 9.91659 7.32808 9.89327C7.27363 9.86994 7.22449 9.8358 7.18363 9.79291Z"
        fill="#252525"
        stroke="#252525"
        strokeWidth="0.5"
      />
    </svg>
  )
}

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-plus"
      viewBox="0 0 16 16"
    >
      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
    </svg>
  )
}
const MinusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-dash"
      viewBox="0 0 16 16"
    >
      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
    </svg>
  )
}

const Subscription1 = () => {
  return (
    <svg
      width="103"
      height="93"
      viewBox="0 0 103 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.7953 90.4706C65.7952 85.176 68.019 74.0574 83.2675 74.0574C98.5159 74.0574 101.269 85.176 101.269 90.4706"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M29.7922 90.4706C29.0862 90.4706 27.6743 90.1529 27.6743 88.8822C27.6743 87.6115 27.6743 83.4111 27.6743 81.4698C27.8508 80.0579 29.3686 77.2341 34.0278 77.2341C38.6871 77.2341 40.2048 80.0579 40.3813 81.4698V88.8822C40.3813 89.5882 39.8519 90.8942 37.734 90.4706"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M14.9675 67.1743C14.6145 67.7038 13.9086 69.5039 13.9086 72.4689C13.9086 75.4339 13.9086 79.7048 13.9086 81.4697"
        stroke="#252525"
        stroke-width="2.5"
      />
      <path
        d="M32.9686 67.1744C33.3216 67.7039 34.0275 69.504 34.0275 72.469C34.0275 75.4339 34.0275 74.9398 34.0275 76.7047"
        stroke="#252525"
        stroke-width="2.5"
      />
      <circle
        cx="24.4969"
        cy="49.1728"
        r="13.0454"
        stroke="#252525"
        stroke-width="2.5"
      />
      <circle
        cx="83.2676"
        cy="58.1735"
        r="10.3981"
        stroke="#252525"
        stroke-width="2.5"
      />
      <path
        d="M1.73086 91C1.73107 83.0582 1.73086 67.1744 16.5557 67.1744L23.9681 73.5279L31.91 67.1744C36.6751 67.1744 46.7349 71.516 46.7349 91"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <circle
        cx="13.9081"
        cy="85.176"
        r="3.51513"
        stroke="#252525"
        stroke-width="2.5"
      />
      <path
        d="M30.3216 26.4061C40.6424 28.3174 40.8155 34.3969 39.3242 38.0294C39.1549 38.4416 39.5634 38.9051 39.9926 38.7853C83.9695 26.5042 64.0745 2.57576 49.3815 2.05101C34.5566 1.52155 30.3216 5.22776 27.1449 7.87506C23.9681 10.5224 16.0262 23.7588 30.3216 26.4061Z"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M69.5027 24.9729C73.6896 25.8787 75.6075 27.5125 77.1891 28.814C79.6675 30.8534 85.8633 41.0504 74.7108 43.0897C66.9039 44.5174 66.5396 48.9638 67.5852 51.7837C67.7404 52.202 67.3613 52.6811 66.9315 52.5612C55.3023 49.319 49.4606 45.0391 47.2654 40.7945"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M31.9086 12.6401H57.8521"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M31.9086 20.0525H57.8521"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
    </svg>
  )
}
const Subscription2 = () => {
  return (
    <svg
      width="76"
      height="93"
      viewBox="0 0 76 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5733 33.3687L35.7753 38.9697C36.4968 39.3305 37.3507 38.7308 37.2788 37.9273C36.9468 34.2159 36.5678 28.2372 34.2547 23.2256C31.4681 17.1883 25.3415 19.6909 23.1901 21.3814C17.6575 26.914 21.8063 31.5245 24.5733 33.3687Z"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M49.9306 33.5054L38.7286 39.1064C38.0071 39.4672 37.1532 38.8675 37.2251 38.064C37.5571 34.3526 37.9361 28.3739 40.2492 23.3623C43.0358 17.325 49.1624 19.8276 51.3138 21.5181C56.8464 27.0507 52.6976 31.6612 49.9306 33.5054Z"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M67.4503 50.7329V41.8235C67.4503 40.7189 66.5549 39.8235 65.4503 39.8235H9.05274C7.94817 39.8235 7.05274 40.7189 7.05274 41.8235V50.7329C7.05274 51.8375 7.94817 52.7329 9.05273 52.7329H65.4503C66.5549 52.7329 67.4503 51.8375 67.4503 50.7329ZM61.9177 54.7329V89C61.9177 90.1046 61.0223 91 59.9177 91H15.0464C13.9418 91 13.0464 90.1046 13.0464 89V54.7329C13.0464 53.6283 13.9418 52.7329 15.0464 52.7329H59.9177C61.0223 52.7329 61.9177 53.6283 61.9177 54.7329Z"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M30.5664 39.8235V91"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M44.3984 39.8235V91"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M9.83599 2.01741C9.36914 4.78274 7.05171 10.5871 1.5168 11.682C4.58885 12.4569 9.4395 15.2026 9.79828 19.9983"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.83588 2.01751C10.2911 4.78477 12.5842 10.5988 18.1145 11.7169C15.0392 12.4789 10.1771 15.2043 9.79817 19.9984"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M69.5265 8.43239C68.4307 10.2977 65.1663 13.8458 60.8755 13.1154C62.8774 14.5175 65.6203 17.8288 64.5622 21.3845"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M69.5279 8.43232C69.0963 10.5522 69.153 15.3732 72.8327 17.6978C70.4065 17.4027 66.1531 18.0324 64.5636 21.3845"
        stroke="#252525"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
const Subscription3 = () => {
  return (
    <svg
      width="81"
      height="81"
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.12976 9.18796C5.96702 8.26702 14.0099 8.35911 15.4834 16.095C15.7736 17.6186 16.1757 19.6791 16.6497 22.0811M69.819 38.658L66.1352 55.235H23.3114C21.709 47.3463 18.5822 31.8753 16.6497 22.0811M16.6497 22.0811H51.8606"
        stroke="#252525"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M73.9628 62.1421H19.6272C17.7853 62.1421 13.6411 62.2342 13.6411 58.9188C13.6411 54.5025 15.0225 55.2351 23.311 55.2351"
        stroke="#252525"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M52.3221 33.1325L76.7271 1.82043"
        stroke="#252525"
        stroke-width="3"
        stroke-linecap="round"
      />
      <circle
        cx="54.1628"
        cy="7.34606"
        r="4.94659"
        stroke="#252525"
        stroke-width="3"
      />
      <circle
        cx="28.3766"
        cy="73.6539"
        r="4.94659"
        stroke="#252525"
        stroke-width="3"
      />
      <circle
        cx="57.8475"
        cy="73.6539"
        r="4.94659"
        stroke="#252525"
        stroke-width="3"
      />
      <circle
        cx="74.4236"
        cy="26.6859"
        r="4.94659"
        stroke="#252525"
        stroke-width="3"
      />
    </svg>
  )
}
export {
  Digestive,
  ImmunityThroat,
  Women,
  Joint,
  Men,
  Oral,
  Personal,
  Sexual,
  StressAnxietySleep,
  ArrowDown,
  PlusIcon,
  MinusIcon,
  Subscription1,
  Subscription2,
  Subscription3,
}
