export const numberNotation = ({
  value,
  currency = 'INR',
  excludeCurrency = false,
  removeDecimal = true,
}) => {
  const formatter = new Intl.NumberFormat('en-IN', {
    ...(!excludeCurrency &&
      currency && {
        style: 'currency',
        currency,
      }),
  });

  var cur = formatter.format(value);

  if (isNaN(value)) return 0;

  cur = cur.replace(/^(\D+)/, '$1 ');

  if (removeDecimal) {
    cur = cur.replace(/\D00(?=\D*$)/, '');
  }

  return cur;
};

export const convertCurrency = ({
  currency,
  sourceValue,
  destinationValue,
}) => {

};
