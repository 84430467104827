import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import { useEffect, useState } from 'react'
import { set, useForm } from 'react-hook-form'
import {
   createAccountOTP,
   createAccountOTPVerify,
   createCustomerAccount,
   referralGraphql,
   updateCustomer,
} from 'src/graphql/mutations/customermutation'
import { loginOTP, loginOTPVerify } from 'src/graphql/mutations/loginmuation'
import { deleteCookie, getCookie } from 'src/services/cookieService'
import { Buttons } from '../Button'
import { Button, Offcanvas } from 'react-bootstrap'
import useAuthContext from '../Contexts/AuthContext'
import Form from '../Form'
import './login-component.scss'
import { getToken, setToken } from '@/utils/auth'
import { getCartId } from '@/utils/cart'
import { getReferralCode, removeReferralCode } from '@/utils/referral'
import { useAppContext } from '@/core/App/AppContext'
import { fbLogin, gmailLogin } from 'src/services/socialLoginService'
import { ButtonLoader } from '../ButtonLoader'
import { useStore } from 'react-redux'
// import { FBPostLogin } from "src/graphql/queries/loginQuery";
import { customer, FBPostLogin } from 'src/graphql/queries/loginQuery'
import we from 'src/services/webengage'
import { gql } from 'graphql-request'

import { Helmet } from 'react-helmet'
declare var gapi
import fbLoginIcon from '../../../icons/facebook_login_icon.png'
import googleLoginIcon from '../../../icons/google_login_icon.png'
import ReCaptcha from 'react-google-recaptcha'
import { GCAPTCH_SITE_KEY } from 'src/config'
import LoginModal from '../Header/LoginModal'
import { parseEmail, parseMobileNumber } from 'src/server/utils'

const ResendOTP = ({ mobilenumber, isRegister, socialLogin }) => {
   const [isLocked, setIsLocked] = useState(false)
   const [remainingTime, setRemainingTime] = useState(30)
   const resend = () => {
      ; (socialLogin || isRegister) &&
         graphqlWebClient
            .request(createAccountOTP(`${mobilenumber}`))
            .then((d) => {
               alert(d.createAccountOTP.message)
               setIsLocked(true)
            })
      !socialLogin &&
         !isRegister &&
         graphqlWebClient.request(loginOTP(`${mobilenumber}`)).then((d) => {
            alert(d.loginOTP.message)
            setIsLocked(true)
         })
   }
   useEffect(() => {
      let int = null
      if (isLocked) {
         int = setInterval(() => {
            setRemainingTime((prev) => prev - 1)
         }, 1000)
      }
      return () => {
         clearInterval(int)
      }
   }, [isLocked])
   useEffect(() => {
      if (remainingTime <= 0) {
         setIsLocked(false)
         setRemainingTime(30)
      }
   }, [remainingTime])
   return (
      <div className="resend-otp mb-3">
         Not received your code?{' '}
         {isLocked ? (
            <b className="pointer">
               {isLocked
                  ? 'Resend in in ' + remainingTime + ' seconds'
                  : 'Resend code'}
            </b>
         ) : (
            <b className="pointer" onClick={resend}>
               {isLocked
                  ? 'Resend in in ' + remainingTime + ' seconds'
                  : 'Resend code'}
            </b>
         )}
      </div>
   )
}

const SendOTP = ({ setOtpStatus, mobilenumber, onClose, isVisible }) => {
   // const [isVisible, setIsVisible] = useState(false)
   // const  [buttonLoading, setbuttonLoading] = useState(false);
   const [loading, setLoading] = useState(false)
   const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)

   const {
      register,
      handleSubmit,
      control,
      formState: { errors },
   } = useForm<any>({
      defaultValues: {
         mobilenumber: null,
      },
   })

   const onLoginSubmit = async (data) => {
      console.log('Sending otp', data)
      setLoading(true)
      const {
         loginOTP: { message, status },
      } = await graphqlWebClient.request(loginOTP(`${data.mobilenumber}`))

      if (status == 'UNREGISTERED') {
         const {
            createAccountOTP: { message: newMessage, status, token },
         } = await graphqlWebClient.request(
            createAccountOTP(`${data.mobilenumber}`)
         )
         alert(newMessage)
         setOtpStatus({ mobilenumber: data.mobilenumber, isRegister: true })
         setLoading(false)
         // setRegisterForm(true)
         // setHideContinueButton(true)
      } else if (status == 'ERROR') {
         setLoading(false)
         alert(message)
      } else {
         setOtpStatus({ mobilenumber: data.mobilenumber, isRegister: false })
         setLoading(false)
         // setHideContinueButton(true)
      }
   }

   // const onLoginSubmit = async (data) => {
   //   setLoading(true)
   //   const {
   //     loginOTP: { message, status },
   //   } = await graphqlWebClient.request(loginOTP(`${data.mobilenumber}`))

   //   if (!status) {
   //     const {
   //       createAccountOTP: { message: newMessage, status, token },
   //     } = await graphqlWebClient.request(
   //       createAccountOTP(`${data.mobilenumber}`)
   //     )
   //     alert(newMessage)
   //     setOtpStatus({ mobilenumber: data.mobilenumber, isRegister: true })
   //     setLoading(false)
   //     // setRegisterForm(true)
   //     // setHideContinueButton(true)
   //   } else {
   //     setOtpStatus({ mobilenumber: data.mobilenumber, isRegister: false })
   //     setLoading(false)
   //     // setHideContinueButton(true)
   //   }
   // }
   const onCloseClicked = () => {
      // setIsVisible(false)
      onClose && onClose()
   }

   return (
      <>
         <Offcanvas
            show={isVisible}
            onHide={onCloseClicked}
            placement="end"
            className="login-popup"
         >
            <Offcanvas.Header closeButton>
               <Offcanvas.Title
                  className="position-relative w-100"
                  onClick={onCloseClicked}
               >
                  For tailored recommendations, sign in now!
               </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
               <form onSubmit={handleSubmit(onLoginSubmit)}>
                  <LoginModal
                     onClose={onCloseClicked}
                     isVisible={true}
                     control={control}
                     isLoading={loading}
                     register={register}
                  />
               </form>
            </Offcanvas.Body>
         </Offcanvas>
      </>
   )
}

const useOTPTimeout = () => {
   const [isUnlocked, setIsUnlocked] = useState(false)
   return {}
}

const FBRegisterOTP = ({
   setOtpStatus,
   mobilenumber,
   setIsFbRegister,
   access_token,
   fb_verify_data,
}) => {
   // this is for FB register OTP
   const [loading, setLoading] = useState(false)

   const {
      register,
      handleSubmit,
      control,
      formState: { errors },
   } = useForm<any>({
      defaultValues: {
         mobilenumber: null,
      },
   })

   const onLoginSubmit = async (data) => {
      setLoading(true)

      const {
         loginOTP: { message, status },
      } = await graphqlWebClient.request(loginOTP(`${data.mobilenumber}`))
      if (!status) {
         const {
            createAccountOTP: { message: newMessage, status, token },
         } = await graphqlWebClient.request(
            createAccountOTP(`${data.mobilenumber}`)
         )
         alert(newMessage)
         setLoading(false)

         setIsFbRegister({
            fb_verify_otp: true,
            fb_button: true,
            access_token: access_token,
            type: fb_verify_data.type,
         })
         setOtpStatus({ mobilenumber: data.mobilenumber, isRegister: false })
      } else {
         setIsFbRegister({
            fb_verify_otp: true,
            fb_button: true,
         })
         setLoading(false)
         setOtpStatus({ mobilenumber: data.mobilenumber, isRegister: false })
      }
   }

   return (
      <form
         onSubmit={handleSubmit(onLoginSubmit)}
         className="fieldset send-otp-form"
      >
         <Form.MobileNumberField
            label="Mobile Number"
            name="mobilenumber"
            control={control}
         />
         {loading ? (
            <ButtonLoader color="#1877f2" />
         ) : (
            <Button fill>Continue</Button>
         )}
         {/* <Button fill>Continue</Button> */}
      </form>
   )
}

const VerifyOTP = ({
   mobilenumber,
   isRegister,
   setOtpStatus,
   close,
   fb_verify_data = null,
   setIsFbRegister,
}) => {
   const {
      register,
      control,
      handleSubmit,
      watch,
      formState: { errors },
   } = useForm<{
      mobilenumber: string
      otp: string
      firtname?: string
      lastname?: string
      email?: string
      webengage_whatsapp_optin?: boolean
   }>({
      defaultValues: {
         mobilenumber,
         otp: null,
      },
   })
   const [isVisible, setIsVisible] = useState(true)
   const [loading, setLoading] = useState(false)
   const [, , { reloadLogin }] = useAuthContext()

   useEffect(() => {
      // close()
   }, [])
   const refCode = async () => {
      try {
         const refcode = getReferralCode()
         // console.log('refcode', refcode)
         if (refcode) {
            const cart_id = getCartId()
            const {
               referralGraphql: { referral_code },
            } = await graphqlWebClient.request(
               referralGraphql({ referral_code: refcode, quote_id: cart_id })
            )
            if (referral_code) {
               alert('Referral code added to your account')
               removeReferralCode()
            }
         }
      } catch (err) {
         console.log(err)
      }
   }
   const onLoginVerify = async (data) => {
      setLoading(true)
      const { fb_verify_otp, access_token, type } = fb_verify_data

      if (fb_verify_otp) {
         const { status, message, token } = await graphqlWebClient
            .request(loginOTPVerify(data.mobilenumber, data.otp))
            .then((d) => {
               if (d.loginOTPVerify.status) {
                  return d.loginOTPVerify
               } else {
                  return graphqlWebClient
                     .request(
                        FBPostLogin({
                           access_token: access_token,
                           mobilenumber: data.mobilenumber,
                           otp: data.otp,
                           type: type,
                        })
                     )
                     .then((d) => d.postSocialLogin)
               }
            })

         if (status) {
            setToken(token)
            refCode()
            reloadLogin()
            await graphqlWebClient.request(updateCustomer, {
               input: {
                  webengage_whatsapp_optin: `${data?.webengage_whatsapp_optin ? '1' : '0'
                     }`,
               },
            })
            const customerDetails = await graphqlWebClient.request(gql`{
                    ${customer}
                  }`)
            we.setUserAttribute(
               'we_whatsapp_opt_in',
               customerDetails?.customer?.webengage_whatsapp_optin == 1
                  ? true
                  : false
            )
            {
               isRegister
                  ? we.userLogin(
                     {
                        email: parseEmail(`${data?.email}`),
                        phone: parseMobileNumber(`${data?.mobilenumber}`),
                        first_name: `${data?.firtname}`,
                        last_name: `${data?.lastname}`,
                        user_id: parseEmail(`${data?.email}`),
                        mode: `signup`,
                        modeSource: {
                           source: `OTP`,
                           page: ``,
                        },
                     },
                     'logged in'
                  )
                  : we.userLogin(
                     {
                        email: parseEmail(`${customerDetails?.customer?.email}`),
                        phone: parseMobileNumber(`${data?.mobilenumber}`),
                        first_name: `${customerDetails?.customer?.firstname}`,
                        last_name: `${customerDetails?.customer?.lastname}`,
                        user_id: parseEmail(`${customerDetails?.customer?.email}`),
                        mode: `login`,
                        modeSource: {
                           source: `OTP`,
                           page: ``,
                        },
                     },
                     'logged in'
                  )
            }
         }
      } else {
         const { message, status, token } = await (isRegister
            ? graphqlWebClient
               .request(createCustomerAccount(data))
               .then((d) => d.createCustomerAccount)
            : graphqlWebClient
               .request(loginOTPVerify(data.mobilenumber, data.otp))
               .then((d) => d.loginOTPVerify)
         )
            .catch((d) => {
               alert(d.response.errors[0].message)
               return {
                  status: false,
               }
            })
            .finally(() => {
               setLoading(false)
            })
         if (status) {
            console.log('Inside Status')
            setToken(token)
            refCode()
            reloadLogin()

            onCloseClicked()
         } else {
            // alert(message);
         }
      }
   }
   const onCloseClicked = () => {
      setIsVisible(false)
      close && close()
   }
   let submitLabel = isRegister ? 'Sign up now' : 'Sign in'
   return (
      <Offcanvas
         show={isVisible}
         onHide={onCloseClicked}
         placement="end"
         className="login-popup"
      >
         <Offcanvas.Header closeButton>
            <Offcanvas.Title
               className="position-relative w-100"
               onClick={onCloseClicked}
            >
               For tailored recommendations, {isRegister ? 'sign up' : 'sign in'}{' '}
               now!
            </Offcanvas.Title>
         </Offcanvas.Header>
         <Offcanvas.Body>
            <form onSubmit={handleSubmit(onLoginVerify)} className="fieldset ">
               <input type="hidden" {...register('mobilenumber')} />

               <Form.Field label={'Mobile Number'}>
                  <div className="control mobile-number-control">
                     <div className="text-input">+{mobilenumber}</div>
                     <div
                        className="change input"
                        onClick={(e) => {
                           setOtpStatus({
                              mobilenumber: false,
                              isRegister: false,
                           })
                           setIsFbRegister({
                              fb_button: false,
                           })
                        }}
                     >
                        Change
                     </div>
                  </div>
               </Form.Field>

               {isRegister && (
                  <>
                     <Form.TextField
                        name="firtname"
                        label="First Name"
                        {...register('firtname')}
                     ></Form.TextField>
                     <Form.TextField
                        name="lastname"
                        label="Last Name"
                        {...register('lastname')}
                     ></Form.TextField>
                     <Form.TextField
                        name="email"
                        label="Email Id"
                        {...register('email')}
                     />
                  </>
               )}
               <Form.OtpField
                  label="OTP"
                  className="otp"
                  placeholder="Please enter 6 digit otp"
                  control={control}
                  {...register('otp', {
                     pattern: /^[0-9]{6}$/,
                     required: true,
                  })}
               />
               <ResendOTP
                  mobilenumber={mobilenumber}
                  isRegister={isRegister}
                  socialLogin={fb_verify_data.fb_verify_otp}
               />
               <div className="text-center">
                  <Button
                     type="submit"
                     variant="primary"
                     className="px-5"
                     fill
                     disabled={loading}
                  >
                     {loading ? 'Signing...' : submitLabel}
                  </Button>
               </div>
            </form>
         </Offcanvas.Body>
      </Offcanvas>
   )
}

export const LoginComponent = (props) => {
   const [{ isAuthenticated }] = useAuthContext()
   const [{ mitter, storeConfig }] = useAppContext()
   const [, , { reloadLogin }] = useAuthContext()
   const [loading, setLoading] = useState(false)
   const additionLogoUrl = '/media/logo/'
   const [buttonLoading, setbuttonLoading] = useState(false)
   const [{ mobilenumber, isRegister }, setOtpStatus] = useState({
      mobilenumber: false,
      isRegister: false,
   })
   const [isFbRegister, setIsFbRegister] = useState({
      fb_register: false,
      fb_access_token: null,
      fb_verify_otp: false,
      fb_button: false,
      type: null,
   })
   // isAuthenticated &&
   //   mitter.emit('ShowToaster', {
   //     message: 'Customer Logged In Successfully',
   //   })
   // isAuthenticated && props.close()

   // gmail login state
   const [gmailLoading, setGmailLoading] = useState(false)

   function handleOnClick() {
      gapi.load('auth2', function () {
         gapi.auth2.authorize(
            {
               client_id:
                  '212306632821-n7hbcpsgnta87dce5vijv2ioakrp7vup.apps.googleusercontent.com',
               scope: 'email profile openid',
               response_type: 'id_token permission',
               plugin_name: 'Vedistry App',
            },
            function (response) {
               if (response.error) {
                  setGmailLoading(false)
                  // An error happened!
                  return
               }
               // The user authorized the application for the scopes requested.
               var accessToken = response.access_token
               responseGoogle(accessToken, null)
            }
         )
      })
   }

   const responseGoogle = (accessToken, error) => {
      // do not remove this the login component need to refactor

      if (accessToken) {
         gmailLogin(accessToken)
            .then(async (data: any) => {
               if (data.status) {
                  setToken(data.token)
                  reloadLogin()
                  const customerDetails = await graphqlWebClient.request(gql`{
                        ${customer}
                     }`)
                  let email = parseEmail(`${customerDetails?.customer?.email}`)
                  we.userLogin(
                     {
                        email,
                        phone: parseMobileNumber(`${customerDetails?.customer?.mobilenumber}`),
                        first_name: `${customerDetails?.customer?.firstname}`,
                        last_name: `${customerDetails?.customer?.lastname}`,
                        user_id: email,
                        mode: `login`,
                        modeSource: {
                           source: `Google`,
                           page: ``,
                        },
                     },
                     'logged in'
                  )
               } else {
                  setIsFbRegister({
                     fb_register: true,
                     fb_access_token: accessToken,
                     fb_verify_otp: false,
                     fb_button: true,
                     type: 'g',
                  })
               }
            })
            .finally(() => {
               setGmailLoading(false)
            })
      }
      if (error) {
         setGmailLoading(false)
      }
   }

   return (
      <>
         <Helmet>
            <script
               src="https://apis.google.com/js/platform.js"
               async
               defer
            ></script>
         </Helmet>
         <div className="login-wrapper">
            {!mobilenumber && !isFbRegister.fb_register && (
               <SendOTP
                  setOtpStatus={setOtpStatus}
                  onClose={props.close}
                  mobilenumber={mobilenumber}
                  isVisible={props.isVisible}
               />
            )}
            {mobilenumber && !isFbRegister.fb_register && (
               <VerifyOTP
                  mobilenumber={mobilenumber}
                  isRegister={isRegister}
                  setOtpStatus={setOtpStatus}
                  setIsFbRegister={setIsFbRegister}
                  fb_verify_data={isFbRegister}
                  close={isAuthenticated ? props.close : () => { }}
               />
            )}
            {isFbRegister.fb_register && (
               <FBRegisterOTP
                  setIsFbRegister={setIsFbRegister}
                  setOtpStatus={setOtpStatus}
                  mobilenumber={mobilenumber}
                  access_token={isFbRegister.fb_access_token}
                  fb_verify_data={isFbRegister}
               />
            )}
         </div>
      </>
   )
}
