// import { ParsedUrlQuery } from 'querystring';
import { getLocationOrigin, normalizePathTrailingSlash } from '@/utils/router/utils';

// export type BaseRouter = {
//   route: string;
//   pathname: string;
//   query: ParsedUrlQuery;
//   asPath: string;
//   basePath: string;
//   locale?: string;
//   locales?: string[];
//   defaultLocale?: string;
//   // domainLocales?: DomainLocales;
//   isLocaleDomain: boolean;
// };

// export default class Router implements BaseRouter {

// }

const basePath = (process.env.__ROUTER_BASEPATH as string) || '';

function pathNoQueryHash(path: string) {
  const queryIndex = path.indexOf('?');
  const hashIndex = path.indexOf('#');

  if (queryIndex > -1 || hashIndex > -1) {
    path = path.substring(0, queryIndex > -1 ? queryIndex : hashIndex);
  }
  return path;
}

export function hasBasePath(path: string): boolean {
  path = pathNoQueryHash(path);
  return path === basePath || path.startsWith(basePath + '/');
}

/**
 * Detects whether a given url is routable by the Next.js router (browser only).
 */
export function isLocalURL(url: string): boolean {
  // prevent a hydration mismatch on href for url with anchor refs
  if (url.startsWith('/') || url.startsWith('#') || url.startsWith('?'))
    return true;
  try {
    // absolute urls can be local if they are on the same origin
    const locationOrigin = getLocationOrigin();
    const resolved = new URL(url, locationOrigin);
    return resolved.origin === locationOrigin && hasBasePath(resolved.pathname);
  } catch (_) {
    return false;
  }
}

// /**
//  * Detects whether a given url is routable by router (browser only).
//  */
// export function isLocalURL(url: string): boolean {
//   // prevent a hydration mismatch on href for url with anchor refs
//   if (url.startsWith('/') || url.startsWith('#') || url.startsWith('?'))
//     return true;
//   try {
//     // absolute urls can be local if they are on the same origin
//     const locationOrigin = getLocationOrigin();
//     const resolved = new URL(url, locationOrigin);
//     return resolved.origin === locationOrigin && hasBasePath(resolved.pathname);
//   } catch (_) {
//     return false;
//   }
// }

function onlyAHashChange(oldUrl: Location, newUrl: Location): boolean {
  const oldUrlNoHash = oldUrl.pathname + '?' + oldUrl.search,
    newUrlNoHash = newUrl.pathname + '?' + newUrl.search,
    oldHash = oldUrl.hash,
    newHash = newUrl.hash;

  if (newHash && oldUrlNoHash === newUrlNoHash && oldHash === newHash) {
    return true;
  }

  if (oldUrlNoHash !== newUrlNoHash) {
    return false;
  }

  return oldHash !== newHash;
}

function addPathPrefix(path: string, prefix?: string) {
  return prefix && path.startsWith('/')
    ? path === '/'
      ? normalizePathTrailingSlash(prefix)
      : `${prefix}${pathNoQueryHash(path) === '/' ? path.substring(1) : path}`
    : path;
}

export function addBasePath(path: string): string {
  // we only add the basepath on relative urls
  return addPathPrefix(path, basePath);
}

export function delBasePath(path: string): string {
  path = path.slice(basePath.length);
  if (!path.startsWith('/')) path = `/${path}`;
  return path;
}
export function addLocale(
  path: string,
  locale?: string | false,
  defaultLocale?: string,
) {
  if (process.env.___I18N_SUPPORT) {
    const pathname = pathNoQueryHash(path);
    const pathLower = pathname.toLowerCase();
    const localeLower = locale && locale.toLowerCase();

    return locale &&
      locale !== defaultLocale &&
      !pathLower.startsWith('/' + localeLower + '/') &&
      pathLower !== '/' + localeLower
      ? addPathPrefix(path, '/' + locale)
      : path;
  }
  return path;
}

export function delLocale(path: string, locale?: string) {
  if (process.env.___I18N_SUPPORT) {
    const pathname = pathNoQueryHash(path);
    const pathLower = pathname.toLowerCase();
    const localeLower = locale && locale.toLowerCase();

    return locale &&
      (pathLower.startsWith('/' + localeLower + '/') ||
        pathLower === '/' + localeLower)
      ? (pathname.length === locale.length + 1 ? '/' : '') +
          path.substr(locale.length + 1)
      : path;
  }
  return path;
}
