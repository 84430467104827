import { useAppContext } from '@/core/App/AppContext'
import React from 'react'
import useCartContext from '../../CartWrapper/useCartContext'

export const CouponList = ({
  title,
  description,
  code,
  close,
  setSuccessModal,
}) => {
  const [cartState, cartDispatch, { applyCoupon, removeCoupon }] =
    useCartContext()
  const [{ mitter }] = useAppContext()
  const appliedCouponCode =
    cartState.cart?.applied_coupons && cartState.cart?.applied_coupons[0]?.code
  const handleRemoveCoupon = () => {
    // alert('Coupon Code removed');
    removeCoupon().catch((err) => {
      alert(err)
    })
  }

  const handleApplyCoupon = (couponcode) => {
    applyCoupon(couponcode)
      .then((data) => {
        mitter.emit('ShowToaster', {
          message: 'Coupon Code applied',
        })
        setSuccessModal(true)
        mitter.emit('NavigationEnd', () => false)
        // close(true);
      })
      .catch((err) => {
        if (err) {
          mitter.emit('ShowToaster', {
            message: 'Coupon Code not valid',
          })
        }
        mitter.emit('NavigationEnd', () => false)
      })
  }

  return (
    <div className="coupon">
      <span className="coupon-title">{title}</span>
      <span className="coupon-description">{description}</span>
      <div className="coupon-column">
        <span className="coupon-name">{code}</span>
        {appliedCouponCode == code ? (
          <div className="coupon-applied">
            APPLIED
            <i className="fa fa-close" onClick={() => handleRemoveCoupon()}></i>
          </div>
        ) : (
          <span
            className="coupon-button btn btn-primary"
            onClick={() => {
              mitter.emit('NavigationStart', () => true)
              handleApplyCoupon(code)
            }}
          >
            APPLY
          </span>
        )}
      </div>
      <small className="danger"></small>
    </div>
  )
}
