import { FC, useState } from 'react'
import { Offcanvas, Button } from 'react-bootstrap'
import { ButtonLoader } from '../ButtonLoader'
import Form from '../Form'
import { GCAPTCH_SITE_KEY } from 'src/config'
import ReCaptcha from 'react-google-recaptcha'

const OtpInput = () => {
   return (
      <div id="otp" className="inputs d-flex flex-row justify-content-center">
         <input
            className="m-2 ms-0 text-center form-control rounded"
            type="text"
            id="first"
         />
         <input
            className="m-2 text-center form-control rounded"
            type="text"
            id="second"
         />
         <input
            className="m-2 text-center form-control rounded"
            type="text"
            id="third"
         />
         <input
            className="m-2 text-center form-control rounded"
            type="text"
            id="fourth"
         />
         <input
            className="m-2 text-center form-control rounded"
            type="text"
            id="fifth"
         />
         <input
            className="m-2 me-0 text-center form-control rounded"
            type="text"
            id="sixth"
         />
      </div>
   )
}

const LoginModal: FC = ({
   isLoading = false,
   isVisible = false,
   onClose,
   control,
   register,
}) => {
   const [showOTP] = useState(true)
   const [loading, setLoading] = useState(false)
   const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)
   const onCloseClicked = () => {
      onClose && onClose()
   }
   return (
      <>
         <div className="login-section">
            <Form.MobileNumberField
               label="Mobile Number"
               name="mobilenumber"
               control={control}
            />
            <div className="mt-3 mb-3 whatsapp-checkbox-userinfo">
               <input
                  type="checkbox"
                  {...register('webengage_whatsapp_optin')}
                  defaultChecked
               />
               <span>Check this to get notified on whatsapp/RCS</span>
            </div>
            <ReCaptcha
               className="d-flex align-items-center justify-content-center"
               sitekey={GCAPTCH_SITE_KEY}
               onErrored={(event) => {
                  console.log({ event })
                  setIsCaptchaVerified(false)
               }}
               onExpired={(event) => {
                  console.log({ event })
                  setIsCaptchaVerified(false)
               }}
               onChange={(event) => {
                  console.log({ event })
                  setIsCaptchaVerified(true)
               }}
            />
            <br />
            <div className="text-center">
               {loading ? (
                  <ButtonLoader color="#1877f2" />
               ) : (
                  <Button
                     disabled={!isCaptchaVerified}
                     variant="primary"
                     className="px-5"
                     type="submit"
                  >
                     {isLoading ? 'Sending OTP...' : 'Send OTP'}
                  </Button>
               )}
            </div>

            {!showOTP && (
               <div className="otp-button p-2">
                  <button
                     className="primary custom-primary-btn"
                     onClick={handleSendOTPShow}
                  >
                     Send OTP
                  </button>
               </div>
            )}
         </div>
      </>
   )
}
export default LoginModal
