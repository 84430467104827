import { useAppContext } from "@/core/App/AppContext";
import clsx from "clsx";
import { memo, useEffect, useRef, useState } from "react";
import "./toaster.scss";
import ReactDOM from "react-dom";
import { Image } from "../Image";
import { useRouterContext } from "@/core/Router/RouterContext";
import { indexOf } from "lodash";
import useProductContext from "@/theme/route/ProductPage/useProductContext";
var viewCountId;
var toasterId = 0;
var toaster = {
  items: [],
};

const ToasterBase = (props) => {
  useEffect(() => {
    var timeout = setTimeout(() => {
      props.removeSelf(props.id);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return <div className={clsx("snackbar")}>{props.message}</div>;
};

const ToasterBaseMemo = memo(ToasterBase);

export const Toaster = () => {
  const [toasters, setToasters] = useState<any[]>([]);
  const [{ mitter }] = useAppContext();
  const toasterRef = useRef([]);

  useEffect(() => {
    const setState = (args) => {
      const newArgs = { ...args, id: toasterId++ };
      toasterRef.current.push(newArgs);
      setToasters([...toasterRef.current]);
    };
    mitter.on("ShowToaster", setState);
    return () => {
      mitter.off("ShowToaster", setState);
    };
  }, []);

  const removeSelf = (id) => {
    const index = toasterRef.current.findIndex((to) => to.id == id);
    toasterRef.current.splice(index, 1);
    setToasters([...toasterRef.current]);
  };
  if (toasters.length == 0) return null
  return ReactDOM.createPortal(
    <div
      className={clsx("snackbar-container", {
        ["has-content"]: toasters.length > 0,
      })}
    >
      {toasters.map((t) => (
        <ToasterBaseMemo {...t} key={t.id} removeSelf={removeSelf} />
      ))}
    </div>,
    document.getElementById("toaster-root")
  );
};

